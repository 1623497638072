export const DonorSignUp = () => {
    return (
        <>
            <h3>Donor Sign Up</h3>
            <p>
                Donations aren't just for people. They are deeply rewarding for you too. Further,
                if you see a problem or deprivation in an area, you probably want to improve those issues.
                Donation is the best way through which you cannot only help a community but a generation.
                So, donate to SAG and play your part in improving the quality of life of the Sikh Community in India. Contact us for further information.

            </p>
        </>
    )
}
