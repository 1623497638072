import { React, useState, useEffect } from 'react'
import { Breadcrumb } from '../Shared/BreadCrumb';
import { UserService } from '../../services/User.service';


export const Donor = () => {
  const userService = new UserService();
  const [donors, setDonors] = useState([]);
  console.log(donors);

  const getData = () => {
    userService.getAllUser({ "role": "donor" }).then((response) => {
      setDonors(response.data);
    })
  }
  useEffect(() => {
    getData();
  }, [])
  return (
    <>
      <Breadcrumb title="Donors" links={[{ title: "Donors" }]} />
      
      <div className="container clearfix">
        <div className="row mt-5">
          {donors.map((donor, index) => {
            return <div className="col-3" key={donor._id}>
              <div className="team">
                <div className="team-image">
                  <img src={donor.profilePicture || "/user-placeholder.jpg"} alt={donor.fName + " " + donor.lName} />
                </div>
                <div className="team-desc">
                  <div className="team-title"><h4>{donor.fName} {donor.lName}</h4></div>
                  <div><h5 className='mb-0 mt-3'>Donation</h5><p>{donor.info && donor.info.donation ? donor.info.donation : 0}</p></div>

                </div>
              </div>
            </div>
          })
          }
        </div>
      </div>

    </>
  );
}
