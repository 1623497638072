
export const dateToDateString = (dateValue) => {
  if (!dateValue) return "";

  if (!(dateValue instanceof Date)) {
    dateValue = new Date(dateValue);
  }
  let date = "";
  let dd = dateValue.getDate();
  let mm = dateValue.getMonth() + 1; //January is 0!

  let yyyy = dateValue.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  date = dd + "/" + mm + "/" + yyyy;
  return date.toString();
}

export const dateToDateTimeString = (dateValue) => {
  if (!(dateValue instanceof Date)) {
    dateValue = new Date(dateValue);
  }

  let date = "";
  let dd = dateValue.getDate();
  let mm = dateValue.getMonth() + 1; //January is 0!

  let yyyy = dateValue.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  date = dd + "/" + mm + "/" + yyyy;
  return date.toString();
}

/**
 * Compares the year and month values. If first date is greater than or equal to the second it returns true.
 * @param {Date Object} dateValue1 Date one to compare with 2nd
 * @param {*} dateValue2 Date two compare
 * @returns boolean
 */
export const compareMonths = (dateValue1, dateValue2) => {
  if (!(dateValue1 instanceof Date)) {
    dateValue1 = new Date(dateValue1);
  }
  if (!(dateValue2 instanceof Date)) {
    dateValue2 = new Date(dateValue2);
  }
  dateValue1.setHours(0,0,0,0);
  dateValue2.setHours(0,0,0,0);

  dateValue1.setDate(1);
  dateValue2.setDate(1);

  return dateValue1 >= dateValue2;
}

/**
 * Compares the year, month, date and time values. If first date is greater than or equal to the second it returns true.
 * @param {Date Object} dateValue1 Date one to compare with 2nd
 * @param {*} dateValue2 Date two compare
 * @returns boolean
 */
export const compareDates = (dateValue1, dateValue2) => {
  if (!(dateValue1 instanceof Date)) {
    dateValue1 = new Date(dateValue1);
  }
  if (!(dateValue2 instanceof Date)) {
    dateValue2 = new Date(dateValue2);
  }

  return dateValue1 >= dateValue2;
}