import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class ApplicantService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/applicant";
  } 

  create(data) {
    return this.post(this.apiUrl, data)
  }

  getApplicants(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }
  deleteApplicant(id) {
    return this.delete(this.apiUrl + "/" + id);
  }
  getApplicant(id) {
    return this.get(this.apiUrl + "/" + id);
  }
  update(id, data) {
    return this.patch(this.apiUrl + "/" + id, data);
  }
}