import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class ProjectService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/category";
  }

  getAllProjects(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }
}