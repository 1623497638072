import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Sidebar } from './shared/sidebar';
import { Home } from './Home/Home';
import { Institution } from "./Institute/Institution";
import { Applicant } from './Applicant/Applicant';
import { Donations } from './Donation/Donations';
import { FundRequest } from './FundRequest/FundRequest';
import { FundRequestApprovals } from './FundRequest/FundRequestApprovals';
import { InstitutionForm } from './Institute/InstitutionForm';
import { FundRequestForm } from './FundRequest/FundRequestForm';
import { FundRequestDetail } from "./FundRequest/FundRequestDetail";
import { DonationForm } from './Donation/DonationForm';
import { ApplicantForm } from './Applicant/Applicantform';
import { UserForm } from './User/UserForm';
import { User } from './User/User';
import { ChangePassword } from './User/ChangePassword';
import { FundRequestCreateEdit } from './FundRequest/FundRequestCreateEdit';
import { Event } from './Event/Event';
import { EventForm } from './Event/EventForm';
import { Disbursement } from './Disbursement/Disbursement';
import { DisbursementForm } from './Disbursement/DisbursementForm';
import { DonationRequest } from './DonationRequest/DonationRequest';
import { DonationRequestForm } from './DonationRequest/DonationRequestForm';
import { DonationRequestDetail } from './DonationRequest/DonationRequestDetail';
import { ApplicantDetail } from './Applicant/ApplicantDetail';
import { Role } from './Role/Role';
import { RoleForm } from './Role/RoleFrom';
import SessionService from "../services/Session.service";
import { AccessRequest } from './AccessRequest/AccessRequest';
import { Profile } from './User/Profile';

export const Portal = (props) => {
	let { path } = useRouteMatch();
	if (!SessionService.isAuthenticated()) {
		props.history.push("/login");
	}

	return (
		<>
			<div className="side-header">
				<div id="wrapper" className='clearfix' style={{ opacity: 1, boxShadow: "none" }}>
					<Sidebar />
					<Switch>
						<Route exact path={path} component={Home} />
						<Route exact path={`${path}/create`} component={FundRequestCreateEdit} />

						<Route exact path={`${path}/user`} component={User} />
						<Route exact path={`${path}/user/form`} component={UserForm} />
						<Route exact path={`${path}/user/form/:id`} component={UserForm} />

						<Route exact path={`${path}/applicant`} component={Applicant} />
						<Route exact path={`${path}/applicant/form`} component={ApplicantForm} />
						<Route exact path={`${path}/applicant/:ApplicantId`} component={ApplicantDetail} />
						<Route exact path={`${path}/applicant/form/:id`} component={ApplicantForm} />

						<Route exact path={`${path}/fund-request`} component={FundRequest} />
						<Route exact path={`${path}/fund-request/approvals`} component={FundRequestApprovals} />
						<Route exact path={`${path}/fund-request/:FundRequestId`} component={FundRequestDetail} />
						<Route exact path={`${path}/fund-request/form/:ApplicantId`} component={FundRequestForm} />
						<Route exact path={`${path}/fund-request/form/:ApplicantId/:id`} component={FundRequestForm} />
					
						<Route exact path={`${path}/donations`} component={Donations} />
						<Route exact path={`${path}/donations/form`} component={DonationForm} />
						<Route exact path={`${path}/donations/form/:id`} component={DonationForm} />
					
						<Route exact path={`${path}/institute`} component={Institution} />
						<Route exact path={`${path}/institute/form`} component={InstitutionForm} />
						<Route exact path={`${path}/institute/form/:id`} component={InstitutionForm} />
					
						<Route exact path={`${path}/event`} component={Event} />
						<Route exact path={`${path}/event/form`} component={EventForm} />
						<Route exact path={`${path}/event/form/:id`} component={EventForm} />

						<Route exact path={`${path}/disbursement`} component={Disbursement} />
						<Route exact path={`${path}/disbursement/form`} component={DisbursementForm} />

						<Route exact path={`${path}/donation-request`} component={DonationRequest} />
						<Route exact path={`${path}/donation-request/:DonationRequestId`} component={DonationRequestDetail} />
						<Route exact path={`${path}/donation-request/form`} component={DonationRequestForm} />
						<Route exact path={`${path}/donation-request/form/:id`} component={DonationRequestForm} />
						<Route exact path={`${path}/donation-request/:DonationRequestId/add-donation`} component={DonationForm} />

						<Route exact path={`${path}/donation-request/:DonationRequestId/add-disbursement`} component={DisbursementForm} />

						<Route exact path={`${path}/change-password`} component = {ChangePassword}/>

						<Route exact path={`${path}/role`} component = {Role}/>
						<Route exact path={`${path}/role/form`} component = {RoleForm}/>
						<Route exact path={`${path}/role/form/:id`} component = {RoleForm}/>

						<Route exact path={`${path}/access-request`} component = {AccessRequest}/>
					
						<Route exact path={`${path}/profile-change`} component = {Profile}/>
					</Switch>
				</div>
			</div>
		</>
	)
}
