import { React, useRef, useEffect, useState } from 'react';
import { Field, Form, Formik, input } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Breadcrumb } from "../shared/Breadcrumb";
import { removeQueryString } from "../../utils/helping.utils";
import { ImageCropperNew } from "../shared/ImageCropperNew";
import { UserService } from "../../services/User.service";
import SessionService from "../../services/Session.service";
import { UploadService } from '../../services/Upload.service';

const uploadService = new UploadService();
const userService = new UserService();

const UserSchema = Yup.object().shape({
  fName: Yup.string()
    .min(2, "First name must contain at least 2 letters")
    .max(50, "First name cannot exceed 50 letters")
    .required("First name is required"),

  lName: Yup.string()
    .min(2, "Last name must contain at least 2 letters")
    .max(50, "Last name cannot exceed 50 letters")
    .required("Last name is required"),

  profilePicture: Yup.string()
});

export const Profile = () => {
  const formRef = useRef();
  const user = SessionService.getLoggedIn();
  const [uploadingThumbnail, setUploadingThumbnail] = useState(false);

  const pictureChanged = (data) => {
    const formRefCurrent = formRef.current;
    if (!data || !data.blob || !data.file || !formRefCurrent) {
      return;
    };

    setUploadingThumbnail(true);
    uploadService.uploadBlob(data.blob, data.file, "public-read").then((response) => {
      formRefCurrent.setFieldValue("profilePicture", removeQueryString(response.location));
      setUploadingThumbnail(false);
    })
      .catch((error) => {
        toast.error("Image could not be uploaded")
        setUploadingThumbnail(false);
      })
  }

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    const data = {
      fName: values.fName, lName: values.lName
    };

    if (values.profilePicture) data['profilePicture'] = values.profilePicture;

    userService.updateProfile(data)
      .then((response) => {
        user.fName = data.fName;
        user.lName = data.lName;
        user.profilePicture = values.profilePicture || "";
        SessionService.setLogin(user);
        toast.success("Profile updated successfully");
      })
      .catch((error) => {
        toast.error("Profile could not updated!")
      })
      .finally(() => {
        setSubmitting(false);
      })
  };

  return (
    <>
      <Breadcrumb title="Change Profile" links={[{ title: "Change Profile" }]} />
      <section className="container-xl p-2 mt-3 bottommargin-lg">
        <Formik
          innerRef={formRef}
          initialValues={{
            fName: user.fName,
            lName: user.lName,
            profilePicture: user.profilePicture,
          }}
          validationSchema={UserSchema}
          onSubmit={formSubmitted}
        >
          {({ errors, touched, isSubmitting, values }) => (
            <Form className="nobottommargin" autoComplete="off">
              <div className="row m-0">
                <div className="col-sm-12">
                  <div className="imageUpload" style={{ border: "none", width: "150px" }}>
                    <img src={values.profilePicture || "/user-placeholder.jpg"} alt={values.fName + " " + values.lName} width="150" height="150" className="rounded-circle" style={{
                      border: "solid #d7d7d7 1px"
                    }} />
                    {uploadingThumbnail &&
                      <div className="loader">
                        <i className="fas fa-spinner fa-pulse fa-2x"></i>
                      </div>
                    }
                  </div>
                  <div className="pl-3">
                    <ImageCropperNew className="ml-2" btnLabel="Upload Image" unit="px" width="260" height="260" locked={false} inputName="thumbnailFile" onCropComplete={pictureChanged} />
                  </div>
                </div>
                <div className="col-6">
                  <label className="label" htmlFor="fName">First Name</label>
                  <Field autoComplete="off" id="fName" name="fName" className="sm-form-control" />
                  {errors.fName && touched.fName ? (<div className="error">{errors.fName}</div>) : null}
                </div>
                <div className="col-6">
                  <label className="label" htmlFor="lName">Last Name</label>
                  <Field autoComplete="off" id="lName" name="lName" className="sm-form-control" />
                  {errors.lName && touched.lName ? (<div className="error">{errors.lName}</div>) : null}
                </div>
                <div className="col-12 mt-5">
                  <button type="submit" name="website-cost-submit" className="btn btn-secondary">
                    {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
}
