import { React, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ImageCropperNew } from "../shared/ImageCropperNew";
import { UploadService } from '../../services/Upload.service';
import { booleanToString, removeQueryString } from "../../utils/helping.utils";

import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { EducationService } from "../../services/Education.service";
import { RandomId } from "../../utils/helping.utils";

const EducationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Class is required"),

  startDate: Yup.date()
    .required("Start date is required"),

  isCurrent: Yup.boolean(),

  endDate: Yup.date().when('isCurrent', {
    is: false,
    then: Yup.date().required('End date is required'),
    otherwise: Yup.date(),
  }),

  obtainedMarks: Yup.string().when('isCurrent', {
    is: false,
    then: Yup.string().required('Obtained marks are required'),
    otherwise: Yup.string(),
  }),

  totalMarks: Yup.string().when('isCurrent', {
    is: false,
    then: Yup.string().required('Total marks are required'),
    otherwise: Yup.string(),
  }),
  // grade: Yup.string().when('isCurrent', {
  //   is: false,
  //   then: Yup.string().required('Grade is required'),
  //   otherwise: Yup.string(),
  // }),
  marksSheet: Yup.string(),
  description: Yup.string()
    .max(200, 'Note cannot exceed 200 letter')
});

export const EducationForm = (props) => {
  const formRef = useRef();
  const educationService = new EducationService();
  const uploadService = new UploadService();

  const [startDate, setStartDate] = useState(props.initialValues.startDate);
  const [endDate, setEndDate] = useState(props.initialValues.endDate);
  const [markFile, setMarkFile] = useState(null);
  const [uploadingMarkSheet, setUploadingMarkSheet] = useState(false);


  const MarkSheetChanged = (data) => {
    const formRefCurrent = formRef.current;
    if (!data || !data.blob || !data.file) {
      return;
    };

    const marksSheetValue = data.file || "SampleString";

    setUploadingMarkSheet(true);
    uploadService.uploadBlob(data.blob, data.file, "public-read").then((response) => {
      formRefCurrent.setFieldValue("marksSheet", removeQueryString(response.location));
      setUploadingMarkSheet(false);
    })
      .catch((error) => {
        toast.error("Image could not be uploaded")
        setUploadingMarkSheet(false);
      })
  }
  const removeMarkSheet = () => {
    const formRefCurrent = formRef.current;
    if (formRefCurrent) formRefCurrent.setFieldValue("marksSheet", "");
  }




  const clearDates = () => {
    setStartDate('')
    setEndDate('')
  }

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    if (!values.uiId) {
      values.uiId = RandomId();
    }

    if (!values._id || !props.ApplicantId) {
      props.onEducationAdded(values);
      resetForm();
      clearDates();
      setSubmitting(false);
      return;
    }

    educationService.update(props.ApplicantId, values._id, values)
      .then((response) => {
        toast.success("Education updated successfully!");
        props.onEducationAdded(values);
        resetForm();
        // clearDates();
      })
      .catch((error) => {
        toast.error("Education could not be updated!");
      })
      .finally(() => {
        setSubmitting(false);
      })
  };


  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={props.initialValues}
        validationSchema={EducationSchema}
        onSubmit={formSubmitted}
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="col-12 form-group">
              <label className="label" htmlFor="class">
                Class
              </label>
              <Field type="text" autoComplete="off" name="title" id="class" className="form-control required" placeholder="Enter your class" />
              {errors.title && touched.title ? (
                <div className="error">{errors.title}</div>
              ) : null}
            </div>
            <div className="col-12 form-group">
              <label className="label" htmlFor="sDate">
                Start Date
              </label>
              <DatePicker placeholder="Start Date" className="form-control" selected={startDate} onfocus="(this.type='date')" onblur="(this.type='text')" onChange={(date) => { setFieldValue("startDate", date); setStartDate(date) }} />
              {errors.startDate && touched.startDate ? (
                <div className="error">{errors.startDate}</div>
              ) : null}
            </div>
            <div className="col-12 form-group">
              <Field id="isCurrent" className="radio-style" name="isCurrent" type="checkbox" />
              <label htmlFor="isCurrent" className="label radio-small ml-1 nobottommargin">Is Current</label>
            </div>

            {!values.isCurrent &&
              <>
                <div className="col-12 form-group">
                  <label className="label" htmlFor="eDate">
                    End Date
                  </label>
                  <DatePicker className="form-control" selected={endDate} onChange={(date) => { setFieldValue("endDate", date); setEndDate(date) }} />
                  {errors.endDate && touched.endDate ? (
                    <div className="error">{errors.endDate}</div>
                  ) : null}
                </div>
                <div className="col-12 form-group">
                  <label className="label" htmlFor="oMark">
                    Obtain Mark
                  </label>
                  <Field type="text" name="obtainedMarks" id="oMark" className="form-control required" placeholder="Obtain marks" />
                  {errors.obtainedMarks && touched.obtainedMarks ? (
                    <div className="error">{errors.obtainedMarks}</div>
                  ) : null}
                </div>
                <div className="col-12 form-group">
                  <label className="label" htmlFor="tMark">
                    Total Mark
                  </label>
                  <Field type="text" name="totalMarks" id="tMark" className="form-control required" placeholder="Total marks" />
                  {errors.totalMarks && touched.totalMarks ? (
                    <div className="error">{errors.totalMarks}</div>
                  ) : null}
                </div>
                {/* <div className="col-12 form-group">
                  <label className="label" htmlFor="grade">
                    Grade
                  </label>
                  <Field type="text" name="grade" id="grade" className="form-control required" placeholder="Grade" />
                  {errors.grade && touched.grade ? (
                    <div className="error">{errors.grade}</div>
                  ) : null}
                </div> */}
                <div className="col-12 form-group">
                  <label className="label" htmlFor="class">
                    Note
                  </label>
                  <Field component="textarea" rows="4" autoComplete="off" name="description" id="description" className="form-control required" placeholder="Enter Note" />
                  {errors.description && touched.description ? (
                    <div className="error">{errors.description}</div>
                  ) : null}
                </div>

                <div className="col-12 form-group">
                  <label htmlFor="basic-url">Mark Sheet</label>
                  {uploadingMarkSheet && <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>}
                  {!uploadingMarkSheet && <>
                    {values.marksSheet !== "" && values.marksSheet !== undefined && <div className="imageUpload">
                      <img src={values.marksSheet} alt="Applicant" />
                      <button className="btn btn-light pl-4 pr-4" onClick={removeMarkSheet}><i className="fa fa-trash"></i></button>
                    </div>}
                    {!values.marksSheet &&
                      <ImageCropperNew btnLabel="Upload Mark Sheet" inputName="marksSheetFile" onCropComplete={MarkSheetChanged} aspect={undefined} />
                    }
                    {errors.marksSheet && touched.marksSheet ? (
                      <div className="error mt-1">{errors.marksSheet}</div>
                    ) : null}
                  </>}
                </div>
              </>
            }

            <div className="col-12 form-group">
              <button type="submit" className="btn btn-secondary" disabled={isSubmitting}>
                {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
