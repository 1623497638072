import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { ProjectService } from "../../services/Project.service";
import { Breadcrumb } from "../Shared/BreadCrumb";
import Slider from "./Components/Slider";
import Statistic from "./Components/Staistics";
import WhyEducation from "./Components/WhyEducation";
import CategoryApplicantTable from "./Components/CategoryApplicantTable";
const projectService = new ProjectService();

function Details(props) {
  let { slug } = useParams();
  console.log("=====>", slug);
  const [category, setCategory] = useState({
    _id: "",
    name: "",
    description: "",
    images: [],
  });
  console.log(category);
  useEffect(() => {
    (() => {
      projectService.getAllProjects({ slug }).then((response) => {
        if (!response.data.length) {
          return;
        }
        setCategory(response.data[0]);
      });
    })();
  }, [slug]);

  return (
    <>
      <Breadcrumb
        title={category.name}
        links={[{ title: "Projects", to: "project" }, { title: category.name }]}
      />
      <div className="container pt-3">
        <Slider images={category.images} />
      </div>
      <section>
        <div className="container mt-5 pt-3 pb-2">
          <div className="">
            <h3 className="d-flex justify-content-right pt-2 nobottommargin">
              {" "}
              {category.name}{" "}
            </h3>
            {/* <span> {category.description}</span> */}
            <div dangerouslySetInnerHTML={{ __html: category.description }} />
          </div>
        </div>
      </section>

      {category._id && <Statistic filters={{ CategoryId: category._id }} />}

      <section>
        <div className="container mt-3 pt-1 pb-2">
          <div className="">
            <h3 className="d-flex justify-content-right">Applicants</h3>
            {/* <p>
              We commit to deliver each penny to the needy and to be transparent we enlist each fund request of applicants here. To ensure the privacy we have masked the data and in case of verification you can sign up as a donor and request us for the full profile access.
            </p> */}
            {category._id && (
              <CategoryApplicantTable
                filters={{ status: "Active", CategoryId: category._id }}
                key="CategoryApplicantTableActive"
              />
            )}
            {/* <div className="tabs tabs-bordered clearfix">
              <Tabs id="uncontrolled-tab-example" defaultActiveKey="New">
                <Tab eventKey="New" title="New">
                  <div className="tab-container">
                   
                  </div>
                </Tab>

                <Tab eventKey="Served" title="Served">
                  <div className="tab-container">
                    {category._id &&
                      <CategoryApplicantTable filters={{ status: "Completed", CategoryId: category._id }} key="CategoryApplicantTableCompleted" />
                    }
                  </div>
                </Tab>
              </Tabs>
            </div> */}
          </div>
        </div>
      </section>

      {/* <WhyEducation /> */}
    </>
  );
}
export default Details;
