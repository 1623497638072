
import { Link } from "react-router-dom";
import "./contact.css";
function Contact() {
  return (
    <>
      <section id="page-title" className="page-title-mini d-none d-md-block">
        <div className="container clearfix">
          <h1>Contact</h1>
          <span>Everything you need to know about our Company</span>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Contact</li>
          </ol>
        </div>
      </section>
      <section id="content" className="mb-0">
        <div className="content-wrap">
          <div className="container clearfix">
            <div className="col_half">
              <div className="fancy-title title-dotted-border">
                <h3>Send us an Email</h3>
              </div>
              <div className="form-widget">
                <div className="form-result"></div>
                <form className="nobottommargin" id="template-contactform" name="template-contactform" action="include/form.php" method="post" novalidate="novalidate">
                  <div className="form-process"></div>
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="template-contactform-name">Name <small>*</small></label>
                      <input type="text" id="template-contactform-name" name="template-contactform-name" value="" className="sm-form-control required" />
                    </div>
                    <div className="col-6">
                      <label htmlFor="template-contactform-email">Email <small>*</small></label>
                      <input type="email" id="template-contactform-email" name="template-contactform-email" value="" className="required email sm-form-control" />
                    </div>
                    <div className="clear"></div>
                    <div className="col-12">
                      <label htmlFor="template-contactform-subject">Subject <small>*</small></label>
                      <input type="text" id="template-contactform-subject" name="subject" value="" className="required sm-form-control" />
                    </div>
                    <div className="clear"></div>
                    <div className="col-12">
                      <label htmlFor="template-contactform-message">Message <small>*</small></label>
                      <textarea className="required sm-form-control" id="template-contactform-message" name="template-contactform-message" rows="6" cols="30"></textarea>
                    </div>
                    <div className="col-12 hidden">
                      <input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" value="" className="sm-form-control" />
                    </div>
                    <div className="col-12 mt-3">
                      <button name="submit" type="submit" id="submit-button" tabindex="5" value="Submit" className="btn btn-secondary nomargin">Submit Comment</button>
                    </div>
                  </div>
                  <input type="hidden" name="prefix" value="template-contactform-" />
                </form>
              </div>
            </div>
            {/* <div className="col_half col_last">
              <section id="google-map" className="gmap">
                <div className="gmapi">
                  <div className="gm-err-container text-center mt-5">
                    <div className="gm-err-content">
                      <div className="gm-err-icon">
                        <img src="https://maps.gstatic.com/mapfiles/api-3/images/icon_error.png" alt="" draggable="false" />
                      </div>
                      <div className="gm-err-title">Oops! Something went wrong.</div>
                      <div className="gm-err-message">This page didn't load Google Maps correctly. See the JavaScript console for technical details.</div>
                    </div>
                  </div>
                </div>
              </section>
            </div> */}
            {/* <div className="clear"></div> */}
            <div className="row clear-bottommargin d-flex justify-content-center">
              {/* <div className="col-lg-3 col-md-6 bottommargin clearfix"> */}
              {/* <div className="feature-box fbox-center fbox-bg fbox-plain"> */}
              {/* <div className="fbox-icon">
                    <Link to="#"><i className="icon-map-marker2"></i></Link>
                  </div> */}
              {/* ur Headquarters<<h3>Ospan className="subtitle">17 Harris Close Newton Mearns Glasgow
                    G77 6TU Scotland</span></h3> */}
              {/* </div>
              </div> */}
              <div className="row d-flex justify-content-between">
                <div className="row col-lg-6 col-md-6 bottommargin clearfix">
                  <div className="feature-box fbox-center fbox-bg fbox-plain">
                    <div className="fbox-icon">
                      <Link to="#"><i className="icon-phone3"></i></Link>
                    </div>
                    <h3>Speak to Us<span className="subtitle"> +44 7723 013160</span></h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 bottommargin clearfix">
                  <div className="feature-box fbox-center fbox-bg fbox-plain d-flex justify-content-center">
                    <div className="fbox-icon">
                      <Link to="#"><i className="icon-email"></i></Link>
                    </div>
                    <h3>Contact via Email<span className="subtitle"> sikhaidglobal@gmail.com
                    </span></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
}
export default Contact;