import { LocalStorageService } from "./LocalStorage.service";
import { SESSION_KEY } from "../constants";

class SessionService {
  constructor() {
    this.localStorageService = new LocalStorageService();
  }

  setLogin(info) {
    this.localStorageService.save(SESSION_KEY, info);
  }

  getLoggedIn() {
    const user = this.localStorageService.get(SESSION_KEY);
    if (!user) {
      return null;
    }

    return user;
  }

  isAuthenticated() {
    return this.localStorageService.get(SESSION_KEY) ? true : false;
  }

  logout() {
    this.localStorageService.remove(SESSION_KEY);
  }
}

export default new SessionService();