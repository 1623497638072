import { Link } from "react-router-dom";
import { useHistory, Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { Breadcrumb } from "../Shared/BreadCrumb";
import { AuthService } from "../../services/Auth.service";
import SessionService from "../../services/Session.service";

const LogInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .min(5, "Email must contain at least 5 characters")
    .max(255, "Email cannot exceed 255 characters")
    .required("Email is required"),

  password: Yup.string().required("Password is required"),
});

export const Login = () => {
  const history = useHistory();
  if (SessionService.isAuthenticated()) return <Redirect push to="/portal" />;

  const authService = new AuthService();
  const initialValues = {
    email: "",
    password: "",
  };

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    authService.logIn(values)
      .then((response) => {
        SessionService.setLogin(response.data);
        resetForm();
        history.push("/portal");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setSubmitting(false);
      })
  };

  return (
    <>
      <ToastContainer />
      <Breadcrumb title="Login" links={[{ title: "Login" }]} />

      <div className="d-flex justify-content-center">
        <div className="mt-3 mb-3 p-2 col-sm-7 col-lg-6">
          <h3 className="center">Log in</h3>
          <Formik initialValues={initialValues} validationSchema={LogInSchema} onSubmit={formSubmitted}>
            {({ errors, touched, isSubmitting }) => (
              <Form className=" nobottommargin ">
                <div className="form-group ">
                  <label htmlFor="email">Email</label>
                  <Field name="email" id="email" placeholder="Email address" className="sm-form-control" />
                  {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field type='password' id="password" name="password" placeholder="Password" className="sm-form-control" />
                  {errors.password && touched.password ? (<div className="error">{errors.password}</div>) : null}
                </div>
                <div className="mt-2 create">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <button type="submit" disabled={isSubmitting} className="btn-block d-flex justify-content-center align-items-center btn btn-secondary mt-3">
                  {isSubmitting && <i className="fas fa-spinner fa-pulse mr-1"></i>} Login
                </button>

                <div className="mt-2 create">
                  <Link to="/signup">Register New Account</Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
