
import { React, useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ProjectService } from "../../services/Project.service";
import { InstituteService } from "../../services/Institute.service";
import { FundRequestService } from "../../services/FundRequest.service";
import { RandomId } from "../../utils/helping.utils";
import { ApplicantPrimaryInfoView } from "../Applicant/Components/ApplicantPrimaryInfoView";

const FundRequestSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Amount is required")
    .min(1, "Amount must be greater than zero"),

  InstitutionId: Yup.string()
    .required("Institute is required"),

  CategoryId: Yup.string()
    .required("Category is required"),

  isMonthly: Yup.boolean()
    .required("Is monthly is required"),

  getFundingTill: Yup.date("Must be a valid date")
    .required("Fund Required Till"),
});

const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) => {
  return (
    <label htmlFor={id.toString()} className={`label btn btn-outline-secondary px-3 t600 ls0 nott ${id === value ? 'active' : ''}`}>
      <input id={id.toString()} value={id} checked={id === value} name={name} type="radio" onChange={onChange} onBlur={onBlur} {...props} />{" "}
      {label}
    </label>
  );
};

export const FundRequestCreateEdit = (props) => {
  const projectService = new ProjectService;
  const instituteService = new InstituteService();
  const fundRequestService = new FundRequestService();
  const [categories, setCategories] = useState([]);
  const [getFundingTill, setFundingTill] = useState();
  const formRef = useRef();

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    values.isMonthly = values.isMonthly === "true" || values.isMonthly === true;

    if (!values.uiId) {
      values.uiId = RandomId();
    }

    if ((!values._id && !props.id) || !props.ApplicantId) {
      props.onFundRequestAdded(values);
      resetForm();
      clearDates();
      setSubmitting(false);
      return;
    }

    fundRequestService.update(props.ApplicantId, values._id || props.id, values)
      .then((response) => {
        toast.success("Fund request updated successfully!");
        props.onFundRequestAdded(values);
        resetForm();
        clearDates();
      })
      .catch((error) => {
        toast.error("Fund request could not be updated!");
      })
      .finally(() => {
        setSubmitting(false);
      })
  };

  const getInstitutes = async (search) => {
    const conditions = {approved: true, l: 20};
    if (search) {
      conditions['s'] = search;
    }
   
    const response = await instituteService.getInstitutes(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.name
        
      };
    });
   
  }

  const clearDates = () => {
    setFundingTill('')
  }

  const getProjects = (search) => {
    const conditions = { l: 100 };
    if (search) {
      conditions['s'] = search;
    }

    projectService.getAllProjects(conditions).then((response) => {
      setCategories(response.data);
    })
  }

  useEffect(() => {
    getProjects();

    if (props.id) {
      fundRequestService.getFundRequest(props.id).then((response) => {
        const formRefCurrent = formRef.current;
        if (response.data && formRefCurrent) {
          formRefCurrent.setFieldValue("amount", response.data.amount);
          formRefCurrent.setFieldValue("InstitutionId", response.data.InstitutionId);
          formRefCurrent.setFieldValue("CategoryId", response.data.category._id);
          formRefCurrent.setFieldValue("isMonthly", response.data.isMonthly.toString());
          if (response.data.getFundingTill) {
            formRefCurrent.setFieldValue("getFundingTill", new Date(response.data.getFundingTill))
            setFundingTill(new Date(response.data.getFundingTill))
          };
        }
      })
    }
    if (props.initialValues && props.initialValues.getFundingTill) {
      setFundingTill(props.initialValues.getFundingTill)
    }
  }, [])

  return (
    <>
      <div className="row m-0 mb-4">
        <div className="col-sm-12">
          <ApplicantPrimaryInfoView ApplicantId={props.ApplicantId} infoLevel="basic" />
        </div>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={props.initialValues}
        validationSchema={FundRequestSchema}
        onSubmit={formSubmitted}
      >
        {({ errors, touched, isSubmitting, setFieldValue, submitCount }) => {
          return (
            <Form>
              <div className="row m-0">
                <div className="col-md-6 form-group">
                  <label className="label">Category</label>
                  <Field as="select" name="CategoryId" className="form-control">
                    <option value="">Select Category</option>
                    {categories.map(category =>
                      <option key={category._id} value={category._id} >{category.name}</option>
                    )};
                  </Field>
                  {errors.CategoryId && touched.CategoryId ? (
                    <div className="error">{errors.CategoryId}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label">Institute</label>
                  <AsyncSelect name="InstitutionId" cacheOptions defaultOptions loadOptions={getInstitutes} onChange={(val) => setFieldValue("InstitutionId", val.value)} />
                  {(errors.InstitutionId || submitCount > 0) && touched.InstitutionId ? (
                    <div className="error">{errors.InstitutionId}</div>
                  ) : null}
                </div>

                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="amount">
                    Required Funding
                  </label>
                  <Field type="number" name="amount" id="amount" className="form-control" placeholder="Required funding" />
                  {errors.amount && touched.amount ? (
                    <div className="error">{errors.amount}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="verified" className="label">
                    Fund Required Till
                  </label>
                  <DatePicker className="form-control" selected={getFundingTill} onChange={(date) => { setFieldValue("getFundingTill", date); setFundingTill(date) }} />
                  {errors.getFundingTill && touched.getFundingTill ? (
                    <div className="error">{errors.getFundingTill}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="isMonthly" className="mb-3 label">
                    Is Monthly?
                  </label>
                  <br />
                  <div className="btn-group btn-group-toggle flex-wrap" data-toggle="buttons">
                    <Field component={RadioButton} name="isMonthly" id="true" label="Yes" />
                    <Field component={RadioButton} name="isMonthly" id="false" label="No" />
                  </div>
                  {errors.isMonthly && touched.isMonthly ? (<div className="error">{errors.isMonthly}</div>) : null}
                </div>
                <div className="col-12 mt-2">
                  <button type="submit" name="website-cost-submit" disabled={isSubmitting} className="btn btn-secondary">
                  {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
                  </button>
                  {/* <Link to="/portal/fund-request">
                      <button type="button" name="website-cost-submit" className="btn btn-warn">
                        Cancel
                      </button>
                    </Link> */}
                </div>
              </div>
            </Form>
          )
        }
        }
      </Formik>
    </>
  );
};
