import { React, useState, useEffect, useRef } from 'react';
import { Card, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { Breadcrumb } from "../shared/Breadcrumb";
import { dateToDateString } from "../../utils/date.utils";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { DisbursementService } from "../../services/Disbursement.service";
import AclService from "../../services/Acl.service";

import { ApplicantService } from "../../services/Applicant.service";
import { InstituteService } from "../../services/Institute.service"
import { EventService } from '../../services/Event.service';

const disbursementService = new DisbursementService();
const applicantService = new ApplicantService();
const instituteService = new InstituteService();
const eventService = new EventService();

export const Disbursement = () => {
	const filterFormRef = useRef();
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [disbursements, setDisbursements] = useState([]);
	const [deleteId, setDeleteId] = useState(null);

	const toggleFilter = () => setIsFilterOpened(wasOpened => !wasOpened);
	const [offset, setOffset] = useState(0);
	const limit = 20;

	const getApplicant = async (search) => {
		const conditions = { l: 20 };
		if (search) {
			conditions['s'] = search;
		}

		const response = await applicantService.getApplicants(conditions);
		return response.data.map(x => {
			return {
				value: x._id,
				label: x.fName
			};
		});
	}

	const getInstitution = async (search) => {
		const conditions = { l: 20 };
		if (search) {
			conditions['s'] = search;
		}

		const response = await instituteService.getInstitutes(conditions);
		return response.data.map(x => {
			return {
				value: x._id,
				label: x.name
			};
		});
	}

	const getEvent = async (search) => {
		const conditions = { l: 20 };
		if (search) {
			conditions['s'] = search;
		}

		const response = await eventService.getEvents(conditions);
		return response.data.map(x => {
			return {
				value: x._id,
				label: x.name
			};
		});
	}

	const getData = () => {
		const filters = {
			l: limit,
			o: offset
		};
		if (filterFormRef.current) {
			if (filterFormRef.current.values.uid) filters.uid = filterFormRef.current.values.uid;
			if (filterFormRef.current.values.ApplicantId) filters.ApplicantId = filterFormRef.current.values.ApplicantId;
			if (filterFormRef.current.values.InstitutionId) filters.InstitutionId = filterFormRef.current.values.InstitutionId;
			if (filterFormRef.current.values.EventId) filters.EventId = filterFormRef.current.values.EventId;
		}
		disbursementService.findAll(filters).then((response) => {
			if (offset) setDisbursements([...disbursements, ...response.data]);
			else setDisbursements(response.data);
		})
	}

	const deleteConfirmationResult = (isConfirmed) => {
		const id = deleteId;
		setDeleteId(null);
		if (!isConfirmed) {
			return;
		}

		disbursementService.deleteDisbursement(id).then((response) => {
			const i = disbursements.findIndex(x => x._id === id);
			if (i >= 0) {
				const tempDisbursements = [...disbursements];
				tempDisbursements.splice(i, 1);
				setDisbursements(tempDisbursements);
				toast.success("Disbursement record delete successfully")
			}
		})
			.catch(() => {
				toast.error("Disbursement record could not delete")
			})
	}

	useEffect(() => {
		getData();
	}, [offset])

	return (
		<>
			<Breadcrumb title="Disbursements" links={[{ title: "Disbursements" }]} />
			<ConfirmationModal show={deleteId !== null} onClose={deleteConfirmationResult} />

			<section id="content" className='container-xl p-2 mt-3 bottommargin-lg'>
				<div className="row mb-3">
					<div className='col-12 mb-2'>
						{
							AclService.checkPermission("DisbursementCreated") &&
							<Link to="/portal/disbursement/form">
								<button type="button" className="btn btn-light btn-sm float-right"><i className="fas fa-plus"></i></button>
							</Link>
						}
						<button type="button" onClick={toggleFilter} className="btn btn-light btn-sm float-right  mr-1"><i className="fas fa-filter"> Filters (0)</i></button>
					</div>
					{isFilterOpened && (
						<div className="col-12">
							<Formik
								innerRef={filterFormRef}
								initialValues={{
									uid: "",
									ApplicantId: "",
									InstitutionId: "",
									EventId: "",
								}}
								onSubmit={() => {
									if (offset) setOffset(0)
									else getData();
								}}
							>
								{({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
									<Form>
										<Card>
											<Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
												<div className="row align-items-center">
													<Field name="uid" autoComplete="off" id="uid" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="TransactionId.." />
													<AsyncSelect name="ApplicantId" className="col-sm-3 sm ml-1 p-0 mt-1" cacheOptions defaultOptions loadOptions={getApplicant} onChange={(val) => setFieldValue("ApplicantId", val.value)} />
													<AsyncSelect name="InstitutionId" className="col-sm-3 sm ml-1 p-0 mt-1" cacheOptions defaultOptions loadOptions={getInstitution} onChange={(val) => setFieldValue("InstitutionId", val.value)} />
													<AsyncSelect name="EventId" className="col-sm-3 sm ml-1 p-0 mt-1" cacheOptions defaultOptions loadOptions={getEvent} onChange={(val) => setFieldValue("EventId", val.value)} />
													<div className="col-sm-2 pt-2">
														<button type="submit" className="btn btn-secondary btn-sm">Filter</button>
														<button type="button" className="btn btn-secondary btn-sm ml-1" onClick={() => {
															resetForm();
															setTimeout(() => {
																if (offset) setOffset(0)
																// else getData();
															}, 200);
														}}>Reset</button>
													</div>
												</div>
											</Card.Body>
										</Card>
									</Form>
								)}
							</Formik>
						</div>
					)}
				</div>
				<div className="table-responsive">
					<InfiniteScroll height={"72vh"} dataLength={disbursements.length} next={() => setOffset(offset + limit)} hasMore={true}>
						<table className="table table-hover">
							<thead>
								<tr>
									<th>Transaction Id</th>
									<th>Amount</th>
									<th>Disbursed Date</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>{disbursements.map((disbursement, index) => {
								return <tr key={disbursement._id}>
									<td>{disbursement.uid}</td>
									<td>{disbursement.amount}</td>
									<td>{dateToDateString(disbursement.createdAt)}</td>
									<td>

										<Dropdown>
											<Dropdown.Toggle variant="light" id="dropdown-basic">
												<i className="fas fa-cog"></i>
											</Dropdown.Toggle>

											<Dropdown.Menu>
											{
							AclService.checkPermission("DisbursementDelete") &&
												<Dropdown.Item eventKey={"1"} onClick={() => setDeleteId(disbursement._id)}>Delete</Dropdown.Item>}
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							}
							)}
							</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</section>
		</>
	);
};
