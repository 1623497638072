import { React, useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { Accordion, Card, ProgressBar } from "react-bootstrap";
import { DonationRequestService } from "../../../services/DonationRequest.service";

const donationRequestService = new DonationRequestService();
const COLORS = ["#0088FE", "#00C49F", "#cccccc", "#FF8042", "#348042"];

function Statistic() {
  const [pieChartData, setPieChartData] = useState([]);
  const [fundTotals, setFundTotals] = useState({
    requiredFund: 0,
    raisedFund: 0,
  });
  const [servedFundRequests, setServedFundRequests] = useState(0);
  const [inProcessFundRequests, setInProcessFundRequests] = useState(0);

  useEffect(() => {
    donationRequestService
      .getDonationRequestsPieChartData()
      .then((response) => {
        const totalsTemp = {
          requiredFund: 0,
          raisedFund: 0,
        };
        if (response.data.length) {
          setPieChartData(response.data);
          response.data.forEach((categoryStats) => {
            totalsTemp.requiredFund =
              totalsTemp.requiredFund + categoryStats.requested;
            totalsTemp.raisedFund =
              totalsTemp.raisedFund + categoryStats.collected;
          });
        }
        setFundTotals(totalsTemp);
      });

    donationRequestService.getCount({ status: "Active" }).then((response) => {
      let inProcessFundRequestsTemp = 0;
      if (response.data.count) {
        inProcessFundRequestsTemp = response.data.count;
      }
      setInProcessFundRequests(inProcessFundRequestsTemp);
    });

    donationRequestService
      .getCount({ status: "Completed" })
      .then((response) => {
        let servedFundRequestsTemp = 0;
        if (response.data.count) {
          servedFundRequestsTemp = response.data.count;
        }
        setServedFundRequests(servedFundRequestsTemp);
      });
  }, []);

  return (
    <>
      <div className="container-fluid statistics">
        <div className="container clearfix">
          <div className="center">
            <h2 className="center heading2">Statistics</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 bottommargin accormain">
              <div className="accordion clearfix nobottommargin">
                <Accordion defaultActiveKey="0">
                  <Card className="nobottommargin mt-0">
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      Transparency
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        We have established a transparent process of donations.
                        You will get comprehensive details about the beneficiary
                        and the case verification. Further, whatever you donate
                        completely goes to charitable activities. You can also
                        find if the beneficiary has received the amount or not
                        through our website.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="nobottommargin mt-0">
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      How to get support
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        You can get support by your information and the kind of
                        support you want. First, you need to contact us through
                        our official email or contact number. Then, you need to
                        go through a simple procedure to ask for your details.
                        Finally, you'll get donations when our team verifies
                        your case.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="nobottommargin mt-0">
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      Where can you find us
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        You don't need to reach us at a particular address.
                        Instead, you can provide all your details and contact us
                        through our website. We'll respond within 24 hours and
                        even less.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="nobottommargin mt-0">
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                      Why you choose our NGO,s
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        There are numerous reasons. Firstly, we only collect
                        donations for verified cases displayed on our website.
                        Secondly, we provide a transparent procedure where we
                        only act as a medium to connect donors and
                        beneficiaries. All the information about the beneficiary
                        is available on our website. You can check it and even
                        request a profile to contact the beneficiaries and meet
                        them to verify the case further.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
            <div className="col-lg-6 bottommargin mt-1">
              <div className="row align-items-center h-100">
                <div className="col-sm-12">
                  <ul className="d-flex justify-content-between list-unstyled totalGraph">
                    <li>
                      <h3>Raised Funding</h3>
                      <p className="mb-2">{fundTotals.raisedFund}</p>
                    </li>
                    <li>
                      <h3>Required Funding</h3>
                      <p className="mb-2 text-right">
                        {fundTotals.requiredFund}
                      </p>
                    </li>
                  </ul>
                  <ul className="list-unstyled">
                    <li>
                      <ProgressBar
                        key="total-funding"
                        variant="secondary"
                        now={
                          (fundTotals.raisedFund / fundTotals.requiredFund) *
                          100
                        }
                      />
                    </li>
                  </ul>

                  <ul className="d-flex justify-content-between list-unstyled totalGraph mb-0 mt-5">
                    <li>
                      <h3>Applicant Served </h3>
                      <p className="mb-2">{servedFundRequests}</p>
                    </li>
                    <li>
                      <h3>Total Applicant</h3>
                      <p className="mb-2 text-right">
                        {inProcessFundRequests + servedFundRequests}
                      </p>
                    </li>
                  </ul>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <ProgressBar
                        key="total-applicants"
                        variant="secondary"
                        now={
                          (servedFundRequests /
                            (inProcessFundRequests + servedFundRequests)) *
                          100
                        }
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            {pieChartData.map((data, index) => {
              const chartData = [];
              if (data.requested)
                chartData.push({ name: "Required", value: data.requested });
              if (data.collected)
                chartData.push({ name: "Collected", value: data.collected });
              if (data.disbursed)
                chartData.push({ name: "Disbursed", value: data.disbursed });
              return (
                <div className="col-sm-12 col-md-6 col-lg-3" key={index}>
                  <div className="card">
                    <div className="card-body pb-2">
                      <h3>{data.category}</h3>
                      <ul className="d-flex justify-content-center nobottommargin">
                        <PieChart width={200} height={170}>
                          <Pie
                            data={chartData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={30}
                            outerRadius={60}
                            fill="#00365b" // Primary color
                            labelLine={false}
                          >
                            {chartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={index === 0 ? "#f44708" : "#00365b"} // Secondary color for index 0, primary color for others
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Required: {data.requested}</li>
                        <li>Collected: {data.collected}</li>
                        <li>Disbursed: {data.disbursed}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="clear"></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Statistic;
