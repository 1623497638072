import { React, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FundRequestService } from "../../services/FundRequest.service";
import { Breadcrumb } from "../shared/Breadcrumb";
import { FundRequestCreateEdit } from "./FundRequestCreateEdit";
import { Link, useHistory } from "react-router-dom";

export const FundRequestForm = () => {
  const history = useHistory();
  const { ApplicantId, id } = useParams();
  const [categories, setCategories] = useState([]);
  const fundRequestService = new FundRequestService();

  const [fundRequestInitialValues, setFundRequestInitialValues] = useState({
    CategoryId: "",
    amount: "",
    InstitutionId: "",
    getFundingTill: "",
    isMonthly: false
  });

  const formSubmitted = (values, setSubmitting, resetForm) => {
    const data = {
      amount: values.amount,
      InstitutionId: values.InstitutionId,
      CategoryId: values.CategoryId,
      isMonthly: values.isMonthly === "true" || values.isMonthly === true
    };

    if (values.getFundingTill) {
      data['getFundingTill'] = values.getFundingTill;
    }

    if (id) {
      return;
    }

    fundRequestService.create(ApplicantId, data).then((response) => {
      toast.success("Fund request created successfully");
      history.push("/portal/fund-request/approvals")
    })
      .catch((error) => {
        toast.error("Fund request could not created")
      })
  };

  return (
    <>
      <Breadcrumb title="Fund Request Form" links={[{ title: "Fund Request" }, { title: "Fund Request Form" }]} />
      <section id="content" className="container-xl p-2 mt-3" style={{minHeight: "85vh"}}>
        <FundRequestCreateEdit id={id} ApplicantId={ApplicantId} initialValues={fundRequestInitialValues} onFundRequestAdded={formSubmitted} />
      </section>
    </>
  );
};
