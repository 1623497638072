import Slider from "./Component/Slider";
import AboutUs from "./Component/Aboutus";

import Mission from "./Component/Mission";
import Fact from "./Component/Fact";
import Events from "../Event/Events";

// import PrimaryPriority from "./Component/PrimaryPriority";
import Projects from "../Catagories/Components/Project";

function Home() {
  return (
    <>
      <Slider />
      <AboutUs />
      <Projects />
      {/* <PrimaryPriority /> */}
      {/* <Fact /> */}
      <Mission />
      <Events limit={4} scrollEnable={false} />
    </>
  );
}
export default Home;
