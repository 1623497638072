export const InstituteSignUp = () => {
    return (
        <>
            <h3>Institute Sign Up</h3>
            <p>Everyone has the right to get a quality education regardless of their background, culture, and area.
                Thus, we're here to help deprived areas to get quality education.
                So, if you are an educational institute and looking for a collaboration to help the underprivileged, you can get us on board.
                We will collect donations for the deserving students at your institute to offer them free education.
                You need to sign up first and provide detailed information about your institute and the students who need help.
                You can register through the below link or contact us for further details.
            </p>
        </>
    )
}
