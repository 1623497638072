import { React, useEffect, useState } from 'react';
import { Formik, Field, Form } from "formik";
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from "yup"
import { Breadcrumb } from "../Shared/BreadCrumb";
import { AuthService } from '../../services/Auth.service';
import { toast } from 'react-toastify';

const authService = new AuthService();
const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "New Password must contain at least 8 characters")
    .max(100, "New Password cannot exceed 100 characters")
    .required("New Password is required"),

  passwordConfirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref('password'), null], 'Confirm Password must match with Password'),
});

export const ResetPassword = () => {
  const history = useHistory();
  const { email, verification } = useParams();
  const [validationStatus, setValidationStatus] = useState("verifying");
  
  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    authService.resetPassword(email, values.password)
      .then((response) => {
        toast.success("Password changed successfully")
        resetForm();
        history.push("/portal/user")
      })
      .catch((error) => {
        if (error.response.message) toast.success(error.response.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    authService.forgotPasswordVerify(verification)
      .then((response) => {
        setValidationStatus("verified");
      })
      .catch((error) => { setValidationStatus("failed") })
  }, [])

  return (
    <>
      <Breadcrumb title="Reset Password" links={[{ title: "Reset Password" }]} />
      <div className="d-flex justify-content-center">
        <div className="mt-3 mb-3 p-2 col-sm-7 col-lg-6">
          <h3 className="center">Reset Password</h3>
          {validationStatus === "verifying" && <>
            <div className="center">
              <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>
              <p className="mb-1 mt-3">
                <strong>Verifying Link!</strong>
              </p>
              <p>Please wait..</p>
            </div>
          </>}

          {validationStatus === "verified" && <>
            <Formik
              initialValues={{
                password: "",
                passwordConfirmation: "",
              }}
              validationSchema={ChangePasswordSchema}
              onSubmit={formSubmitted}>
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="password">New Password</label>
                    <Field type='password' id="password" name="password" placeholder="New Password" className="sm-form-control" />
                    {errors.password && touched.password ? (<div className="error">{errors.password}</div>) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="passwordConfirmation">Repeat New Password</label>
                    <Field type='password' id="passwordConfirmation" name="passwordConfirmation" placeholder="Repeat New Password" className="sm-form-control" />
                    {errors.passwordConfirmation && touched.passwordConfirmation ? (<div className="error">{errors.passwordConfirmation}</div>) : null}
                  </div>

                  <button type="submit" disabled={isSubmitting} className="btn-block d-flex justify-content-center align-items-center btn btn-secondary mt-3">
                    {isSubmitting && <i className="fas fa-spinner fa-pulse mr-1"></i>} Submit
                  </button>

                </Form>
              )}
            </Formik>
          </>
          }

          {validationStatus === "failed" && <>
            <div className="center">
              <i className="fa fa-ban fa-6x" style={{ color: "#685f5f73" }}></i>
              <p className="mb-1 mt-3">
                <strong>Verification Failed!</strong>
              </p>
              <p className="mb-2">Link is invalid or expired.</p>
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </>}
        </div>
      </div>
    </>
  );
}
