import { useState, useEffect } from "react";
import { FundRequestService } from "../../../services/FundRequest.service";
import { dateToDateString } from "../../../utils/date.utils"
import { booleanToString } from "../../../utils/helping.utils";
const fundRequestService = new FundRequestService();

export const FundRequestView = (props) => {
  const { FundRequestId } = props;
  const [fundRequest, setFundRequest] = useState({
    fName: "",
    lName: "",
    fatherName: "",
    phone: "",
    nic: "",
    CityId: {
      name: "",
      countryIso: ""
    },
    address: "",
    skills: [],
    education: [],
    experience: []
  });

  useEffect(() => {
    if (FundRequestId) {
      fundRequestService.getFundRequest(FundRequestId).then((response) => {
        console.log(response.data);
        if (response.data) {
          setFundRequest(response.data);
          if (props.onDataLoad) props.onDataLoad(response.data);
        }
      })
    }
  }, [])

  return <>
    {
      FundRequestId && fundRequest.amount && <>
        <div className="row">
          <div className="col-sm-12">
            <ul className="d-flex flex-row flex-wrap justify-content-between mb-0 list-unstyled">
              <li className="mr-4">Amount: <label>{fundRequest.amount}</label></li>
              <li className="mr-4">Monthly: <label>
                {fundRequest.isMonthly && <span className="badge badge-primary"><i className="fas fa-redo"></i> Yes</span>}
                {!fundRequest.isMonthly && <span className="badge badge-secondary"><i className="far fa-money-bill-alt"></i> No</span>}
              </label></li>
              <li className="mr-4">Fund Required Till: <label>{dateToDateString(fundRequest.getFundingTill)}</label></li>
              <li className="mr-4">Category: <label>{fundRequest.category?.name || "-"}</label></li>
              <li className="mr-4">Institute: <label>{fundRequest?.institute?.name || "-"}</label></li>
              <li className="mr-4">Approved: <label>{booleanToString(fundRequest.isApproved)}</label></li>
              <li className="mr-4">Created At: <label>{dateToDateString(fundRequest.createdAt)}</label></li>
            </ul>
          </div>
        </div>
      </>
    }
  </>
}