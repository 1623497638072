import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class InstituteService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/institute";
  }

  create(data) {
    return this.post(this.apiUrl, data)
  }

  getInstitutes(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }

  getInstitute(id) {
    return this.get(this.apiUrl + "/" + id);
  }
  getCount(conditions = {}) {
    return this.get(this.apiUrl + "/count" + this.objToQuery(conditions));
  }
  deleteInstitute(id) {
    return this.delete(this.apiUrl + "/" + id);
  }

  update(id, data) {
    return this.patch(this.apiUrl + "/" + id, data)
  }
  approve(id, data) {
    return this.patch(this.apiUrl + "/" + id, data)
  }
  unApprove(id, data) {
    return this.patch(this.apiUrl + "/" + id, data)
  }
}