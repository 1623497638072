
import { React, useState, useEffect, useRef } from 'react';
import { Breadcrumb } from "../shared/Breadcrumb";
import { Link } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import InfiniteScroll from 'react-infinite-scroll-component';
import { EventService } from "../../services/Event.service"
import { ConfirmationModal } from "../shared/ConfirmationModal";
import AclService from "../../services/Acl.service";
import { toast } from 'react-toastify';


export const Event = () => {
	const filterFormRef = useRef();
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const eventService = new EventService();
	const [events, setEvents] = useState([]);
	const toggleFilter = () => setIsFilterOpened(wasOpened => !wasOpened);
	const [offset, setOffset] = useState(0);
	const [deleteId, setDeleteId] = useState(null);
	const limit = 20;

	const deleteConfirmationResult = (isConfirmed) => {
		const id = deleteId;
		setDeleteId(null);
		if (!isConfirmed) {
			return;
		}

		eventService.deleteEvent(id).then((response) => {
			const i = events.findIndex(x => x._id === id);
			if (i >= 0) {
				const tempEvents = [...events];
				tempEvents.splice(i, 1);
				setEvents(tempEvents);
				toast.success("Event deleted successfully")
			}
		})
			.catch(() => {
				toast.error("Event could not deleted")
			})
	}

	const getData = () => {
		const filters = {
			l: limit,
			o: offset
		};
		if (filterFormRef.current) {
			if (filterFormRef.current.values.s) filters.s = filterFormRef.current.values.s;
		}
		eventService.getEvents(filters).then((response) => {
			if (offset) setEvents([...events, ...response.data]);
			else setEvents(response.data);
		})
	}
	useEffect(() => {
		getData();
	}, [offset])
	return (
		<>
			<Breadcrumb title="Events" links={[{ title: "Events" }]} />
			<ConfirmationModal show={deleteId !== null} onClose={deleteConfirmationResult} />

			<section id="content" className='container-xl p-2 mt-3 bottommargin-lg'>
				<div className="row mb-3">
					<div className='col-12 mb-2'>
						{
							AclService.checkPermission("EventCreate") &&
							<Link to="/portal/event/form">
								<button type="button" className="btn btn-light btn-sm float-right"><i className="fas fa-plus"></i></button>
							</Link>
						}
						<button type="button" onClick={toggleFilter} className="btn btn-light btn-sm float-right  mr-1"><i className="fas fa-filter"> Filters (0)</i></button>
					</div>
					{isFilterOpened && (
						<div className="col-12">
							<Formik
								innerRef={filterFormRef}
								initialValues={{
									s: "",
								}}
								onSubmit={() => {
									if (offset) setOffset(0)
									else getData();
								}}
							>
								{({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
									<Form>
										<Card>
											<Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
												<div className="row align-items-center">
													<Field name="s" autoComplete="off" id="s" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Search.." />
													<div className="col-sm-2 pt-2">
														<button type="submit" className="btn btn-secondary btn-sm">Filter</button>
														<button type="button" className="btn btn-secondary btn-sm ml-1" onClick={() => {
															resetForm();
															setTimeout(() => {
																if (offset) setOffset(0)
																else getData();
															}, 200);
														}}>Reset</button>
													</div>
												</div>
											</Card.Body>
										</Card>
									</Form>
								)}
							</Formik>
						</div>
					)}
				</div>
				<div className="table-responsive">
					<InfiniteScroll
						height={"72vh"}
						dataLength={events.length}
						next={() => setOffset(offset + limit)}
						hasMore={true}
					>
						<table className="table table-hover">
							<thead>
								<tr>
									<th>Name</th>
									<th>Summary</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{events.map((event, index) => {
									return <tr key={event._id}>
										<td>{event.name}</td>
										<td>{event.summary}</td>
										<td><Dropdown>
											<Dropdown.Toggle variant="light" id="dropdown-basic">
												<i className="fas fa-cog"></i>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{
													AclService.checkPermission("EventDelete") &&
													<Dropdown.Item eventKey={"2"} onClick={() => setDeleteId(event._id)}>Delete</Dropdown.Item>}
												{
													AclService.checkPermission("EventUpdate") &&
													<Dropdown.Item eventKey={"3"} as={Link} to={"/portal/event/form/" + event._id}>Edit</Dropdown.Item>
												}
											</Dropdown.Menu>
										</Dropdown>
										</td>
									</tr>
								}
								)}
							</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</section>
		</>
	);
};
