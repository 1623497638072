import {React, useEffect, useState} from 'react'
import { InstituteService } from '../../services/Institute.service';
import { DonationRequestService } from '../../services/DonationRequest.service';
import { FundRequestService } from '../../services/FundRequest.service';
import { UserService } from '../../services/User.service';

const instituteService = new InstituteService();
const donationRequestService = new DonationRequestService();
const fundRequestService = new FundRequestService();
const userService = new UserService();
export const Card = () => {

  const [instituteCount, setInstituteCount] = useState(0);
  const [donorsCount, setDonorsCount] = useState(0);
  const [servedCount, setServedCount] = useState(0);
  const [awaitingApproval, setAwaitingApproval] = useState(0);

  useEffect(() => {
		instituteService.getCount({approved:"true"}).then((response) => {
			setInstituteCount(response.data.count);
		})

    userService.getCount({"role":"donor"}).then((response) => {
			setDonorsCount(response.data.count);
		})

		donationRequestService.getCount({ status: "Completed" }).then((response) => {
			setServedCount(response.data.count);
		})

    fundRequestService.getCount({'approved':'0'}).then((response) => {
			setAwaitingApproval(response.data.count);
		})
	}, [])
 
  return (
    <>
      <section id="content" className='container mt-3 mb-3'>
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <div className="card-counter">
              <i className=" fas fa-school"></i>
              <span className="count-numbers">{instituteCount}</span>
              <span className="count-name">Institutes</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card-counter">
            <i className="fas fa-money-bill-alt"></i>
              <span className="count-numbers">{donorsCount}</span>
              <span className="count-name">Donors</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card-counter">
            <i className="fas fa-exclamation-triangle fa-xs mb-2"></i>
              <span className="count-numbers">{awaitingApproval}</span>
              <span className="count-name">Awaiting Approvals</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card-counter">
              <i className="fa fa-users"></i>
              <span className="count-numbers">{servedCount}</span>
              <span className="count-name">Served</span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
