import { Breadcrumb } from "../Shared/BreadCrumb";
import { Link , useParams} from "react-router-dom";
import { Modal } from "react-bootstrap";
import {useState } from "react";
import { DonationsService } from "../../services/Donations.service";
import Statistic from "../Home/Component/Statistic";
import PayPalDonateButton from "./Paypal";
import SessionService from "../../services/Session.service";
const user = SessionService.getLoggedIn();

const donationService = new DonationsService();

export const Donation = () => {
  const [showModal, setModal] = useState(false);
  const [showPaypalButton, setPaypalButton] = useState(true);
  const [amount, setAmount] = useState(1);
  const { donationId } = useParams();
  const hideButton = () => {
    setPaypalButton(false);
  };
  // const showButton = () => {
  //   setPaypalButton(true);
  // };

  const handleClose = () => {
    setModal(false);
  };

  const initialOptions = {
    "client-id":"AQdAErvzyYbOx6Q40U1pKcgxFMXl8p-i__OE0Nz29FDYHRk8Fk_PCc7vNOT02bNnrtRGo0MREbmkwQRc",
    currency: "GBP",
  };


  return (
    <>
      <Breadcrumb title="Donation" links={[{ title: "Donation" }]} />
      <div className="container-xl">
        <div className="row col-mb-30 mb-4 mt-4">
          <div className="col-md-6 col-lg-6">
            <div className="pricing-box pricing-simple p-5 bg-light border-top border-primary text-center">
              <div className="pricing-title mb-3">
                <span>1</span>
                <h3>Online</h3>
                <div style={{ width: "150px", margin: "20px auto 0" }}>
                  <img
                    src="/Paypal.png"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <PayPalDonateButton className="mt-2" id={donationId}/>
              <div className="mt-1">
              {!SessionService.isAuthenticated() ? 
              
              <Link  to= "/signup">Register</Link>: <p>Donating as <span style={{fontWeight: 'bold'}}>{user.fName}{user.lName}</span></p>}
              </div>
              {/* <label className="mt-4" htmlFor="amount">
                Please enter amount (GBP)
              </label>
              <input
                type="number"
                className="form-control"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              /> */}
              {/* <div className="pricing-action">
                <button
                  onClick={() => {
                    payPal(amount);
                  }}
                  className="btn btn-primary btn-lg"
                >
                  Paypal
                </button>
              </div> */}
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="pricing-box pricing-simple p-5 bg-light border-top border-danger text-center">
              <div className="pricing-title">
                <span className="text-danger">2</span>
                <h3>Bank Transfer</h3>
              </div>
              <div className="pricing-action">
                <strong>Bank of Scotland (LLOYDS Halifax)</strong>
                <br />
                Sikh Aid Scotland
                <br />
                Account number: 00703341
                <br />
                Sort code: 80-07-60
                <br />
                IBAN: GB18 BOFS 8007 6000 7033 41
              </div>
            </div>
          </div>
          {/* <div className="col-md-6 col-lg-4">
            <div className="pricing-box pricing-simple p-5 bg-light border-top border-success text-center">
              <div className="pricing-title">
                <span>3</span>
                <h3>Contact Us</h3>
              </div>
              <div className="pricing-action">
                <a href="#" className="btn getStartBTN btn-lg">
                  Get Started
                </a>
              </div>
            </div>
          </div> */}
        </div>
        <Statistic />
        <div className="mt-5 mb-5">
          <div className="row align-items-center">
            <div className="col-12 col-lg">
              <h3>Why Sign up?</h3>
            </div>
            <Link to="/signup/donor">
              <button className="button button-large button-circle m-0">
                Signup
              </button>
            </Link>
          </div>
        </div>
        <div className="row col-mb-50 mb-4">
          <div className="col-sm-6 col-lg-3">
            <div className="feature-box fbox-center fbox-effect">
              <div className="fbox-icon">
                <i className="icon-screen i-alt"></i>
              </div>
              <div className="fbox-content">
                <h3>My Donations</h3>
                <p>Access to the records of all of your donations.</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="feature-box fbox-center fbox-effect">
              <div className="fbox-icon">
                <i className="icon-eye i-alt"></i>
              </div>
              <div className="fbox-content">
                <h3>Transparency</h3>
                <p>
                  Our digital solution enables to be transparent with our
                  donations collection and distributions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="feature-box fbox-center fbox-outline fbox-lg fbox-effect fbox-light">
              <div className="fbox-icon">
                <i className="icon-hand-up i-alt"></i>
              </div>
              <div className="fbox-content">
                <h3>Access to Applicants</h3>
                <p>
                  You can request for the applicants profiles and specifically
                  donate them.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="feature-box fbox-center fbox-border fbox-effect border-0">
              <div className="fbox-icon">
                <i className="icon-repeat"></i>
              </div>
              <div className="fbox-content">
                <h3>
                  Set Monthly Donations
                  <span className="subtitle">
                    You can see the applicants who require monthly donations and
                    subscribe for auto donations
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Method</Modal.Title>
        </Modal.Header>
        {showPaypalButton ? (
          {
            /* <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              createOrder={(data, actions) => createOrder(data, actions)}
              onApprove={(data, actions) => onApprove(data, actions)}
            />
          </PayPalScriptProvider> */
          }
        ) : (
          <>
            {" "}
            <i
              style={{ color: "green" }}
              className="fa fa-check fa-3x mt-2 text-center"
            ></i>{" "}
            <h5 className="mt-2 text-center">
              Transaction Successfully Completed
            </h5>
          </>
        )}
      </Modal>
    </>
  );
};
