import React, { useEffect } from "react";
import { DonationsService } from "../../services/Donations.service";
import SessionService from "../../services/Session.service";
const donationService = new DonationsService();
const user = SessionService.getLoggedIn();

function PayPalDonateButton({ id }) {
  console.log(id);
  useEffect(() => {
    // Check if the PayPal script is already loaded
    if (!window.PayPal || !window.PayPal.Donation || !window.PayPal.Donation.Button) {
      // Load the PayPal script
      const script = document.createElement("script");
      script.src = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
      script.async = true;
      script.onload = createPayPalButton;
      document.body.appendChild(script);

      // Cleanup: Remove the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    } else {
      // The PayPal script is already loaded, directly create the button
      createPayPalButton();
    }
  }, []);

  function createPayPalButton() {
    // Check if the button container already exists
    const container = document.querySelector(`#paypal-donate-button-container`);
    if (!container) {
      // Create the PayPal button only if the container does not exist
      window.PayPal.Donation.Button({
        env: "production",
        hosted_button_id: "MTZF6X8639NKS",
        image: {
          src: "/dotnew.png",
          title: "PayPal - The safer, easier way to pay online!",
          alt: "Donate with PayPal button",
        },
        onComplete: function (params) {
          const data = {
            reference: params.tx,
            amount: parseFloat(params.amt),
            source: "PayPal",
          };
          if (user && user.id) {
            data.DonorId = user.id;
          }
          if (id) {
            data.DonationRequestId = id;
          }
          donationService.paypalPayment(data).then((response) => {
            console.log(response);
          }).catch((error) => {
            // Handle the error
          });
        },
      }).render(`#paypal-donate-button-container${id}`);
    }
  }

  return <div id={`paypal-donate-button-container${id}`}></div>;
}

export default PayPalDonateButton;
