import { Carousel } from "react-bootstrap";

function Slider(props) {
    const { images = [] } = props;
    console.log("Images:", images);
    if (images.length === 0) {
        console.warn("No images provided to the Slider component.");
    }
    return <>
        <Carousel fade className="carousel slide">
            {images.map((image, index) =>
                <Carousel.Item key={index}>
                    <img className="d-block w-100" src={image} alt="" />
                </Carousel.Item>
            )}
        </Carousel>
    </>
}
export default Slider