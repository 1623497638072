import { React, useState, useEffect, useRef } from "react";
import { Breadcrumb } from "../shared/Breadcrumb";
import { Link } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import InfiniteScroll from "react-infinite-scroll-component";
import { DonationsService } from "../../services/Donations.service";
import { dateToDateString } from "../../utils/date.utils";
import { booleanToString } from "../../utils/helping.utils";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import AclService from "../../services/Acl.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export const Donations = () => {
  const history = useHistory();
  const filterFormRef = useRef();
  const donationService = new DonationsService();
  const [donations, setDonations] = useState([]);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const toggleFilter = () => setIsFilterOpened((wasOpened) => !wasOpened);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  console.log(donations);
  const deleteConfirmationResult = (isConfirmed) => {
    const id = deleteId;
    setDeleteId(null);
    if (!isConfirmed) {
      return;
    }

    donationService
      .deleteDonation(id)
      .then((response) => {
        const i = donations.findIndex((x) => x._id === id);
        if (i >= 0) {
          const tempDonations = [...donations];
          tempDonations.splice(i, 1);
          setDonations(tempDonations);
        }
      })
      .catch(() => { });
  };
  const getData = () => {
    const filters = {
      l: limit,
      o: offset,
    };

    console.log("Initial Filters:", filters);
    if (filterFormRef.current) {
      console.log("Form Values before Filter:", filterFormRef.current.values);
      console.log(filterFormRef);
      if (filterFormRef.current.values.source)
        filters.s = filterFormRef.current.values.source;
      console.log("Filters after adding source:", filters);
    }
    donationService.getDonations(filters).then((response) => {

      console.log("API Response:", response);

      if (offset) setDonations([...donations, ...response.data]);
      else setDonations(response.data);
    });
  };

  useEffect(() => {
    getData();
  }, [offset]);

  return (
    <>
      <Breadcrumb title="Donations" links={[{ title: "Donations" }]} />
      <ConfirmationModal
        show={deleteId !== null}
        onClose={deleteConfirmationResult}
      />
      <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
        <div className="row mb-3">
          <div className="col-12 mb-2">
            {AclService.checkPermission("DonationCreate") && (
              <Link to="/portal/donations/form">
                <button
                  type="button"
                  className="btn btn-light btn-sm float-right"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </Link>
            )}
            <button
              type="button"
              onClick={toggleFilter}
              className="btn btn-light btn-sm float-right  mr-1"
            >
              <i className="fas fa-filter"> Filters (0)</i>
            </button>
          </div>
          {isFilterOpened && (
            <div className="col-12">
              <Formik
                innerRef={filterFormRef}
                initialValues={{
                  source: "",
                  applicantName: "",
                }}
                onSubmit={() => {
                  if (offset) setOffset(0);
                  else getData();
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  resetForm,
                }) => (
                  <Form>
                    <Card>
                      <Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
                        <div className="row align-items-center">
                          <Field
                            name="source"
                            autoComplete="off"
                            id="s"
                            className="form-control col-sm-3 sm ml-1 mt-1"
                            placeholder="Search.."
                          />

                          <div className="col-sm-2 pt-2">
                            <button
                              type="submit"
                              className="btn btn-secondary btn-sm"
                            >
                              Filter
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm ml-1"
                              onClick={() => {
                                resetForm();
                                setTimeout(() => {
                                  if (offset) setOffset(0);
                                  else getData();
                                }, 200);
                              }}

                            >

                              Reset
                            </button>
                          </div>
                        </div>
                      </Card.Body>

                    </Card>

                  </Form>


                )}
              </Formik>

            </div>
          )}
        </div>
        <div className="table-responsive">
          <InfiniteScroll
            height={"72vh"}
            dataLength={donations.length}
            next={() => setOffset(offset + limit)}
            hasMore={true}
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Donor Name</th>
                  <th>Donation</th>
                  <th>Source</th>
                  <th>Donation Date</th>
                  <th>Verifications</th>
                  <th>Transaction ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation, index) => {
                  return (
                    <tr key={donation._id}>
                      {donation.donor && donation.donor.fName ? (
                        <td>
                          {donation.donor.fName} {donation.donor.lName}
                        </td>
                      ) : (
                        <td>Anonymous</td>
                      )}
                      <td>{donation.amount}</td>
                      <td>{donation.source}</td>
                      <td>{dateToDateString(donation.createdAt)}</td>
                      <td>{booleanToString(donation.isVerified)}</td>
                      <td>{donation.reference}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <i className="fas fa-cog"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {donation.source !== "PayPal" &&
                              donation.source !== "Bank" &&
                              donation.source !== "Crypto" &&
                              AclService.checkPermission("DonationDelete") && (
                                <Dropdown.Item
                                  eventKey={"1"}
                                  onClick={() => setDeleteId(donation._id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              )}
                            {AclService.checkPermission("DonationUpdate") && (
                              <Dropdown.Item
                                eventKey={"2"}
                                as={Link}
                                to={"/portal/donations/form/" + donation._id}
                              >
                                Edit
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              eventKey={"3"}  
                              onClick={() => history.push("/portal/applicant/" + donation.applicant._id)}
                            >
                              Details
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </section>
    </>
  );
};
