import { React, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumb } from "../Shared/BreadCrumb";
import { AuthService } from '../../services/Auth.service'
import { toast } from 'react-toastify';
const authService = new AuthService();

export const VerifyAccount = () => {
  const { email, verification } = useParams();
  const [state, setState] = useState("verifying");
  const [resendBtnDisabled, setResendBtnDisabled] = useState(false);

  const resendVerificationEmail = () => {
    setResendBtnDisabled(true);
    authService.resendVerificationEmail(email).then((response) => {
      toast.success("Verification email sent successfully");
    }).catch((error) => {
      toast.error("Invalid or already verified email address");
    })
  }

  useEffect(() => {
    authService.verifyAccount(verification).then((response) => {
      setState("verified");
    }).catch((error) => {
      setState("failed");
    })
  }, [])

  return (
    <>
      <Breadcrumb title="Reset Password" links={[{ title: "Reset Password" }]} />
      <div className="d-flex justify-content-center">
        <div className="mt-3 mb-3 p-2 col-sm-7 col-lg-6 center">
          {state === "verifying" && <>
            <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>
            <p className="mb-1 mt-3">
              <strong>Verifying Account!</strong>
            </p>
            <p>Please wait..</p>
          </>}

          {state === "verified" && <>
            <i className="fa fa-check fa-6x" style={{ color: "#685f5f73" }}></i>
            <p className="mb-1">
              <strong>Account Verified!</strong>
            </p>
            <p>Please login to continue <Link to="/login">Login</Link></p>
          </>}

          {state === "failed" && <>
            <i className="fa fa-ban fa-6x" style={{ color: "#685f5f73" }}></i>
            <p className="mb-1 mt-3">
              <strong>Verification Failed!</strong>
            </p>
            <p className="mb-2">Link is invalid or expired.</p>
            {email && <button className="btn flat-btn" onClick={resendVerificationEmail} disabled={resendBtnDisabled}>Resend Link</button>}
          </>}
        </div>
      </div>
    </>
  );
};
