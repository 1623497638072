import React from 'react';
import { Link } from "react-router-dom";

export const Breadcrumb = (props) => {
	const { links = [] } = props;

	return (
		<>
			<section id="page-title" className="page-title-mini d-none d-md-block">
				<div className="container clearfix">
					<h1>{props.title}</h1>
					<span>Everything you need to know about our Company</span>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/portal">Portal</Link></li>
					
						{links.map((link, index) =>
            <li key={link.title + index} className={`breadcrumb-item ${index === links.length -  1 ? 'active' : ''}`} aria-current="page">
              {link.to && (<Link to={link.to}>{link.title}</Link>)}
              {!link.to && (<>{link.title}</>)}
            </li>
          )}
					</ol>
				</div>
			</section>
		</>
	)
}
