import { React, useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import AsyncSelect from 'react-select/async';
import * as Yup from "yup";
import { Breadcrumb } from "../shared/Breadcrumb";
import { UserService } from "../../services/User.service";
import { DonationsService } from "../../services/Donations.service"
import { DonationRequestInfo } from "../DonationRequest/component/DonationRequestInfo";
import { toast } from "react-toastify";


const userService = new UserService();
const donationService = new DonationsService();
const DonationFormScheme = Yup.object().shape({
  amount: Yup.string()
    .required("Amount is required"),

  DonorId: Yup.string()
    .required("Donor is required"),

  reference: Yup.string()
    .required("Reference is required"),

  source: Yup.string()
    .required("Source is required"),

  notes: Yup.string()
});

export const DonationForm = () => {
  const { id, DonationRequestId } = useParams();
  const formRef = useRef();
  const history = useHistory();
  const [donationRequestId, setDonationRequestId] = useState(null);
  const [donationRequest, setDonationRequest] = useState(null);
  const [donation, setDonation] = useState(null);

  const getUsers = async (search) => {
    const conditions = { l: 20, role: "donor" };
    if (search) {
      conditions['s'] = search;
    }
    const response = await userService.getAllUser(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.fName + " " + x.lName
      };
    });
  }

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    const data = {
      amount: values.amount,
      DonorId: values.DonorId,
      reference: values.reference,
      source: values.source,
    }

    if (donationRequestId) data[`DonationRequestId`] = donationRequestId;

    if (donationRequest && id && data.amount + (donationRequest.collected - donation.amount) > donationRequest.requested) {
      const previousCollected = donationRequest.collected - donation.amount;
      toast.error(`Amount cannot be higher then ${donationRequest.requested - previousCollected}`);
      setSubmitting(false);
      return;
    } else if (donationRequest && !id && data.amount + donationRequest.collected > donationRequest.requested) {
      toast.error(`Amount cannot be higher then ${donationRequest.requested - donationRequest.collected}`);
      setSubmitting(false);
      return;
    }

    if (values.notes) {
      data[`notes`] = values.notes;
    }

    if (id) {
      donationService.update(id, data)
        .then((response) => {
          resetForm();
          toast.success("Donation updated successfully");
          if (DonationRequestId) history.push("/portal/donation-request");
          else history.push("/portal/donations");
        })
        .catch((error) => {
          if (error.error && error.error.message) {
            toast.error(error.error.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        })
      return;
    }

    donationService.create(data).then((response) => {
      resetForm();
      toast.success("Donation added successfully");
      if (DonationRequestId) history.push("/portal/donation-request");
      else history.push("/portal/donations");
      resetForm();
    })
      .catch((error) => {
        if (error.error && error.error.message) {
          toast.error(error.error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });

  };

  useEffect(() => {
    if (id) {
      donationService.getDonation(id).then((response) => {
        const formRefCurrent = formRef.current;
        if (response.data && formRefCurrent) {
          setDonation(response.data);

          formRefCurrent.setFieldValue("reference", response.data.reference);
          formRefCurrent.setFieldValue("notes", response.data.notes);
          formRefCurrent.setFieldValue("amount", response.data.amount);
          formRefCurrent.setFieldValue("source", response.data.source);
          if (response.data.DonationRequestId) setDonationRequestId(response.data.DonationRequestId);
          if (response.data.donor) {
            formRefCurrent.setFieldValue("DonorId", response.data.donor._id);
          }
        }
      })
    }

    if (DonationRequestId) setDonationRequestId(DonationRequestId)
  }, [])

  return (
    <>
      <Breadcrumb title="Donation Form" links={[{ title: "Donations", to: "/portal/donations" }, { title: "Donor Form" }]} />

      <section id="content" className="container-xl p-2 mt-3 ">
        {donationRequestId && <div className="row m-0">
          <div className="col-sm-12">
            <DonationRequestInfo DonationRequestId={donationRequestId} onDataLoad={(donationRequest) => {
              setDonationRequest(donationRequest);
            }} />
          </div>
        </div>}
        <Formik
          innerRef={formRef}
          initialValues={{
            amount: "",
            reference: "",
            DonorId: "",
            notes: "",
            source: "",
          }}
          validationSchema={DonationFormScheme}
          onSubmit={formSubmitted}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Form className="">
              <div className="row m-0">
                <div className="col-md-6 form-group">
                  <label className="label">Donor</label>
                  <AsyncSelect name="DonorId" cacheOptions defaultOptions loadOptions={getUsers} onChange={(val) => setFieldValue("DonorId", val.value)} />
                  {errors.DonorId && touched.DonorId ? (
                    <div className="error">{errors.DonorId}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label">Source</label>
                  <Field as="select" name="source" className="form-control">
                    <option value="">Select Source</option>
                    <option value="Cash" >Cash</option>
                    <option value="PayPal" >Paypal</option>
                  </Field>
                  {errors.source && touched.source ? (
                    <div className="error">{errors.source}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="pNo">
                    Reference/Source Transaction Id
                  </label>
                  <Field name="reference" id="" className="form-control" placeholder="Enter text" />
                  {errors.reference && touched.reference ? (
                    <div className="error">{errors.reference}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="pNo">
                    Amount
                  </label>
                  <Field type="number" name="amount" id="pNo" className="form-control" placeholder="Enter amount" />
                  {errors.amount && touched.amount ? (
                    <div className="error">{errors.amount}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="pNo">
                    Notes
                  </label>
                  <Field as="textarea" name="notes" id="pNo" className="form-control" placeholder="Text area" />
                </div>
                <div className="col-12 mt-2">
                  <button type="submit" disabled={isSubmitting} name="" className=" btn btn-secondary">
                    {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
                  </button>
                  {DonationRequestId &&
                    <Link to="/portal/donation-request">
                      <button type="button" name="" className=" btn btn-warn">
                        Cancel
                      </button>
                    </Link>
                  }
                  {!DonationRequestId &&
                    <Link to="/portal/donations">
                      <button type="button" name="" className=" btn btn-warn">
                        Cancel
                      </button>
                    </Link>
                  }
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    
    </>
  );
};
