import { React, useEffect, useRef, useState } from 'react';
import * as Yup from "yup";
import { Link, useParams, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Breadcrumb } from "../shared/Breadcrumb";
import { ProjectService } from "../../services/Project.service";
import { InstituteService } from '../../services/Institute.service';
import { ApplicantService } from '../../services/Applicant.service';
import { DonationRequestService } from "../../services/DonationRequest.service";
import { DonationRequestInfo } from "../DonationRequest/component/DonationRequestInfo";

const DonationRequestScheme = Yup.object().shape({
  ApplicantId: Yup.string()
    .required("Applicant is required"),

  CategoryId: Yup.string()
    .required("Category is required"),

  InstituteId: Yup.string(),

  requested: Yup.number()
    .min(1, "Requested amount must be greater than zero")
    .required("Requested amount is required"),

  status: Yup.string()
    .required("Status is required"),

  validTill: Yup.date("Must be a valid date")
    .required("Last date is required")
});

export const DonationRequestForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [validTill, setValidTillDate] = useState();
  const formRef = useRef();
  const projectService = new ProjectService();
  const instituteService = new InstituteService();
  const applicantService = new ApplicantService();
  const donationRequestService = new DonationRequestService();

  const clearDates = () => {
    setValidTillDate('')
  }

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    const data = {
      ApplicantId: values.ApplicantId, CategoryId: values.CategoryId, requested: values.requested,
      status: values.status,
    };

    if (values.InstitutionId) {
      data.InstitutionId = values.InstitutionId;
    }

    if (id) {
      donationRequestService.update(id, data)
        .then((response) => {
          resetForm();
          clearDates();
          toast.success("Fund request updated successfully!");
          history.push("/portal/donation-request");
        })
        .catch((error) => {
          if (error.error.message) {
            toast.error(error.error.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        })
      return;
    }

    donationRequestService.create(data).then((response) => {
      resetForm();
      clearDates();
      toast.success("Fund request created successfully!");
      history.push("/portal/donation-request");
    })
      .catch((error) => {
        console.log(error);
        if (error.response.message) {
          toast.error(error.response.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // const getInstitute = async (search) => {
  //   const conditions = { l: 20 };
  //   if (search) {
  //     conditions['s'] = search;
  //   }
  //   const response = await instituteService.getInstitutes(conditions);
  //   return response.data.map(x => {
  //     return {
  //       value: x._id,
  //       label: x.name
  //     };
  //   });
  // }

  const getApplicant = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions['s'] = search;
    }
    const response = await applicantService.getApplicants(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.fName + " " + x.lName
      };

    });
  }

  const getProjects = (search) => {
    const conditions = { l: 100 };
    if (search) {
      conditions['s'] = search;
    }

    projectService.getAllProjects(conditions).then((response) => {
      setCategories(response.data);
    })
  }
  const getInstitutes = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions['s'] = search;
    }

    instituteService.getInstitutes(conditions).then((response) => {
      setInstitutes(response.data);
    })
  }


  useEffect(() => {
    getProjects();
    getInstitutes();
    if (id) {
      donationRequestService.getDonationRequest(id).then((response) => {
        const formRefCurrent = formRef.current;
        console.log(response.data);
        if (response.data && formRefCurrent) {
          formRefCurrent.setFieldValue("ApplicantId", response.data.applicant._id);
          formRefCurrent.setFieldValue("CategoryId", response.data.category._id);
          formRefCurrent.setFieldValue("requested", response.data.requested);
          formRefCurrent.setFieldValue("status", response.data.status);
          if (response.data.institution) {
            formRefCurrent.setFieldValue("InstitutionId", response.data.institution._id);
          }
          if (response.data.validTill) {
            formRefCurrent.setFieldValue("validTill", new Date(response.data.validTill))
            setValidTillDate(new Date(response.data.validTill))
          };
        }
      })
    }
  }, [])

  return (
    <>
      <Breadcrumb title="Live Fund Request Form" links={[{ title: "Live Fund Request" }, { title: "Live Fund Request Form" }]} />
      <section id="content" className="container-xl p-2 mt-3 ">
        <DonationRequestInfo DonationRequestId={id} />
        <Formik
          innerRef={formRef}
          initialValues={{
            CategoryId: "",
            InstitutionId: "",
            ApplicantId: "",
            requested: "",
            status: "",
            validTill: "",
          }}
          validationSchema={DonationRequestScheme}
          onSubmit={formSubmitted}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Form className="">
              <div className="row m-0">
                <div className="col-md-6 form-group">
                  <label className="label">Applicant</label>
                  <AsyncSelect name="ApplicantId" cacheOptions defaultOptions loadOptions={getApplicant} onChange={(val) => setFieldValue("ApplicantId", val.value)} />
                  {errors.ApplicantId && touched.ApplicantId ? (
                    <div className="error">{errors.ApplicantId}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label">Category</label>
                  <Field as="select" name="CategoryId" className="form-control">
                    <option value="">Select Category</option>
                    {categories.map(category =>
                      <option key={category._id} value={category._id} >{category.name}</option>
                    )};
                  </Field>
                  {errors.CategoryId && touched.CategoryId ? (
                    <div className="error">{errors.CategoryId}</div>
                  ) : null}
                </div>
                {/* <div className="col-md-6 form-group">
                  <label className="label">Institute</label>
                  <AsyncSelect name="InstitutionId" cacheOptions defaultOptions loadOptions={getInstitute} onChange={(val) => setFieldValue("InstitutionId", val.value)} />
                  {errors.InstitutionId && touched.InstitutionId ? (
                    <div className="error">{errors.InstitutionId}</div>
                  ) : null}
                </div> */}
                <div className="col-md-6 form-group">
                  <label className="label">Institute</label>
                  <Field as="select" name="InstitutionId" className="form-control">
                    <option value="">Select Institute</option>
                    {institutes.map(institution =>
                      <option key={institution._id} value={institution._id} >{institution.name}</option>
                    )};
                  </Field>
                  {/* {errors.CategoryId && touched.CategoryId ? (
                      <div className="error">{errors.CategoryId}</div>
                    ) : null} */}
                </div>
                <div className="col-6 form-group">
                  <label className="label" htmlFor="sDate">
                    Last Date
                  </label>
                  <DatePicker placeholder="Start Date" className="form-control" selected={validTill} onfocus="(this.type='date')" onblur="(this.type='text')" onChange={(date) => { setFieldValue("validTill", date); setValidTillDate(date) }} />
                  {errors.validTill && touched.validTill ? (
                    <div className="error">{errors.validTill}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="pNo">
                    Requested
                  </label>
                  <Field type="number" name="requested" id="pNo" className="form-control" placeholder="Enter requested amount" />
                  {errors.requested && touched.requested ? (
                    <div className="error">{errors.requested}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label">Select Status </label>
                  <Field as="select" name="status" className="form-control">
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Canceled">Canceled</option>
                    <option value="Completed">Completed</option>
                  </Field>
                  {errors.status && touched.status ? (
                    <div className="error">{errors.status}</div>
                  ) : null}
                </div>

                <div className="col-12 mt-2">
                  <button type="submit" disabled={isSubmitting} className="btn btn-secondary">
                    {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
                  </button>
                  <Link to="/portal/donation-request">
                    <button type="button" className=" btn btn-warn">
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
};
