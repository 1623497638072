import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Header from "./Shared/Header";
import Footer from "./Shared/Footer";
import Home from "./Home/Home";
import Events from "./Event/Events";
import Details from "./Catagories/Details";
import Contact from "./Contact/Contact";
import Project from "./Catagories/Catagory"
import { SignUp } from "./Auth/SignUp";
import { Login } from './Auth/Login';
import { AboutUs } from './AboutUs/AboutUs';
import { EventDetailPage } from './Event/EventDetailPage';
import { Event } from './Event/Components/Event';
import { ForgotPassword } from './Auth/ForgotPassword';
import { ResetPassword } from './Auth/ResetPassword';
import { VerifyAccount } from './Auth/VerifyAccount';
import { OurTeam } from './OurTeam/OurTeam';
import { Donor } from './Donor/Donor';
import { Institute } from './Institute/Institute';
import { Donation } from './StaticPages/Donation';
import { InstituteDetail } from './Institute/InstituteDetail';

export const PublicSite = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <Header />
      <Switch>
        <Route exact path={`${path}login`} component={Login} />
        <Route exact path={`${path}project/:slug`} component={Details} />
        <Route exact path={`${path}about-us`} component={AboutUs} />
        <Route exact path={`${path}project`} component={Project} />
        <Route exact path={`${path}events`} component={Event} />
      
        <Route exact path={`${path}events/:slug`} component={EventDetailPage} />
        <Route exact path={`${path}contact`} component={Contact} />
        <Route exact path={`${path}signup`} component={SignUp} />
        <Route exact path={`${path}signup/:role`} component={SignUp} />
        <Route exact path={`${path}donors`} component={Donor} />
        <Route exact path={`${path}institute/details/:id`} component={InstituteDetail} />
        <Route exact path={`${path}institutes`} component={Institute} />
        <Route exact path={`${path}our-team`} component={OurTeam} />
        <Route exact path={`${path}forgot-password`} component={ForgotPassword} />
        <Route exact path={`${path}donation`} component={Donation} />
        <Route exact path={`${path}donation/:donationId`} component={Donation} />
        <Route exact path={`${path}reset-password/:email/:verification`} component={ResetPassword} />
        <Route exact path={`${path}verify-account/:email/:verification`} component={VerifyAccount} />
        <Route exact path={path} component={Home} />
      </Switch>
      <Footer />
    </>
  )
}
