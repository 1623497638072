import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class FundRequestService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/fund-request";
  } 

  create(ApplicantId, data) {
    return this.post(this.apiUrl + "/applicant/" + ApplicantId, data)
  }

  getCount(conditions = {}) {
    return this.get(this.apiUrl + "/count" + this.objToQuery(conditions));
  }

  getFundRequests(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }

  getFundRequest(id) {
    return this.get(this.apiUrl + "/" + id);
  }

  deleteFundRequest(ApplicantId, id) {
    return this.delete(this.apiUrl + "/applicant/" + ApplicantId + "/" + id);
  }

  update(ApplicantId, id, data) {
    return this.patch(this.apiUrl +  "/applicant/" + ApplicantId + "/" + id, data)
  }

  approve(ApplicantId, id) {
    return this.patch(this.apiUrl +  "/applicant/" + ApplicantId + "/" + id + "/approve", {})
  }
}