export class LocalStorageService {
  save(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      localStorage.setItem(key, value);
    }
  }

  get(key) {
    const value = localStorage.getItem(key);
    if (!value) return null;

    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  remove(key) {
    localStorage.removeItem(key);
  }
}