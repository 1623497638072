import React from 'react';
import { Breadcrumb } from "../../Shared/BreadCrumb";
import Events from '../Events';

export const Event = () => {
  return (
    <>
        <Breadcrumb title="Events" links={[{ title: "Events" }]} />
        <Events/>  
    </>
  );
};
