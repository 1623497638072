import { React, useState, useRef, useEffect } from "react";
import { TagsInput } from "react-tag-input-component";
import { Link, useHistory, useParams } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import AsyncSelect from 'react-select/async';
import { Breadcrumb } from "../shared/Breadcrumb";
import { EducationForm } from "./EducationForm";
import { ExperienceForm } from "./ExperienceForm";
import { dateToDateString } from "../../utils/date.utils";
import { booleanToString, removeQueryString } from "../../utils/helping.utils";
import { FundRequestCreateEdit } from "../FundRequest/FundRequestCreateEdit";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { ImageCropperNew } from "../shared/ImageCropperNew";

import { InstituteService } from "../../services/Institute.service";
import { ApplicantService } from "../../services/Applicant.service";
import { GeoDbService } from "../../services/GeoDb.service";
import { UploadService } from "../../services/Upload.service";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
const ApplicantScheme = Yup.object().shape({
  fName: Yup.string()
    .max(50, "First name cannot exceed 50 letters!")
    .required("First name is required"),

  lName: Yup.string()
    .max(50, "Last name cannot exceed 50 letters!")
    .required("Last name is required"),

  fatherName: Yup.string()
    .max(50, "Father name cannot exceed 50 letters!")
    .required("Father name is required"),

  motherName: Yup.string()
    .max(50, "Mother name cannot exceed 50 letters!")
    .required("Mother name is required"),

  villageName: Yup.string()
    .max(50, "Village name cannot exceed 50 letters!")
    .required("Village name is required"),

  postOffice: Yup.string()
    .max(50, "Post Office cannot exceed 50 letters!")
    .required("Post Office is required"),

  tehsil: Yup.string()
    .max(50, "Tehsil cannot exceed 50 letters!")
    .required("Tehsil is required"),

  district: Yup.string()
    .max(50, "District cannot exceed 50 letters!")
    .required("District is required"),

  state: Yup.string()
    .max(50, "State cannot exceed 50 letters!")
    .required("State is required"),

  nic: Yup.string(),

  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid'),

  photo: Yup.string(),

  idCardFront: Yup.string(),

  idCardBack: Yup.string(),

  instituteId: Yup.string(),

  isEx: Yup.boolean(),
  // CityId: Yup.string()
  //   .required("City is required"),

});

const applicantService = new ApplicantService();
const geoDbService = new GeoDbService();
const uploadService = new UploadService();
const instituteService = new InstituteService();

export const ApplicantForm = () => {

  const history = useHistory();
  const { id } = useParams();
  const formRef = useRef();
  const imgRef = useRef < HTMLImageElement > (null)
  const [institutes, setInstitutes] = useState([]);
  const [skills, setSkills] = useState([]);
  const [educationRecords, setEducationRecords] = useState([]);
  const [experienceRecords, setExperienceRecords] = useState([]);
  const [fundRequestRecords, setFundRequestRecords] = useState([]);
  const [educationDeleteUiId, setEducationDeleteUiId] = useState(null);
  const [experienceDeleteUiId, setExperienceDeleteUiId] = useState(null);
  const [fundRequestDeleteUiId, setFundRequestDeleteUiId] = useState(null);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [uploadingIdCardFront, setUploadingIdCardFront] = useState(false);
  const [uploadingIdCardBack, setUploadingIdCardBack] = useState(false);



  const [showEducationModal, setShowEducationModal] = useState(false);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [showFundRequestModal, setShowFundRequestModal] = useState(false);

  const [cardFile, setCardFile] = useState(null);

  const photoChanged = (data) => {
    const formRefCurrent = formRef.current;

    if (!data || !data.blob || !data.file || !formRefCurrent) {
      return;
    };

    setUploadingPhoto(true);
    uploadService.uploadBlob(data.blob, data.file, "public-read").then((response) => {
      formRefCurrent.setFieldValue("photo", removeQueryString(response.location));
      setUploadingPhoto(false);
    })
      .catch((error) => {
        toast.error("Image could not be uploaded")
        setUploadingPhoto(false);
      })
  }

  const removePhoto = () => {
    const formRefCurrent = formRef.current;
    if (formRefCurrent) formRefCurrent.setFieldValue("photo", "");
  }

  const IdCardFrontChanged = (data) => {
    const formRefCurrent = formRef.current;
    if (!data || !data.blob || !data.file || !formRefCurrent) {
      return;
    };

    setUploadingIdCardFront(true);
    uploadService.uploadBlob(data.blob, data.file, "public-read").then((response) => {
      formRefCurrent.setFieldValue("idCardFront", removeQueryString(response.location));
      setUploadingIdCardFront(false);
    })
      .catch((error) => {
        toast.error("Image could not be uploaded")
        setUploadingIdCardFront(false);
      })
  }

  const removeIdCardFront = () => {
    const formRefCurrent = formRef.current;
    if (formRefCurrent) formRefCurrent.setFieldValue("idCardFront", "");
  }

  const getInstitutes = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions['s'] = search;
    }

    instituteService.getInstitutes(conditions).then((response) => {
      setInstitutes(response.data);
    })
  }

  // const IdCardBackChanged = (data) => {
  //   const formRefCurrent = formRef.current;
  //   if (!data || !data.blob || !data.file || !formRefCurrent) {
  //     return;
  //   };

  //   setUploadingIdCardBack(true);
  //   uploadService.uploadBlob(data.blob, data.file, "public-read").then((response) => {
  //     formRefCurrent.setFieldValue("idCardBack", removeQueryString(response.location));
  //     setUploadingIdCardBack(false);
  //   })
  //     .catch((error) => {
  //       toast.error("Image could not be uploaded")
  //       setUploadingIdCardBack(false);
  //     })
  // }

  // const removeIdCardBack = () => {
  //   const formRefCurrent = formRef.current;
  //   if (formRefCurrent) formRefCurrent.setFieldValue("idCardBack", "");
  // }

  const [educationInitialValues, setEducationInitialValues] = useState({
    title: "",
    description: "",
    obtainedMarks: "",
    totalMarks: "",
    grade: "",
    markSheet: "",
    startDate: "",
    endDate: "",
    isCurrent: false
  });

  const [experienceInitialValues, setExperienceInitialValues] = useState({
    title: "",
    employerName: "",
    startDate: "",
    isCurrent: false,
    endDate: "",
  });

  const [fundRequestInitialValues, setFundRequestInitialValues] = useState({
    CategoryId: "",
    amount: "",
    InstituteId: "",
    getFundingTill: "",
    isMonthly: false
  });

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    console.log(
    values.isEx
    );

    const data = {
      fName: values.fName,
      lName: values.lName,
      fatherName: values.fatherName,
      motherName: values.motherName,
      villageName: values.villageName,
      postOffice: values.postOffice,
      tehsil: values.tehsil,
      district: values.district,
      state: values.state,
      nic: values.nic,
      // CityId: values.CityId,
      instituteId: values.instituteId,
      education: educationRecords,
      experience: experienceRecords,
      skills: skills,
      isEx: values.isEx
    }
    console.log("===>", values);
    if (values.phone) {
      data[`phone`] = values.phone;
    }

    if (values.photo) {
      data[`photo`] = values.photo;
    }

    if (values.idCardFront) {
      data[`idCardFront`] = values.idCardFront;
    }

    if (values.idCardBack) {
      data[`idCardBack`] = values.idCardBack;
    }

    if (fundRequestRecords.length) {
      data[`fundRequests`] = fundRequestRecords;
    }

    if (id) {
      applicantService.update(id, data)
        .then((response) => {
          toast.success("Applicant information updated successfully");
          resetForm();
          history.push("/portal/applicant")
        })
        .catch((error) => {

        })
        .finally(() => {
          setSubmitting(false);

        })
      return;
    }

    applicantService.create(data).then((response) => {
      toast.success("Applicant created successfully");
      resetForm();
      history.push("/portal/applicant")
    })
      .catch((error) => {
        toast.error("Applicant Could not be Created");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const educationAdded = (education) => {
    const tempRecords = [...educationRecords];
    const i = tempRecords.findIndex(x => x.uiId === education.uiId);

    if (i >= 0) {
      tempRecords[i] = education;
    } else {
      tempRecords.unshift(education);
    }

    setEducationRecords(tempRecords);
    setShowEducationModal(false);
  }

  const experienceAdded = (experience) => {
    const tempRecords = [...experienceRecords];
    const i = tempRecords.findIndex(x => x.uiId === experience.uiId);

    if (i >= 0) {
      tempRecords[i] = experience;
    } else {
      tempRecords.unshift(experience);
    }

    setExperienceRecords(tempRecords);
    setShowExperienceModal(false);
  }

  const fundRequestAdded = (fundRequest) => {
    const tempRecords = [...fundRequestRecords];
    const i = tempRecords.findIndex(x => x.uiId === fundRequest.uiId);

    if (i >= 0) {
      tempRecords[i] = fundRequest;
    } else {
      tempRecords.unshift(fundRequest);
    }

    setFundRequestRecords(tempRecords);
    setShowFundRequestModal(false);
  }

  const educationDeleteConfirmationResult = (isConfirmed) => {
    const uiId = educationDeleteUiId;
    setEducationDeleteUiId(null);

    if (!isConfirmed) {
      return;
    }

    const tempRecords = [...educationRecords];
    const index = tempRecords.findIndex(x => x.uiId === uiId);
    if (index < 0) {
      return;
    }
    tempRecords.splice(index, 1)
    setEducationRecords(tempRecords);

    // const educationRecord = tempRecords[index];

    // if (!educationRecord._id) {
    //   return;
    // }

    // educationService.deleteEducation(id, educationRecord._id).then(() => {
    //   toast.success("Education record deleted successfully");
    //   tempRecords.splice(index, 1)
    //   setEducationRecords(tempRecords);
    // })
    //   .catch((error) => {
    //     toast.error("Record could not be deleted");
    //   })
  }

  const experienceDeleteConfirmationResult = (isConfirmed) => {
    const uiId = experienceDeleteUiId;
    setExperienceDeleteUiId(null);

    if (!isConfirmed) {
      return;
    }

    const tempRecords = [...experienceRecords];
    const index = tempRecords.findIndex(x => x.uiId === uiId);
    if (index < 0) {
      return;
    }
    tempRecords.splice(index, 1)
    setExperienceRecords(tempRecords);
  }

  const fundRequestDeleteConfirmationResult = (isConfirmed) => {
    const uiId = fundRequestDeleteUiId;
    setFundRequestDeleteUiId(null);

    if (!isConfirmed) {
      return;
    }

    const tempRecords = [...fundRequestRecords];
    const index = tempRecords.findIndex(x => x.uiId === uiId);
    if (index < 0) {
      return;
    }
    tempRecords.splice(index, 1)
    setFundRequestRecords(tempRecords);
  }

  const getCities = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions['s'] = search;
    }

    const response = await geoDbService.getAllCities(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.name
      };
    });
  }

  useEffect(() => {
    if (id) {
      applicantService.getApplicant(id).then((response) => {
        setEducationRecords(response.data.education);
        setExperienceRecords(response.data.experience);
        setSkills(response.data.skills);

        const formRefCurrent = formRef.current;
        if (response.data && formRefCurrent) {
          formRefCurrent.setFieldValue("fName", response.data.fName);
          formRefCurrent.setFieldValue("lName", response.data.lName);
          formRefCurrent.setFieldValue("fatherName", response.data.fatherName);
          formRefCurrent.setFieldValue("motherName", response.data.motherName);
          formRefCurrent.setFieldValue("nic", response.data.nic);
          formRefCurrent.setFieldValue("email", response.data.email);
          formRefCurrent.setFieldValue("phone", response.data.phone);
          formRefCurrent.setFieldValue("photo", response.data.photo);
          formRefCurrent.setFieldValue("idCardFront", response.data.idCardFront);
          formRefCurrent.setFieldValue("idCardBack", response.data.idCardBack);
          formRefCurrent.setFieldValue("villageName", response.data.villageName);
          formRefCurrent.setFieldValue("postOffice", response.data.postOffice);
          formRefCurrent.setFieldValue("tehsil", response.data.tehsil);
          formRefCurrent.setFieldValue("district", response.data.district);
          formRefCurrent.setFieldValue("state", response.data.state);
          formRefCurrent.setFieldValue("instituteId", response.data.instituteId);
          formRefCurrent.setFieldValue("isEx", response.data.isEx);
        }
      })
    }
    getInstitutes()
  }, [])

  return (
    <>
      <Breadcrumb title="Applicant Form" links={[{ title: "Applicants" }, { title: "Applicant Form" }]} />
      <ConfirmationModal show={educationDeleteUiId !== null} onClose={educationDeleteConfirmationResult} />
      <ConfirmationModal show={experienceDeleteUiId !== null} onClose={experienceDeleteConfirmationResult} />
      <ConfirmationModal show={fundRequestDeleteUiId !== null} onClose={fundRequestDeleteConfirmationResult} />

      <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
        <h4>Personal Information</h4>
        <Formik
          innerRef={formRef}
          initialValues={{
            fName: "",
            lName: "",
            fatherName: "",
            motherName: "",
            villageName: "",
            postOffice: "",
            tehsil: "",
            district: "",
            idCardFront: "",
            idCardBack: "",
            state: "",
            nic: "",
            phone: "",
            photo: "",
            instituteId: "",
            CityId: "",
            skills: [],
            education: [],
            isEx: false,
          }}
          validationSchema={ApplicantScheme}
          onSubmit={formSubmitted}
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => (
            <Form className=" nobottommargin ">
              <div className="row m-0">
                <div className="col-md-3 form-group">
                  {uploadingPhoto && <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>}
                  {!uploadingPhoto && <>
                    {values.photo !== "" && values.photo !== undefined && <div className="imageUpload">
                    <img src={values.photo} alt="Applicant" />
                      <button className="btn btn-light pl-4 pr-4" onClick={removePhoto}><i className="fa fa-trash"></i></button>
                    </div>}
                    {!values.photo &&
                      <ImageCropperNew btnLabel="Upload Photo" inputName="photoFile" onCropComplete={photoChanged} aspect={8 / 8} />
                    }
                    {errors.photo && touched.photo ? (
                      <div className="error mt-1">{errors.photo}</div>
                    ) : null}
                  </>}
                </div>
                <div className="col-md-9"></div>

                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="fName">
                    First Name
                  </label>
                  <Field name="fName" autoComplete="off" id="fName" className="form-control" placeholder="Enter first name" />
                  {errors.fName && touched.fName ? (
                    <div className="error">{errors.fName}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="lName">
                    Last Name
                  </label>
                  <Field name="lName" autoComplete="off" id="lName" className="form-control" placeholder="Enter last name" />
                  {errors.lName && touched.lName ? (
                    <div className="error">{errors.lName}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="fatherName">
                    Father Name:
                  </label>
                  <Field name="fatherName" autoComplete="off" id="fatherName" className="form-control" placeholder="Enter father name" />
                  {errors.fatherName && touched.fatherName ? (
                    <div className="error">{errors.fatherName}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="motherName">
                    Mother Name:
                  </label>
                  <Field name="motherName" autoComplete="off" id="motherName" className="form-control" placeholder="Enter mother name" />
                  {errors.motherName && touched.motherName ? (
                    <div className="error">{errors.motherName}</div>
                  ) : null}
                </div>

                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="villageName">
                    Village Name:
                  </label>
                  <Field name="villageName" autoComplete="off" id="villageName" className="form-control" placeholder="Enter village name" />
                  {errors.villageName && touched.villageName ? (
                    <div className="error">{errors.villageName}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="postOffice">
                    Post Office:
                  </label>
                  <Field name="postOffice" autoComplete="off" id="postOffice" className="form-control" placeholder="Enter post office" />
                  {errors.postOffice && touched.postOffice ? (
                    <div className="error">{errors.postOffice}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="tehsil">
                    Tehsil:
                  </label>
                  <Field name="tehsil" autoComplete="off" id="tehsil" className="form-control" placeholder="Enter tehsile" />
                  {errors.tehsil && touched.tehsil ? (
                    <div className="error">{errors.tehsil}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="district">
                    District
                  </label>
                  <Field name="district" autoComplete="off" id="district" className="form-control" placeholder="Enter district" />
                  {errors.district && touched.district ? (
                    <div className="error">{errors.district}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="state">
                    State:
                  </label>
                  <Field name="state" autoComplete="off" id="state" className="form-control" placeholder="Enter state" />
                  {errors.state && touched.state ? (
                    <div className="error">{errors.state}</div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="nic">
                    Adhaar Card
                  </label>
                  <Field name="nic" autoComplete="off" id="nic" className="form-control" placeholder="Enter applicant Adhar Card" />
                  {errors.nic && touched.nic ? (
                    <div className="error">{errors.nic}</div>
                  ) : null}
                </div>

                <div className="col-md-6 form-group">
                  <label className="label" htmlFor="pNo">
                    Phone Number
                  </label>
                  <Field name="phone" id="pNo" className="form-control" placeholder="+91**********" />
                  {errors.phone && touched.phone ? (
                    <div className="error">{errors.phone}</div>
                  ) : null}
                </div>
                {/* <div className="col-md-6 form-group">
                  <label className="label">Institute</label>
                  <AsyncSelect name="instituteId" className="" cacheOptions defaultOptions loadOptions={getInstitutes} onChange={(val) => setFieldValue("instituteId", val.value)} />
                </div> */}
                <div className="col-md-6 form-group">
                  <label className="label">Institute</label>
                  <Field as="select" name="instituteId" className="form-control">
                    <option value="">Select Institute</option>
                    {institutes.map(institute =>
                      <option key={institute._id} value={institute._id} >{institute.name}</option>
                    )};
                  </Field>
                  {/* {errors.CategoryId && touched.CategoryId ? (
                      <div className="error">{errors.CategoryId}</div>
                    ) : null} */}
                </div>
                <div className="col-md-6 form-group">
                  <label className="label">IS EX</label>
                  <Field type="checkbox" name="isEx" id="isEx" />
                </div>

                {/* <div className="col-md-6 form-group">
                  <label className="label">City</label>
                  <AsyncSelect name="CityId" cacheOptions defaultOptions loadOptions={getCities} onChange={(val) => setFieldValue("CityId", val.value)} />
                  {errors.CityId && touched.CityId ? (
                    <div className="error">{errors.CityId}</div>
                  ) : null}
                </div> */}
                <div className="col-md-6 form-group">
                  <label htmlFor="basic-url">Upload ID Card Front Side</label>
                  <div className="col-md-6 form-group">
                    {uploadingIdCardFront && <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>}
                    {!uploadingIdCardFront && <>
                      {values.idCardFront !== "" && values.idCardFront !== undefined && <div className="imageUpload">
                        <img src={values.idCardFront} alt="Applicant" />
                        <button className="btn btn-light pl-4 pr-4" onClick={removeIdCardFront}><i className="fa fa-trash"></i></button>
                      </div>}
                      {!values.idCardFront &&
                        <ImageCropperNew btnLabel="Upload Id Card" inputName="idCardFrontFile" onCropComplete={IdCardFrontChanged} aspect={undefined} />
                      }
                      {errors.idCardFront && touched.idCardFront ? (
                        <div className="error mt-1">{errors.idCardFront}</div>
                      ) : null}
                    </>}
                  </div>
                  {/* <div className="input-group mb-3">
                    <input type="file" onChange={(e) => setCardFile(e.target.files)} />
                  </div> */}
                </div>
                {/* <div className="col-md-6 form-group">
                  <label htmlFor="basic-url">Upload ID Card Back Side</label>
                  <div className="col-md-6 form-group">
                    {uploadingIdCardBack && <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>}
                    {!uploadingIdCardBack && <>
                      {values.idCardBack !== "" && values.idCardBack !== undefined && <div className="imageUpload">
                        <img src={values.idCardBack} alt="Applicant" />
                        <button className="btn btn-light pl-4 pr-4" onClick={removeIdCardBack}><i className="fa fa-trash"></i></button>
                      </div>}
                      {!values.idCardBack &&
                        <ImageCropperNew btnLabel="Upload Id Card" inputName="idCardBackFile" onCropComplete={IdCardBackChanged} aspect={16/9} />
                      }
                      {errors.idCardBack && touched.idCardBack ? (
                        <div className="error mt-1">{errors.idCardBack}</div>
                      ) : null}
                    </>}
                  </div>
                </div> */}
                <div className="col-12">
                  <h4>Enter Skills</h4>
                  <TagsInput value={skills} onChange={setSkills} name="skill" placeHolder="Enter skill if any.." />
                  <em>Press enter to add new skill</em>
                </div>
                <div className="col-md-6 mt-2">
                  <h4>Educational Information</h4>
                </div>
                <div className="col-md-6">
                  <Button className="float-right" variant="secondary" onClick={() => {
                    setEducationInitialValues({
                      _id: "",
                      title: "",
                      description:"",
                      obtainedMarks: "",
                      totalMarks: "",
                      // grade: "",
                      markSheet: "",
                      startDate: "",
                      endDate: "",
                      isCurrent: false
                    });
                    setShowEducationModal(true);
                  }}>
                    <i className="float-right fas fa-plus"></i>
                  </Button>
                </div>
                <div className="col-12">
                  <div className="table-responsive mt-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Class</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Obtain Marks</th>
                          <th>Total Marks</th>
                          {/* <th>Grade</th> */}
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {educationRecords.map((educationRecord, index) => {
                          if (!educationRecord.uiId) educationRecord.uiId = educationRecord._id;
                          return (
                            <tr key={educationRecord.uiId || educationRecord._id}>
                              <td>{educationRecord.title}</td>
                              <td>{dateToDateString(educationRecord.startDate)}</td>
                              <td>{!educationRecord.isCurrent && educationRecord.endDate && dateToDateString(educationRecord.endDate)}</td>
                              <td>{!educationRecord.isCurrent && educationRecord.obtainedMarks}</td>
                              <td>{!educationRecord.isCurrent && educationRecord.totalMarks}</td>
                              {/* <td>{!educationRecord.isCurrent && educationRecord.grade}</td> */}
                              <td className="d-flex justify-content-end">
                                <Button variant="light" onClick={() => {
                                  setEducationInitialValues({
                                    _id: educationRecord._id || "",
                                    title: educationRecord.title,
                                    description: educationRecord.description,
                                    obtainedMarks: !educationRecord.isCurrent ? educationRecord.obtainedMarks : "",
                                    totalMarks: !educationRecord.isCurrent ? educationRecord.totalMarks : "",
                                    grade: !educationRecord.isCurrent ? educationRecord.grade : "",
                                    markSheet: !educationRecord.isCurrent ? educationRecord.markSheet : "",
                                    startDate: new Date(educationRecord.startDate),
                                    endDate: !educationRecord.isCurrent ? new Date(educationRecord.endDate) : "",
                                    isCurrent: educationRecord.isCurrent,
                                    uiId: educationRecord.uiId
                                  })
                                  setShowEducationModal(true)
                                }}>
                                  <i className="far fa-edit"></i>
                                </Button>
                                <Button variant="light" className="ml-2" onClick={() => setEducationDeleteUiId(educationRecord.uiId)}>
                                  <i className="far fa-trash-alt"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <h4>Experience Information</h4>
                </div>
                <div className="col-md-6 mt-2">
                  <Button className="float-right" variant="secondary" onClick={() => {
                    setExperienceInitialValues({
                      _id: "",
                      title: "",
                      employerName: "",
                      startDate: "",
                      isCurrent: false,
                      endDate: "",
                    });
                    setShowExperienceModal(true);
                  }}>
                    <i className="float-right fas fa-plus"></i>
                  </Button>
                </div>
                <div className="col-12">
                  <div className="table-responsive mt-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Employer Name</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {experienceRecords.map((experienceRecord, index) => {
                          if (!experienceRecord.uiId) experienceRecord.uiId = experienceRecord._id;
                          return (
                            <tr key={experienceRecord.title + index}>
                              <td>{experienceRecord.title}</td>
                              <td>{experienceRecord.employerName}</td>
                              <td>{dateToDateString(experienceRecord.startDate)}</td>
                              <td>{!dateToDateString.isCurrent && experienceRecord.endDate && dateToDateString(experienceRecord.endDate)}</td>
                              <td className="d-flex justify-content-end">
                                <Button variant="light" onClick={() => {
                                  setExperienceInitialValues({
                                    _id: experienceRecord._id || "",
                                    title: experienceRecord.title,
                                    employerName: experienceRecord.employerName,
                                    startDate: new Date(experienceRecord.startDate),
                                    endDate: !experienceRecord.isCurrent ? new Date(experienceRecord.endDate) : "",
                                    isCurrent: experienceRecord.isCurrent,
                                    uiId: experienceRecord.uiId
                                  })
                                  setShowExperienceModal(true)
                                }}>
                                  <i className="far fa-edit"></i>
                                </Button>
                                <Button variant="light" className="ml-2" onClick={() => setExperienceDeleteUiId(experienceRecord.uiId)}>
                                  <i className="far fa-trash-alt"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!id && <>
                  <div className="col-md-6 mt-2">
                    <h4>Fund Request</h4>
                  </div>
                  <div className="col-md-6 mt-2">
                    <Button className="float-right" variant="secondary" disabled={fundRequestRecords.length > 0} onClick={() => setShowFundRequestModal(true)}>
                      <i className="float-right fas fa-plus"></i>
                    </Button>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive mt-1">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Amount</th>
                            <th>Is Monthly</th>
                            <th>Get Funding Till</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fundRequestRecords.map((fundRequestRecord, index) => {
                            if (!fundRequestRecord.uiId) fundRequestRecord.uiId = fundRequestRecord._id;
                            return (
                              <tr key={fundRequestRecord + index}>
                                <td>{fundRequestRecord.amount}</td>
                                <td>{booleanToString(fundRequestRecord.isMonthly)}</td>
                                <td>{dateToDateString(fundRequestRecord.getFundingTill)}</td>
                                <td className="d-flex justify-content-end">
                                  <Button variant="light" onClick={() => {
                                    setFundRequestInitialValues({
                                      _id: fundRequestRecord._id || "",
                                      amount: fundRequestRecord.amount,
                                      CategoryId: fundRequestRecord.CategoryId,
                                      InstituteId: fundRequestRecord.InstituteId,
                                      getFundingTill: new Date(fundRequestRecord.getFundingTill),
                                      isMonthly: fundRequestRecord.isMonthly,
                                      uiId: fundRequestRecord.uiId
                                    })
                                    setShowFundRequestModal(true)
                                  }}>
                                    <i className="far fa-edit"></i>
                                  </Button>
                                  <Button variant="light" className="ml-2" onClick={() => setFundRequestDeleteUiId(fundRequestRecord.uiId)}>
                                    <i className="far fa-trash-alt"></i>
                                  </Button>
                                </td>
                              </tr>
                            )
                          }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>}
              </div>

              <div className="col-12 mt-2">
                <button type="submit" name="website-cost-submit" className="btn btn-secondary" disabled={isSubmitting}>
                  {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
                </button>
                <Link to="/portal/applicant">
                  <button type="button" name="website-cost-submit" className="btn btn-warn">Cancel</button>
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </section>

      <Modal show={showEducationModal} onHide={() => setShowEducationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> <h5 className="modal-title" id="educationFormModalLabel">Education</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EducationForm initialValues={educationInitialValues} onEducationAdded={educationAdded} />
        </Modal.Body>
      </Modal>

      <Modal show={showExperienceModal} onHide={() => setShowExperienceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> <h5 className="modal-title" id="experienceFormModalLabel"> Experience</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExperienceForm initialValues={experienceInitialValues} onExperienceAdded={experienceAdded} />
        </Modal.Body>
      </Modal>

      <Modal show={showFundRequestModal} onHide={() => setShowFundRequestModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> <h5 className="modal-title" id="fundRequestFormModalLabel">Fund Request</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FundRequestCreateEdit initialValues={fundRequestInitialValues} onFundRequestAdded={fundRequestAdded} />
        </Modal.Body>
      </Modal>
    </>
  );
};
