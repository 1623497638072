import React, { useState, useEffect, useRef } from "react";
import { ApplicantService } from "../../../services/Applicant.service";
import { dateToDateString } from "../../../utils/date.utils";
import { Button, Card } from "react-bootstrap";

const applicantService = new ApplicantService();

export const ApplicantPrimaryInfoView = (props) => {
  const ref = useRef();

  const { ApplicantId = props.ApplicantId, infoLevel = "basic" } = props;
  const [applicant, setApplicant] = useState({
    fName: "",
    lName: "",
    fatherName: "",
    phone: "",
    nic: "",
    CityId: {
      name: "",
      countryIso: "",
    },

    address: "",
    skills: [],
    education: [],
    experience: [],

  });

  useEffect(() => {
    if (ApplicantId) {
      applicantService.getApplicant(ApplicantId).then((response) => {
        if (response.data) {
          setApplicant(response.data);
        }
      });
    }
  }, [ApplicantId]);

  const currentEducation = applicant.education.find(edu => edu.isCurrent);

  const generatePdf = () => {
    window.print()
  }


  return (
    <>
      <section id="applicant-info" ref={ref}>
        {ApplicantId && applicant.fName && (
          <>
            {infoLevel === "full" &&
              applicant.photo !== "" &&
              applicant.photo !== undefined && (
                <>
                  <img
                    src={applicant.photo}
                    alt="Applicant"
                    style={{ height: "200px" }}
                  />
                </>
              )}
            <h4 className="mb-0 d-flex align-items-center">
              {applicant.fName + " " + applicant.lName}
              <span
                className="ml-1 badge rounded-pill bg-success pl-2 pr-2"
                style={{ fontSize: "55%" }}
              >
                <i className="fas fa-user-check"></i> Verified
              </span>
            </h4>
            <address className="mb-2">
              {applicant.address} {applicant.CityId?.name},{" "}
              {applicant.CityId?.countryIso}
            </address>
            <div className="row">
              <div className="col-sm-12">
                <ul className="d-flex flex-row flex-wrap justify-content-between mb-0 list-unstyled">
                  <li className="mr-4">
                    Father Name: <label>{applicant.fatherName}</label>
                  </li>
                  <li className="mr-4">
                    Mother Name: <label>{applicant.motherName}</label>
                  </li>

                  <li className="mr-4">
                    Phone: <label>{applicant.instituteId.phone || "-"}</label>
                  </li>
                  {/* <li className="mr-4">Email: <label>{applicant.email || "-"}</label></li> */}
                  <li className="mr-4">
                    Institute: <label>{applicant.instituteId.name || "-"}</label>
                  </li>
                  <li className="mr-4">
                    Village: <label>{applicant.villageName || "-"}</label>
                  </li>
                  <li className="mr-4">
                    Tehsil: <label>{applicant.tehsil || "-"}</label>
                  </li>
                  <li className="mr-4">
                    Post Office: <label>{applicant.postOffice || "-"}</label>
                  </li>
                  <li className="mr-4">
                    District: <label>{applicant.district || "-"}</label>
                  </li>
                  <li className="mr-4">
                    State: <label>{applicant.state || "-"}</label>
                  </li>
                  <li className="mr-4 ">
                    <h4 className="bg-warning p-2">
                      Current Applicant: <label className="fw-bold" style={{ fontSize: '1.2em' }}>{currentEducation ? 'Yes' : 'No'}</label>
                    </h4>
                  </li>
                  {/* <li className="mr-4">ID: <label>{applicant.nic || "-"}</label></li> */}
                </ul>
              </div>
            </div>

            {infoLevel === "full" && (
              <>
                <p className="mb-2">
                  Skills: <label>{applicant.skills.join(", ")}</label>
                </p>
              </>
            )}

            {infoLevel === "full" && applicant.education.length > 0 && (
              <>
                <h4 className="mt-2 mb-2">Education Information</h4>
                {applicant.education.length > 0 &&
                  applicant.education.map((educationRecord) => (
                    <Card className="shadow p-3 mb-2 bg-white rounded bg-white">
                      <div className="row d-flex" key={educationRecord._id}>
                        <div className="col-lg-8">
                          <div className="d-flex flex-row justify-content-around mt-3">
                            <ul className="d-flex flex-column justify-content-between mb-0 list-unstyled">
                              <li className="mt-2">
                                Class: <label>{educationRecord.title}</label>
                              </li>
                              <li className="mt-2">
                                Start Date:{" "}
                                <label>
                                  {!educationRecord.isCurrent &&
                                    educationRecord.endDate &&
                                    dateToDateString(educationRecord.endDate)}
                                </label>
                              </li>
                              <li className="mt-2">
                                End Date:{" "}
                                <label>
                                  {" "}
                                  {!educationRecord.isCurrent &&
                                    educationRecord.endDate &&
                                    dateToDateString(educationRecord.endDate)}
                                </label>
                              </li>
                              <li className="mt-2">
                                Obtain Marks:{" "}
                                <label>
                                  {!educationRecord.isCurrent &&
                                    educationRecord.obtainedMarks}
                                </label>
                              </li>
                            </ul>
                            <ul className="d-flex flex-column mb-0 list-unstyled">
                              <li className="ml-5 mt-2">
                                Total Marks:{" "}
                                <label>
                                  {!educationRecord.isCurrent &&
                                    educationRecord.totalMarks}
                                </label>
                              </li>
                              <li className="ml-5 mt-2">
                                Grade:{" "}
                                <label>
                                  {" "}
                                  {!educationRecord.isCurrent &&
                                    educationRecord.grade}
                                </label>
                              </li>{" "}
                              <li className="ml-5 mt-2">
                                Note:{" "}
                                <label>{educationRecord.description}</label>
                              </li>{" "}
                              <li className="ml-5 mt-2">
                                Added On:{" "}
                                <label>
                                  {dateToDateString(educationRecord.createdAt)}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {educationRecord.marksSheet && (
                            <a href={educationRecord.marksSheet}>
                              <img
                                src={educationRecord.marksSheet}
                                width={250}
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </Card>
                  ))}
                {/* <div className="table-responsive mt-1">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Class</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Obtain Marks</th>
                      <th>Total Marks</th>
                      <th>Grade</th>
                      <th>Note</th>
                      <th>Added On</th>
                      <th>Result Card</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicant.education.length > 0 &&
                      applicant.education.map((educationRecord) => (
                        <tr key={educationRecord._id}>
                          <td>{educationRecord.title}</td>
                          <td>{dateToDateString(educationRecord.startDate)}</td>
                          <td>
                            {!educationRecord.isCurrent &&
                              educationRecord.endDate &&
                              dateToDateString(educationRecord.endDate)}
                          </td>
                          <td>
                            {!educationRecord.isCurrent &&
                              educationRecord.obtainedMarks}
                          </td>
                          <td>
                            {!educationRecord.isCurrent &&
                              educationRecord.totalMarks}
                          </td>
                          <td>
                            {!educationRecord.isCurrent &&
                              educationRecord.grade}
                          </td>
                          <td>{educationRecord.description}</td>
                          <td>{dateToDateString(educationRecord.createdAt)}</td>
                          {educationRecord.marksSheet && (
                            <td>
                              <img src={educationRecord.marksSheet} />
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div> */}
              </>
            )}

            {infoLevel === "full" && applicant.experience.length > 0 && (
              <>
                <h4 className="mt-2 mb-2">Experience Information</h4>
                <div className="table-responsive mt-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Employer Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Added On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {applicant.experience.length &&
                        applicant.experience.map((experienceRecord) => (
                          <tr key={experienceRecord._id}>
                            <td>{experienceRecord.title}</td>
                            <td>{experienceRecord.employerName}</td>
                            <td>
                              {dateToDateString(experienceRecord.startDate)}
                            </td>
                            <td>
                              {!dateToDateString.isCurrent &&
                                experienceRecord.endDate &&
                                dateToDateString(experienceRecord.endDate)}
                            </td>
                            <td>
                              {dateToDateString(experienceRecord.createdAt)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}

      </section>
      <Button onClick={generatePdf}>Generate PDF</Button>
    </>
  );
};
