import axios from 'axios';
import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class UploadService extends HttpService {
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/upload";
  }

  async uploadFile(file, access = "private") {
    const fileName = file.name;
    const response = await this.getUploadUrl(fileName, access);
    if (!response.data || !response.data.location) {
      return null;
    }
    await this.uploadToS3(response.data.location, file, file.type);
    return await response.data;
  }

  async uploadBlob(blob, file, access = "private") {
    const fileName = file.name;
    const response = await this.getUploadUrl(fileName, access);
    if (!response.data || !response.data.location) {
      return null;
    }
    await this.uploadToS3(response.data.location, blob, file.type);
    return await response.data;
  }

  isValidFile(file, allowedTypes = []) {
    const extension = file.name.split('.').pop().toLowerCase();
    return allowedTypes.indexOf(extension) > -1;
  }

  getUploadUrl(name, access = "private") {
    return this.post(this.apiUrl, { name, access });
  }

  uploadToS3(location, fileContent, contentType) {
    return axios.put(location, fileContent, { "Content-Type": contentType });
  }
}