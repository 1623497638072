import React from "react";
import { Link } from "react-router-dom";

const containerHeight = "500px"; // Updated height value to match the image height

function Slider() {
  return (
    <>
      <section id="slider" className="slider-element full-screen">
        <div
          className="full-screen dark section top-2 mt-0 w-100"
          style={{ height: containerHeight, position: "relative" }}
        >
          <div className="container clearfix" style={{ height: "100%", marginTop:"150px"}}>
            <div className="slider-caption">
              <h1
                data-animate="fadeInUp"
                className="d-none d-sm-block"
                style={{fontSize: "48px"}}
              >
             Educate a Child today
              </h1>
              {/* <p
                className="d-none d-md-block"
                data-animate="fadeInUp"
                data-delay="200"
              >
                <b>
                  There are many different ways you can help - by donating your
                  time, money, skills and prayer.
                </b>
              </p> */}
              <Link
                to="/donation"
                className="mt-3 button button-border button-light button-rounded button-large noleftmargin nobottommargin d-none d-md-inline-block"
              >
                Donate Now
              </Link>
              {/* <Link
                to="/about-us"
                className="button button-rounded button-white button-light button-large"
              >
                Read More
              </Link> */}
            </div>
          </div>

          <div className="video-wrap" style={{ height: "100%" }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 50%)", // Change the opacity here (0.3 in this example)
              }}
            />
            <img
              src="images/mainImage.jpeg"
              alt="Banner"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover", // Ensure the image covers the container
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Slider;
