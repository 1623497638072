import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../shared/header.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AclService from "../../services/Acl.service";
import SessionService from "../../services/Session.service";

export const Sidebar = () => {
	const [showMediaIcons, setShowMediaIcons] = useState(false);
	const history = useHistory();

	return (
		<>
			<header id="header" className="no-sticky">
				<div id="header-wrap">
					<div className="container clearfix">
						<div id="primary-menu-trigger"> <Link to="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
							<span style={{ color: "black" }}>
								<i className="icon-reorder "></i>
							</span>
						</Link>
						</div>
						<div id="logo" className="nobottomborder">
							<Link to="/portal" className="standard-logo" data-dark-logo="images/sikhlogo1.png"><img src="/images/sikhlogo1.png" alt="Sikh Logo" /></Link>
							<Link to="/portal" className="retina-logo" data-dark-logo="images/sikhlogo1.png"><img src="/images/sikhlogo1.png" alt="Sikh Logo" /></Link>
						</div>
						<nav id="primary-menu">
							<ul className={showMediaIcons ? "sf-js-enabled d-block" : " menu-link"}>
								<li><Link to="/portal" className="sf-with-ul"><div>Home</div></Link></li>

								{AclService.checkPermissions(["ApplicantCreate", "ApplicantReturn", "ApplicantUpdate", "ApplicantDelete", "ApplicantView", "ApplicantCreateFundRequest"]) &&
									<li><Link to="/portal/applicant" className="sf-with-ul"><div>Applicants</div></Link></li>}

								{AclService.checkPermissions(["InstituteCreate", "InstituteReturn", "InstituteUpdate", "InstituteDelete"]) &&
									<li><Link to="/portal/institute" className="sf-with-ul"><div>Institutes</div></Link></li>}
								{AclService.checkPermissions(["ApprovalReturn", "ApprovalUpdate", "ApprovalDelete", "ApprovalViewDetail", "ApprovalApprove"]) &&
									<li><Link to="/portal/fund-request/approvals" className="sf-with-ul"><div>Approvals</div></Link></li>}

								{AclService.checkPermissions(["FundRequestCreate", "FundRequestReturn", "FundRequestUpdate", "FundRequestDelete",]) &&
									<li><Link to="/portal/fund-request" className="sf-with-ul"><div>Monthly Requests</div></Link></li>}

								{AclService.checkPermissions(["DonationRequestCreate", "DonationRequestReturn", "DonationRequestUpdate", "DonationRequestDelete", "DonationRequestAddDonation"]) &&
									<li><Link to="/portal/donation-request" className="sf-with-ul"><div>Donation Requests</div></Link></li>}

								{AclService.checkPermissions(["DonationCreate", "DonationReturn", "DonationUpdate", "DonationDelete", "DonationAddDonation"]) &&
									<li><Link to="/portal/donations" className="sf-with-ul"><div>Donations</div></Link></li>}

								{AclService.checkPermissions(["DisbursementCreate", "DisbursementReturn", "DisbursementUpdate", "DisbursementDelete"]) &&
									<li><Link to="/portal/disbursement" className="sf-with-ul"><div>Disbursement</div></Link></li>}

								{AclService.checkPermissions(["AccessRequestReturn", "AccessRequestStatus"]) &&
									<li><Link to="/portal/access-request" className="sf-with-ul"><div>Access Request</div></Link></li>}

								{AclService.checkPermissions(["UserCreate", "UserReturn", "UserUpdate", "UserDelete"]) &&
									<li><Link to="/portal/user" className="sf-with-ul"><div>Users</div></Link></li>}

								{AclService.checkPermissions(["EventCreate", "EventReturn", "EventUpdate", "EventDelete"]) &&
									<li><Link to="/portal/event" className="sf-with-ul"><div>Events</div></Link></li>}


								{AclService.checkPermissions(["RoleCreate", "RoleReturn", "RoleUpdate", "RoleDelete"]) &&
									<li><Link to="/portal/role" className="sf-with-ul"><div>Role</div></Link></li>}

							</ul>
						</nav>
						<div className="clearfix d-none d-lg-block">
							<Link to="/portal/profile-change">
								<button className="flat-btn flex" style={{ color: "#030303", fontSize: "80%", alignItems: "center" }}>
									<i className="fa fa-user mr-1"></i>Profile
								</button>
							</Link>
							<Link to="/portal/change-password">
								<button className="flat-btn flex" style={{ color: "#030303", fontSize: "80%", alignItems: "center" }}>
									<i className="fa fa-key mr-1"></i>Change Password
								</button>
							</Link>
							<button className="flat-btn flex" onClick={() => {
								SessionService.logout();
								history.push("/login");
							}} style={{ color: "#030303", fontSize: "80%", alignItems: "center" }}>
								<i className="icon-off mr-1"></i>Log Out
							</button>
						</div>

					</div>

				</div>
			</header>
		</>
	)
}
