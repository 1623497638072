import { React, useState, useEffect } from 'react';
import { FaDonate, FaCreativeCommonsBy, FaUsers } from "react-icons/fa";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DonationRequestService } from "../../../services/DonationRequest.service";
const donationRequestService = new DonationRequestService();

function Statistic(props) {
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({
    collected: 0,
    disbursed: 0,
    requested: 0
  });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const {filters = {}} = props;
    donationRequestService.getDonationRequestsLineChartData(filters).then((response) => {
      if (response.data.length) {
        setData(response.data);
      }
    })

    donationRequestService.getDonationRequestsPieChartData(filters).then((response) => {
      console.log("Pie Chart", response);
      if (response.data.length) {
        setTotals(response.data[0]);
      }
    })

    donationRequestService.getCount(filters).then((response) => {
      console.log("Count", response);
      setTotalCount(response.data.count);
    })
  }, [props, props.filters])

  return (
    <>
      <section>
        <div className="container mt-5  pt-2 pb-2">
          <div className=" mt-1">
            <h3 className="d-flex justify-content-right"> Statistics </h3>
            <p className='ppri'>The statistics show our part in the project.</p>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 col-md-6 col-lg-3 mb-2 center">
              <div className="card">
                <div className="card-body">
                  <ul className="list-unstyled mb-0">
                    <li>Total Required</li>
                    <FaDonate size={28} />
                    <li className="mt-2">{totals.requested}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mb-2 center">
              <div className="card">
                <div className="card-body">
                  <ul className="list-unstyled mb-0">
                    <li>Total Collected</li>
                    <GiReceiveMoney size={28} />
                    <li className="mt-2">{totals.collected}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mb-2 center">
              <div className="card">
                <div className="card-body">
                  <ul className="list-unstyled mb-0">
                    <li>Total Disbursed</li>
                    <GiPayMoney size={28} />
                    <li className="mt-2">{totals.disbursed}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mb-2 center">
              <div className="card">
                <div className="card-body">
                  <ul className="list-unstyled mb-0">
                    <li>Total Applicants</li>
                    <FaUsers size={28} />
                    <li className="mt-2">{totalCount}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className='col-sm-12 col-md-6 col-lg-12 d-flex justify-content-center w-100'>
              {/* <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                  <Tooltip />
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" interval="preserveEnd" />
                  <YAxis interval="preserveEnd" />
                  <Line type="monotone" dataKey="Required" stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="Collected" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer> */}
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="requested" name="Requested" stroke="#8884d8" />
                  <Line type="monotone" dataKey="collected" name="Collected" stroke="#000000" />
                  <Line type="monotone" dataKey="disbursed" name="Disbursed" stroke="#2a2299" />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="date" />
                  <YAxis />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Statistic;