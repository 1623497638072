import { React, useEffect, useRef } from 'react';
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import { Link, useParams, useHistory } from 'react-router-dom';
import { RoleService } from '../../services/Role.service';
import { Breadcrumb } from '../shared/Breadcrumb';
import * as Yup from "yup";
import { useState } from 'react';

const RoleSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required"),

  isPublic: Yup.boolean(),

  permissions: Yup.array()
    .min(1, "Permissions are required")
});


export const RoleForm = () => {
  const history = useHistory();
  const { id } = useParams();
  const formRef = useRef();
  const [allPermissions, setAllPermissions] = useState([]);
  const roleService = new RoleService();

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    const data = {
      name: values.name,
      isPublic: values.isPublic,
      permissions: values.permissions,
    };

    if (id) {
      roleService.update(id, data)
        .then((response) => {
          toast.success("Role updated successfully");
          resetForm();
          history.push("/portal/role");
        })
        .catch((error) => {
          if(error.response.data.message) toast.error(error.response.data.message);
        })
        .finally(() => {
          setSubmitting(false);
        })
      return;
    }

    roleService.create(values).then((response) => {
      toast.success("Role created successfully");
      resetForm();
      history.push("/portal/role");
    })
      .catch((error) => {
        if(error.response.data.message) toast.error(error.response.data.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const getAllPermission = () => {
    roleService.allPermission().then((response) => {
      setAllPermissions(response.data);
    })
  }

  useEffect(() => {
    if (id) {
      roleService.getRole(id).then((response) => {
        const formRefCurrent = formRef.current;
        if (response.data && formRefCurrent) {
          formRefCurrent.setFieldValue("name", response.data.name);
          formRefCurrent.setFieldValue("isPublic", response.data.isPublic);
          formRefCurrent.setFieldValue("permissions", response.data.permissions);
        }
      })
    }
    getAllPermission();
  }, [])

  return (
    <>
      <Breadcrumb title="Role Form" links={[{ title: "Roles", to: "/portal/role" }, { title: "Role Form" }]} />

      <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
        <Formik
          innerRef={formRef}
          initialValues={{
            name: "",
            permissions: [],
            isPublic: false
          }} validationSchema={RoleSchema}
          onSubmit={formSubmitted}>
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="row m-0">
                <div className="col-6">
                  <label className="label" htmlFor="fName"> Name</label>
                  <Field autoComplete="off" id="fName" name="name" className="sm-form-control" />
                  {errors.name && touched.name ? (<div className="error">{errors.name}</div>) : null}
                </div>

                <div className="col-6 form-group mt-4">
                  <Field id="isPublic" className="radio-style" name="isPublic" type="checkbox" />
                  <label htmlFor="isPublic" className="label radio-small ml-1 nobottommargin">Is Public</label>
                </div>

                <div className="col-sm-12 mt-4" role="group">
                  {allPermissions.map((permission, index) => {
                    return <label key={permission._id}>
                      <Field type="checkbox" className="radio-style" name="permissions" id={permission._id} value={permission.identifier} />
                      <label htmlFor={permission._id} className="label radio-small ml-1 mr-2 nobottommargin"> {permission.module} {permission.action}</label>
                    </label>
                  })}
                  {errors.permissions && touched.permissions ? (<div className="error">{errors.permissions}</div>) : null}
                </div>

                <div className="col-12 mt-4">
                  <button type="submit" name="website-cost-submit" className="btn btn-secondary">
                    {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
                  </button>
                  <Link to="/portal/role">
                    <button type="button" className="btn btn-warn  ">
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
};