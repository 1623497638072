import { Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import { ProjectService } from "../../../services/Project.service";

const Projects = () => {
	const projectService = new ProjectService();
	const [categories, setCategories] = useState([])

	function getData() {
		projectService.getAllProjects().then((response) => {
			setCategories(response.data);
		})
	}

	useEffect(() => {
		getData();
	}, [])

	return (
		<>
			<section>
				<div className="container-fluid priority pt-3 30px pb-3 20px" >
					<h2 className="center mt-3 15px heading2 ">Projects</h2>
					<div className="container clearfix">
						<div className="row">

							{categories.map((category, index) => {
								return <div key={index} className="col-sm-12 col-md-6 col-lg-4 mb-4">
									<div className="feature-box center media-box1 fbox-bg priorityBox h-100">
										<div className="fbox-media mb-1">
											<img src={category.thumbnail} alt="Why choose Us?" />
										</div>
										<div className="fbox-desc p-3 10px">
											<h3>{category.name}</h3>
											<p className="ppri">
												{category.summary}
											</p>
											<Link to={{ pathname: `/project/${category.slug}` }} className="button button-border mt-3 nobottommargin">Read More</Link>
										</div>
									</div>
								</div>
							}
							)}
						</div>
					</div>
				</div>

			</section>
		</>
	);
}

export default Projects;


