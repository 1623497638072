import { React, useState, useEffect } from 'react'
import { Breadcrumb } from '../Shared/BreadCrumb';
import { InstituteService } from "../../services/Institute.service";
import { Link } from 'react-router-dom';

export const Institute = () => {
  const instituteService = new InstituteService();
  const [institutes, setInstitutes] = useState([]);
 
  const condition = {
    approved: true,
    withStats: true
  }

  const getData = () => {
    instituteService.getInstitutes(condition).then((response) => {
      setInstitutes(response.data);
    })
  }

  useEffect(() => {
    getData();
  }, [])
  return (
    <>
      <Breadcrumb title="Institutes" links={[{ title: "Institutes" }]} />
      <div className="container clearfix">
        <div className="row mt-5">
          {institutes.map((institute, index) => {
            return <div className='shop product-1 mb-2 clearfix' key={institute._id}>
              <div className="product clearfix">
                <div className="product-image">
                  {/* <a href="#"> */}
                  <img src={institute.thumbnail} alt={institute.name} />
                  {/* </a> */}
                </div>
                <div className="product-desc">
                  <div className="product-title">
                    <h3>
                      {/* <a href="#"> */}
                      {institute.name}
                      {/* </a> */}
                    </h3>
                    <address className="mb-2">{institute.address}, {institute?.city?.name}</address>
                  </div>
                  <p>{institute.about}</p>
                  <ul className="d-flex justify-content-between list-unstyled totalGraph mb-0">
                    {/* <li className='mr-4'>
                      <h5 className='mb-2'>Total Applicant</h5>
                      <p className="mb-0">{institute.stats.applicants}</p>
                    </li> */}
                    {/* <li className='mr-4'>
                      <h5 className='mb-2'>Applicant Served </h5>
                      <p className="mb-0">10</p>
                    </li> */}
                    {/* <li className='mr-4'>
                      <h5 className='mb-2'>Applicant Remaining</h5>
                      <p className="mb-0">5000</p>
                    </li> */}
                  </ul>
                  {/* <ul className="d-flex justify-content-between list-unstyled totalGraph mt-5"> */}
                    {/* <li className='mr-4'>
                      <h5 className='mb-2'>Total Funding Required</h5>
                      <p className="mb-0">{institute.stats.requested}</p>
                    </li>
                    <li className='mr-4'>
                      <h5 className='mb-2'>Funding Collected</h5>
                      <p className="mb-0">{institute.stats.collected}</p>
                    </li> */}
                    {/* <li className='mr-4'>
                      <h5 className='mb-2'>Funding Remaining</h5>
                      <p className="mb-0">{institute.stats.requested - institute.stats.collected}</p>
                    </li> */}
                  {/* </ul> */}
                  
                  <Link className="button button-border mt-3 nobottommargin" to={"/institute/details/" + institute._id}>Read More</Link  >
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </>
  );
}
