import React, { useState } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { ExperienceService } from "../../services/Experience.service";
import { RandomId } from "../../utils/helping.utils";

const ExperienceScheme = Yup.object().shape({
  title: Yup.string()
    .required("Title is required"),

  employerName: Yup.string()
    .required("Employer name is required"),

  startDate: Yup.date()
    .required("Start date is required"),

  isCurrent: Yup.boolean(),

  endDate: Yup.date().when('isCurrent', {
    is: false,
    then: Yup.date().required('End date is required'),
    otherwise: Yup.date(),
  }),
});

export const ExperienceForm = (props) => {
  const experienceService = new ExperienceService();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const clearDates = () => {
    setStartDate('')
    setEndDate('')
  }

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    if (!values.uiId) {
      values.uiId = RandomId();
    }

    if (!values._id || !props.ApplicantId) {
      props.onExperienceAdded(values);
      resetForm();
      clearDates();
      setSubmitting(false);
      return;
    }

    experienceService.update(props.ApplicantId, values._id, values)
      .then((response) => {
        toast.success("Experience updated successfully!");
        props.onExperienceAdded(values);
        resetForm();
        clearDates();
      })
      .catch((error) => {
        toast.error("Experience could not be updated!");
      })
      .finally(() => {
        setSubmitting(false);
      })
  };

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={ExperienceScheme}
        onSubmit={formSubmitted}
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="col-12 form-group">
              <label className="label " htmlFor="title">
                Title
              </label>
              <Field type="text" name="title" id="title" className="form-control required" placeholder="Title" />
              {errors.title && touched.title ? (
                <div className="error">{errors.title}</div>
              ) : null}
            </div>
            <div className="col-12 form-group">
              <label className="label" htmlFor="eName">
                Employer Name
              </label>
              <Field type="text" name="employerName" id="eName" className="form-control required" placeholder="Employer name" />
              {errors.employerName && touched.employerName ? (
                <div className="error">{errors.employerName}</div>
              ) : null}
            </div>
            <div className="col-12 form-group">
              <label className="label" htmlFor="sDate">
                Start Date
              </label>
              <DatePicker className="form-control" selected={startDate} onChange={(date) => { setFieldValue("startDate", date); setStartDate(date) }} placeholder="Start Date" />
              {errors.startDate && touched.startDate ? (
                <div className="error">{errors.startDate}</div>
              ) : null}
            </div>
            <div className="col-12 form-group">
              <Field id="current" className="radio-style" name="isCurrent" type="checkbox" />
              <label htmlFor="current" className="label radio-small ml-1 nobottommargin">Is Current</label>
            </div>
            {!values.isCurrent &&
              <div className="col-12 form-group">
                <label className="label" htmlFor="eDate">
                  End Date
                </label>
                <DatePicker className="form-control" selected={endDate} onChange={(date) => { setFieldValue("endDate", date); setEndDate(date) }} />
                {errors.endDate && touched.endDate ? (
                  <div className="error">{errors.endDate}</div>
                ) : null}
              </div>
            }
            <div className="col-12 form-group">
              <button type="submit" className="btn btn-secondary">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
