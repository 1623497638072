
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./css/bootstrap.min.css";
import "./css/style.css";
import "./css/dark.css";
import "./css/Home.css";
import "./css/font-icons.css";
import "./css/navbar.css";
import "./css/responsive.css";
import "./css/fonts.css";
import "./css/magnific-popup.css";

import "./css/my.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { Portal } from "./Portal";
import { PublicSite } from "./PublicSite";

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route path="/portal" component={Portal} />
          <Route path="/" component={PublicSite} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
