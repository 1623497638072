
import Projects from "./Components/Project";
import { Breadcrumb } from "../Shared/BreadCrumb";
function Category() {
    return (
        <>
            <Breadcrumb title="Projects" links={[{ title: "Projects" }]} />
            <Projects />
        </>
    );
}
export default Category;