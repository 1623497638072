export const AccountRegistered = () => {
  return (
    <>
      <i className="fa fa-check fa-6x" style={{ color: "#685f5f73" }}></i>
      <p className="mb-1">
        <strong>Account Registered!</strong>
      </p>
      <p>Please check your email to verify the account</p>
    </>
  );
};
