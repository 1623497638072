

function AboutUs() {
  return (
    <>
      <div className="container clearfix">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 ">
            <h2 className="mb-1" style={{ color: "#00365b", marginLeft: 8 }}>
              {" "}
              About us
            </h2>
            <div className="d-flex align-items-center">
              <div
                style={{
                  backgroundColor: "#fff",

                  padding: 10,
                }}
              >
                <span className="span">
                  Every person on the earth has the right to get all the basic
                  needs. However, many people lack access to fundamental things
                  like education. They are assets to our community and can prove
                  to raise economic growth. But it's only possible if we provide
                  them with everything to improve their quality of life. Thus,
                  the Sikh Aid Global is established to provide these opportunities
                  to them. We have many projects to provide clean water,
                  education, homes, healthcare activities, and empower women.
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-7">
            <img src="/images/AboutUsImage.jpeg" alt="Illustration image about donation" />
          </div>
        </div>
      </div>
    </>
  );
}
export default AboutUs;
