import { React, useEffect, useState } from 'react';
import { DonationRequestService } from '../../../services/DonationRequest.service';

export const DonationRequestInfo = (props) => {
  const { DonationRequestId } = props;
  const donationRequestService = new DonationRequestService();
  const [donationRequest, setDonationRequest] = useState({
    uid: "",
    applicant: {
      fName: "",
      lName: ""
    },
    requested: 0,
    collected: 0,
    disbursed: 0,
    validTill: ""
  });

  useEffect(() => {
    if (DonationRequestId) {
      donationRequestService.getDonationRequest(DonationRequestId).then((response) => {
        if (response.data) {
          if (props.onDataLoad) props.onDataLoad(response.data);
          setDonationRequest(response.data);
        }
      })
    }
  }, [DonationRequestId])

  return (
    <>
      {donationRequest.uid &&
        <>
          <h4 className="mb-0 d-flex align-items-center">{donationRequest.applicant?.fName} {donationRequest.applicant?.lName}
          </h4>
          <div className="row">
            <div className="col-sm-12">
              <ul className="d-flex flex-row flex-wrap justify-content-between mb-0 list-unstyled">
                <li className="mr-4">Id: <label>{donationRequest.uid}</label></li>
                <li className="mr-4">Requested Fund: <label>{donationRequest.requested}</label></li>
                <li className="mr-4">Collected Fund: <label>{donationRequest.collected}</label></li>
                <li className="mr-4">Disbursed Fund: <label>{donationRequest.disbursed}</label></li>
              </ul>
            </div>
          </div>
        </>
      }
    </>);
};
