import { useState } from "react";
import { useParams } from 'react-router-dom';
import { Breadcrumb } from "../shared/Breadcrumb";
import { DonationRequestInfo } from "./component/DonationRequestInfo";
import { ApplicantPrimaryInfoView } from "../Applicant/Components/ApplicantPrimaryInfoView";

export const DonationRequestDetail = () => {
  const { DonationRequestId } = useParams();
  const [applicantId, setApplicantId]  =  useState(null);

  const donationRequestViewDataLoaded = (data) => {
    if (data?.applicant?._id && data?.AccessStatus && data?.AccessStatus == "Approved") {
      setApplicantId(data.applicant._id)
    }
  }

  return <>
    <Breadcrumb title="Donation Request" links={[{ title: "Donation Requests", to: "/portal/fund-request" }, { title: "Donation Request" }]} />
    <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
      { applicantId && <ApplicantPrimaryInfoView ApplicantId={applicantId} infoLevel="full" /> }

      <DonationRequestInfo DonationRequestId={DonationRequestId} onDataLoad={donationRequestViewDataLoaded} />
    </section>
  </>
}