import { useParams } from "react-router-dom";
import { useState } from "react";
import { DonorSignUp } from "./signup/DonorSignUp";
import { InstituteSignUp } from "./signup/InstituteSignUp";
import { SignUpForm } from "./signup/SignUpForm";
import { AccountRegistered } from "./signup/AccountRegistered";
import { Breadcrumb } from "../Shared/BreadCrumb";

export const SignUp = () => {
  const { role } = useParams();
  const isRoleValid = () => ["donor", "institute"].some((m) => m === role);

  const [hideRole, setHideRole] = useState(role && isRoleValid() ? true : false);
  const [selectedRole, setSelectedRole] = useState(role && isRoleValid() ? role : "donor");
  const [accountRegistered, setAccountRegistered] = useState(false);

  const roleChanged = (role) => setSelectedRole(role);
  const registrationComplete = (registrationStatus) => setAccountRegistered(registrationStatus);

  return (
    <>
      <Breadcrumb title="Sign Up" links={[{ title: "Sign Up" }]} />

      <div className="container-xl">
        <div className="row mr-0 ml-0 pb-4">
          <div className="col-lg-6 col-sm-12 col-md-12 mt-3 mb-3 p-lg-5">
            {!accountRegistered && <SignUpForm hideRole={hideRole} role={selectedRole} onRoleChange={roleChanged} onRegistration={registrationComplete} />}
            {accountRegistered && <AccountRegistered />}
          </div>

          <div className="col-lg-6 col-sm-12 col-md-12 mt-3 mb-3 p-lg-5">
            {selectedRole && selectedRole === "institute" && <InstituteSignUp />}
            {selectedRole && selectedRole === "donor" && <DonorSignUp />}
          </div>
        </div>
      </div>
    </>
  );
};
