import { useState } from "react";
import { useParams } from 'react-router-dom';
import { Breadcrumb } from "../shared/Breadcrumb";
import { FundRequestView } from "./Components/FundRequestView";
import { ApplicantPrimaryInfoView } from "../Applicant/Components/ApplicantPrimaryInfoView";

export const FundRequestDetail = () => {
  const { FundRequestId } = useParams();
  const [applicantId, setApplicantId]  =  useState(null);

  const fundRequestViewDataLoaded = (data) => {
    if (data?.applicant?._id) {
      console.log("=== Done === ", data.applicant._id);
      setApplicantId(data.applicant._id)
    }
  }

  return <>
    <Breadcrumb title="Fund Request" links={[{ title: "Fund Requests", to: "/portal/fund-request" }, { title: "Fund Request" }]} />
    <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
      { applicantId && <ApplicantPrimaryInfoView ApplicantId={applicantId}  /> }
      <h5 className="mb-2 mt-3">Fund Request</h5>
      <FundRequestView FundRequestId={FundRequestId} onDataLoad={fundRequestViewDataLoaded} />
    </section>
  </>
}