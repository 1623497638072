import React from "react";
import { Link } from "react-router-dom";

export const AboutUs = () => {
  return (
    <>
      <section id="page-title" className="page-title-mini d-none d-md-block">
        <div className="container clearfix">
          <h1>About us</h1>
          <span>Everything you need to know about our Company</span>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Projects
            </li>
          </ol>
        </div>
      </section>
      <section id="content">
        <div className="container clearfix mt-5 ">
          <p>
            <span className="dropcap">E</span>very person on the earth has the right to get all the basic needs. However, many people lack access to fundamental things like education. They are assets to our community and can prove to raise economic growth. But it's only possible if we provide them with everything to improve their quality of life. Thus, the Sikh Aid Global is established to provide these opportunities to them. We have many projects to provide clean water, education, homes, healthcare activities, and empower women. But schooling is our primary concern. Therefore, we offer a transparent procedure of donations through which you can get complete access to the recipients' information.
            Donate now to contribute to this compassionate act. It's not important how much you donate because a single penny can make a difference.

          </p>
        </div>
        <div className="container clearfix mt-5">
          <div className="col_one_third">
            <div className="heading-block fancy-title nobottomborder title-bottom-border">
              <h4>
                Why choose <span>Us</span>.
              </h4>
            </div>
            <p>
              There are numerous reasons. Firstly, we only collect donations for verified cases displayed on our website.
              Secondly, we provide a transparent procedure where we only act as a medium to connect donors and beneficiaries.
              All the information about the beneficiary is available on our website.
              You can check it and even request a profile to contact the beneficiaries and meet them to verify the case further.
            </p>
          </div>
          <div className="col_one_third">
            <div className="heading-block fancy-title nobottomborder title-bottom-border">
              <h4>
                Our <span>Mission</span>.
              </h4>
            </div>
            <p>
              Sikh Aid Global () was inaugurated in 2002.
              It's a non-profit organization that is devoted to serving the Sikh community.
              SAG particularly focuses on the rural areas of India by providing access to basic needs like health, water, and education to improve their quality of life ultimately.
            </p>
          </div>
          <div className="col_one_third col_last">
            <div className="heading-block fancy-title nobottomborder title-bottom-border">
              <h4>
                How to get  <span>Support</span>.
              </h4>
            </div>
            <p>
              You can get support by your information and the kind of support you want. First, you need to contact us through our official email or contact number. Then, you need to go through a simple procedure to ask for your details. Finally, you'll get donations when our team verifies your case
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
