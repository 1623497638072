import { React, useState, useEffect } from 'react';
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';
import { DonationsService } from "../../services/Donations.service";
import { DonationRequestService } from "../../services/DonationRequest.service";
import { dateToDateTimeString } from '../../utils/date.utils';



const COLORS = ['#0088FE', '#00C49F', '#cccccc', '#FF8042', '#348042'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

// PieChart.legend.update({ enabled: (PieChart.containerWidth > 900) });


export const PieGraph = () => {
  const donationService = new DonationsService();
  const donationRequestService = new DonationRequestService();
  const [donations, setDonations] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [offset, setOffset] = useState(0);
  const limit = 6;
 
  const getData = () => {
    const filters = {
      l: limit,
      o: offset
    };
    donationService.getDonations(filters).then((response) => {
      if (offset) setDonations([...donations, ...response.data]);
      else setDonations(response.data);
    })
  }

  const getPieChartData = () => {
    donationRequestService.getDonationRequestsPieChartData().then((response) => {
      if (response.data.length) {
        setPieChartData(response.data.map(x => {
          return {
            name: x.category,
            value: x.requested
          }
        }));
      }
    })
  }

  useEffect(() => {
    getPieChartData();
    getData();
  }, [])
  console.log(donations);

  return (
    <>
      <section id="content" className="container mt-1 mb-3">
        <div className="row pt-1">
          <div className="col-sm-12 col-md-6 col-lg-5 mb-3 mt-3 w-100">
            <div className='card pcard'>
              <p className="ml-2 mt-2" ><strong>Requested Finance Distribution</strong></p>
              <ul className="d-flex justify-content-center nobottommargin">
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Tooltip />
                    <Legend />
                    <Pie
                      data={pieChartData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {
                        pieChartData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                      }
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-7 mt-3">
            <div className='card pcard'>
              <p className="ml-2 mt-2" ><strong>Recent Donations</strong></p>
              <div className="table-responsive">
                <table className="table table-bordered nobottommargin">
                  <thead>
                    <tr>
                      <th className='p-1'>Name</th>
                      <th className='p-1'>Donation</th>
                      <th className='p-1'>Time</th>
                    </tr>
                  </thead>
                  <tbody>{donations.map((donation, index) => {
                    return <tr key={donation._id}>
                      {donation.donor && donation.donor.fName ? 
                      <td className='p-1'>{donation.donor.fName} {donation.donor.lName}</td> : <td className='p-1'>Anonymous</td>}
                      <td className='p-1'>{donation.amount}</td>
                      <td className='p-1'>{dateToDateTimeString(donation.createdAt)}</td>
                    </tr>
                  })
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
