import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class AuthService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/auth";
  }

  signUp(data) {
    return this.post(this.apiUrl + '/signup', data);
  }

  verifyAccount(verification) {
    return this.post(this.apiUrl + '/verify-email', { verification });
  }

  resendVerificationEmail(email) {
    return this.post(this.apiUrl + '/resend-verify-email', { email });
  }

  logIn(data) {
    return this.post(this.apiUrl + '/login', data);
  }

  resetPassword(email, password) {
    return this.post(this.apiUrl + '/reset-password', {email, password});
  }

  forgotPassword(data) {
    return this.post(this.apiUrl + '/forgot-password', data)
  }

  forgotPasswordVerify(verification) {
    return this.post(this.apiUrl + '/forgot-password-verify', { verification })
  }
}