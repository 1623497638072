import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class AccessRequestService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/access-request";
  } 

  create(data) {
    return this.post(this.apiUrl, data)
  } 
  getData(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }
  approve(RecordId, data) {
    return this.patch(this.apiUrl +  "/"  + RecordId + "/status", data);
  }
}
