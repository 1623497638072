import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class DonationRequestService extends HttpService {
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/donation-request";
  }
  create(data) {
    return this.post(this.apiUrl, data)
  }

  getCount(conditions = {}) {
    return this.get(this.apiUrl + "/count" + this.objToQuery(conditions));
  }

  getDonationRequests(conditions = {}) {
    console.log(conditions,"++++" );
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }

  getDonationRequestsLineChartData(conditions = {}) {
    return this.get(this.apiUrl + "/line-chart" + this.objToQuery(conditions));
  }

  getDonationRequestsPieChartData(conditions = {}) {
    return this.get(this.apiUrl + "/pie-chart" + this.objToQuery(conditions));
  }

  getDonationRequest(id) {
    return this.get(this.apiUrl + "/" + id);
  }

  deleteDonationRequest(id, data) {
    return this.delete(this.apiUrl + "/" + id, data)
  }

  update(id, data) {
    return this.patch(this.apiUrl + "/" + id, data)
  }

  approve(ApplicantId, id) {
    return this.patch(this.apiUrl + "/applicant/" + ApplicantId + "/" + id + "/approve", {})
  }
}