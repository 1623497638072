
import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";

function Header() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);

  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }
  const logos = [
    "/images/newLogo.png",
    "/images/america.jpg",
    "/images/canada.jpg",
    // Add more logo paths as needed
  ];
  useEffect(() => {
    // Use setInterval to change the logo every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 3000); // Change logo every 5 seconds

    return () => {
      clearInterval(intervalId); // Clear the interval on component unmount
    };
  }, []);

  return (
    <>
      <div className="stretched">
        <header id="header" className="full-header">
          <div id="header-wrap">
            <div className="container clearfix">
              <div id="primary-menu-trigger"> <Link to="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
                <span style={{ color: "black" }}>
                  <i className="icon-reorder "></i>
                </span>
              </Link></div>
               <div id="logo">
                  <Link to="/"  className="standard-logo mr-4">
                    <img src={logos[currentLogoIndex]} alt="Sikh Logo" />
                  </Link>
                  <Link to="/" className="retina-logo mr-4" data-dark-logo="images/logo-dark@2x.png"><img src={logos[currentLogoIndex]} alt="Sikh Logo" /></Link>
              </div>
              <nav id="primary-menu">
                <ul className={showMediaIcons ? "sf-js-enabled d-block" : " menu-link"} data-easing="easeInOutExpo" data-speed="1500" >
                  <li><Link to="/" data-href=""><div>Home</div></Link></li>
                  <li><a href="https://news.sikhaidglobal.com/" data-href="" target="blank"><div>Blog</div></a></li>
                  {/* <li><Link to="/donors" data-href=""><div>Donors</div></Link></li> */}
                  <li><Link to="/institutes" data-href=""><div>Institutes</div></Link></li>
                  <li><Link to="/events" data-href=""><div>Events</div></Link></li>
                  {/* <li><Link to="/about-us" data-href=""><div>About Us</div></Link></li> */}
                  <li><Link to="/our-team" data-href=""><div>Our Team</div></Link></li>
                  {/* <li>
                    <NavDropdown className="navDropdown pl-0" title="About"  show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown} >
                      <NavDropdown.Item className="navDropdown" onClick={hideDropdown}><Link to="/about-us" data-href="">About us</Link></NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item className="navDropdown" onClick={hideDropdown}><Link to="/our-team" data-href="">Our Team</Link></NavDropdown.Item>
                    </NavDropdown>
                  </li> */}
                  <li><Link to="/contact" data-href=""><div>Contact</div></Link></li>
                </ul>
                <div id="top-search">
                  <Link to='/login' id="top-search-trigger"><i className="fas fa-user-circle ml-1 fa-lg"></i></Link>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}
export default Header;