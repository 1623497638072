import React from 'react';
import { Breadcrumb } from '../Shared/BreadCrumb';
import { useParams } from "react-router-dom";
import CategoryApplicantTable from "../Catagories/Components/CategoryApplicantTable"


export const InstituteDetail = () => {
  let { id } = useParams();

  return (
    <>
      <Breadcrumb className="" title="Details" links={[{ title: "Institutes" }, { title: "Details" }]} />
      <div className="container mt-4">
        <h2 className="center mt-3 mb-3 heading2">Institute Details</h2>
        <CategoryApplicantTable InstitutionId={id}></CategoryApplicantTable>
      </div>
    </>
  )
}
