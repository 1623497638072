import { Link } from "react-router-dom"
function Footer() {
	return (
		<>
			<footer id="footer" className="dark" >
				<div className="container">
					<div className="footer-widgets-wrap clearfix">
						<div className="col_two_third">
							<div className="col_one_third mt-4">
								<div className="widget clearfix">
									<img src="/images/Logo.png" url="https://sikhcouncilscotland.org/wp-content/uploads/2016/07/SAG_logo_SM1-02.png 626w, https://sikhcouncilscotland.org/wp-content/uploads/2016/07/SAG_logo_SM1-02-300x160.png 300w" sizes="(max-width: 626px) 100vw, 626px" alt="" className="so-widget-image center mb-2" />
									<p className="text-center" >Charity Reg. SC042137</p>
								</div>
							</div>

							<div className="col_one_third mt-4 ml-4">
								<div className="widget widget_links clearfix">
									<h4>Quick Links</h4>
									<ul className="d-flex justify-content-between">
										<li>
											<Link to="/about-us" data-href="">About us</Link>
										</li>
										<li>
											<Link to="/contact">
												Feedback
											</Link>
										</li>
									</ul>
									<ul className="d-flex justify-content-between">
										<li>
											<Link to="/signup">
												Register now
											</Link>
										</li>
										<li>
											<Link to="/signup/donor">
												Donate us
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="col_one_third col_last">
							<div className="widget clearfix">
								<div className="row">
									<div className="col-lg-12 mt-4">
										<address>
											{/* <strong>Headquarters:</strong>
											<br />
											17 Harris Close
											Newton Mearns
											Glasgow */}
											{/* <br />
											G77 6TU
											Scotland
											<br /> */}
										</address>
									</div>
									<div className="col-lg-6 clearfix bottommargin-sm">
										<a
											href="https://web.facebook.com/sikh007/?fref=nf&_rdc=1&_rdr"
											className="social-icon si-dark si-colored si-facebook nobottommargin"
										>
											<i className="icon-facebook"></i>
											<i className="icon-facebook"></i>
										</a>
										<a href="#">
											<small>
												<strong>Like us</strong>
												<br />
												on Facebook
											</small>
										</a>
									</div>
									<div className="col-lg-6 clearfix">
										<a
											href="#"
											className="social-icon si-dark si-colored si-rss nobottommargin"

										>
											<i className="icon-rss"></i>
											<i className="icon-rss"></i>
										</a>
										<a href="#">
											<small >
												<strong>Subscribe</strong>
												<br />
												to RSS Feeds
											</small>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="copyrights">
					<div className="container">
						<div className="row">
							{/* <div className=" footer col-sm-12 col-md-4 col-lg-6 mt-5 ">
								Copyrights © 2021 All Rights Reserved by sikhcouncil Inc.
								<div className="copyright-links"><Link to="#">Terms of Use</Link> / <Link to="#">Privacy Policy</Link></div>
							</div> */}
							<div className="col_half">
								Copyrights © 2021 All Rights Reserved by sikhcouncil Inc.<br />
								<div className="copyright-links"><a href="#">Terms of Use</a> / <a href="#">Privacy Policy</a></div>
							</div>
							<div className="col_half col_last tright">

								<i className="icon-envelope2"></i> sikhaidglobal@gmail.com<span className="middot">·</span> <i className="icon-headphones"></i>  @+44 7723 013160 <span className="middot"></span><br></br>
								USA:    Deep Singh <span className="middot">·</span> <i className="icon-headphones"></i>+1 (530) 8441523 <span className="middot"></span><br></br>
								Canada: Sukhwinder Samra<span className="middot">·</span> <i className="icon-headphones"></i>  +1 (360) 5610677 <span className="middot"></span><br></br>
								Canada:  Sheenu Singh<span className="middot">·</span> <i className="icon-headphones"></i>  +1 (778) 9871772 <span className="middot"></span><br></br>
								India:  Tarandip Singh<span className="middot">·</span> <i className="icon-headphones"></i>  +91 987 6600922  <span className="middot"></span><br></br>
								
							</div>
							{/* <div className="d-flex justify-content-center footer col-sm-12 col-md-4 col-lg-6 mt-5">
								<a href="https://web.facebook.com/sikh007/?fref=nf&_rdc=1&_rdr" className="social-icon si-small si-borderless si-facebook">
									<i className="icon-facebook"></i>
									<i className="icon-facebook"></i>
								</a>

								<Link to="#" className="social-icon si-small si-borderless si-twitter">
									<i className="icon-twitter"></i>
									<i className="icon-twitter"></i>
								</Link>

								<Link to="#" className="social-icon si-small si-borderless si-gplus">
									<i className="icon-gplus"></i>
									<i className="icon-gplus"></i>
								</Link>

								<Link to="#" className="social-icon si-small si-borderless si-pinterest">
									<i className="icon-pinterest"></i>
									<i className="icon-pinterest"></i>
								</Link>

								<Link to="#" className="social-icon si-small si-borderless si-vimeo">
									<i className="icon-vimeo"></i>
									<i className="icon-vimeo"></i>
								</Link>

								<Link to="#" className="social-icon si-small si-borderless si-github">
									<i className="icon-github"></i>
									<i className="icon-github"></i>
								</Link>

								<Link to="#" className="social-icon si-small si-borderless si-yahoo">
									<i className="icon-yahoo"></i>
									<i className="icon-yahoo"></i>
								</Link>

								<Link to="#" className="social-icon si-small si-borderless si-linkedin">
									<i className="icon-linkedin"></i>
									<i className="icon-linkedin"></i>
								</Link>
								<br />
								<ul>
									<i className="icon-envelope2"></i> info@sikhcouncilscotland.org <span className="middot"></span>
									<i className="icon-headphones"></i>:0772 3013160 <span className="middot"></span>
									<i className="icon-skype2"></i> sikhcouncilOnSkype
								</ul>
							</div> */}
						</div>
					</div>
				</div>
			</footer>
			<div id="gotoTop" className="icon-angle-up"></div>
		</>
	);
}
export default Footer;