
function Mission() {
	return (
		<>
			<div className="container-fluid missionParagraph" data-bottom-top="background-position:0px 300px" data-top-bottom="background-position:0px -300px">
				<div className="heading-block center nobottomborder nobottommargin">
					<h1 className="heading1">Sikh Aid Global Mission</h1>
					<h5 className="h5"> "Sikh Aid Global is a non-profit organization that was established in 2002.
						SAG is dedicated to addressing the need of Sikhs,
						with an emphasis of those in rural India by improving the quality of life,
						facilitating access to basic healthcare and increasing opportunities for education. "</h5>
				</div>
			</div>
		</>
	);
}
export default Mission;