import {React, useState, useEffect} from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer , Tooltip, Legend } from 'recharts';
import { DonationRequestService } from "../../services/DonationRequest.service";

// const data = [{ name: '0', Distributed: 0, Collected: 200 },
// { name: 'January', Distributed: 400, Collected: 200},
// { name: 'Feb', Distributed: 300, Collected: 2200},
// { name: 'March', Distributed: 600, Collected: 2300,},
// { name: 'April', Distributed: 200, Collected: 2500,},
// { name: 'May', Distributed: 600, Collected: 200,},
// { name: 'June', Distributed: 1200, Collected: 2400,},
// { name: 'July', Distributed: 500, Collected: 400},
// { name: 'August', Distributed: 500, Collected: 2400},
// { name: 'Sep', Distributed: 500, Collected: 2400},
// { name: 'Oct', Distributed: 300, Collected: 1500},
// { name: 'Nov', Distributed: 500, Collected: 2400},
// { name: 'Dec', Distributed: 1500, Collected: 1100}];

const donationRequestService = new DonationRequestService();

export const Graph = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    donationRequestService.getDonationRequestsLineChartData().then((response) => {
      if (response.data.length) {
        setData(response.data);
      }
    })
  }, [])
  return (
    <>
      <section id="" className="container mt-3 ">
        <div className='card pcard'>
          <p className="ml-2 mt-2" ><strong>Financial Performance</strong></p>
          <div className="row mt-2 pt-3 pb-3">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="requested" name="Requested" stroke="#8884d8" />
                  <Line type="monotone" dataKey="collected" name="Collected" stroke="#000000" />
                  <Line type="monotone" dataKey="disbursed" name="Disbursed" stroke="#2a2299" />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="date" />
                  <YAxis />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
