import { React, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import DOMPurify from 'dompurify';
import { Breadcrumb } from "../Shared/BreadCrumb";
import { EventService } from '../../services/Event.service';


export const EventDetailPage = () => {
  let { slug } = useParams();
  const eventService = new EventService();
  const [event, setEvent] = useState({
    name: "",
    summary: "",
    description: "",
    thumbnail: "",
    cover: "",
  })
  console.log(event);

  async function getData() {
    eventService.getEvents({
      slug: slug
    }).then((response) => {
      if (response.data.length > 0) {
        setEvent(response.data[0]);
      }
    })
  }

  useEffect(() => {
    getData();
  }, [])
  return (
    <>
      <Breadcrumb title="Event Details" links={[{ title: "Event Details" }]} />
      <section>
        <div className="content-wrap">
          <div className="container clearfix">
            <div className="single-event">
              <div className='row'>
                <div className="col-12">
                  <div className="entry-image shadow nobottommargin">
                    <img src={event.cover} alt={event.name} />
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <h3>{event.name}</h3>
                  <p>{event.summary}</p>
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.description)}}></div>
                </div>
                {/* <div className="col-12 nobottommargin ">
                  <h5>Events Timeline</h5>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Timings</th>
                          <th>Location</th>
                          <th>Events</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="badge badge-danger">10:00 - 12:00</span></td>
                          <td>Main Auditorium</td>
                          <td>WWDC Developer Conference</td>
                        </tr>
                        <tr>
                          <td><span className="badge badge-danger">12:00 - 12:45</span></td>
                          <td>Cafeteria</td>
                          <td>Lunch</td>
                        </tr>
                        <tr>
                          <td><span className="badge badge-danger">13:00 - 14:00</span></td>
                          <td>Audio-Visual Lab</td>
                          <td>Apple Engineers Brain-Storming &amp; Questionaire</td>
                        </tr>
                        <tr>
                          <td><span className="badge badge-danger">15:00 - 18:00</span></td>
                          <td>Room - 25, 2nd Floor</td>
                          <td>Hardware Testing &amp; Evaluation</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
