

import { React, useState, useEffect, useRef } from 'react'
import { Breadcrumb } from "../shared/Breadcrumb";
import { Link } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import AclService from "../../services/Acl.service";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { FundRequestService } from "../../services/FundRequest.service";
import { dateToDateString, compareMonths } from "../../utils/date.utils";

export const FundRequest = () => {
	const filterFormRef = useRef();
	const fundRequestService = new FundRequestService();
	const [fundRequests, setFundRequest] = useState([]);
	const [deleteId, setDeleteId] = useState(null);
	const [ApplicantID, setApplicantId] = useState(null);

	const deleteConfirmationResult = (isConfirmed) => {
		const id = deleteId;
		setDeleteId(null);
		const ApplicantId = ApplicantID;
		setApplicantId(null);
		if (!isConfirmed) {
			return;
		}

		fundRequestService.deleteFundRequest(ApplicantId, id).then((response) => {
			const i = fundRequests.findIndex(x => x._id === id);
			if (i >= 0) {
				const tempFundRequests = [...fundRequests];
				tempFundRequests.splice(i, 1);
				setFundRequest(tempFundRequests);
				toast.success("Fund Request deleted successfully")
			}
		})
			.catch(() => {
				toast.error("Fund Request could not deleted")
			})
	}

	const [offset, setOffset] = useState(0);
	const limit = 20;

	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const toggleFilter = () => setIsFilterOpened(wasOpened => !wasOpened);

	const approveRequest = (ApplicantId, id, index) => {
		console.log(ApplicantId, id, index);
		fundRequestService.approve(ApplicantId, id).then(() => {
			toast.success("Fund request approved successfully");

			const tempRecords = [...fundRequests];
			tempRecords[index].isApproved = true;
			setFundRequest(tempRecords);
		})
			.catch(() => {
				toast.error("Fund request could not be approved");
			})
	}

	const getData = () => {
		const filters = {
			monthly: 1,
			l: limit,
			o: offset
		};
		if (filterFormRef.current) {
			if (filterFormRef.current.values.s) filters.s = filterFormRef.current.values.s;
		}
		fundRequestService.getFundRequests(filters).then((response) => {
			if (offset) setFundRequest([...fundRequests, ...response.data]);
			else setFundRequest(response.data);
		})
	}

	const expiryStatus = (date) => {
		let status = "Expired";
		if (compareMonths(new Date(), new Date(date))) {
			status = "Valid";
		}
		return status;
	}

	useEffect(() => {
		getData();
	}, [offset])

	return (
		<>
			<Breadcrumb title="Monthly Fund Requests" links={[{ title: "Monthly Fund Requests" }]} />
			<ConfirmationModal show={deleteId !== null} onClose={deleteConfirmationResult} />

			<section id="content" className='container-xl p-2 mt-3 bottommargin-lg'>
				<div className="row mb-3">
					<div className='col-12 mb-2'>
						<button type="button" onClick={toggleFilter} className="btn btn-light btn-sm float-right  mr-1"><i className="fas fa-filter"> Filters (0)</i></button>
					</div>
					{isFilterOpened && (
						<div className="col-12">
							<div className="col-12">
								<Formik
									innerRef={filterFormRef}
									initialValues={{
										s: "",
									}}
									onSubmit={() => {
										if (offset) setOffset(0)
										else getData();
									}}
								>
									{({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
										<Form>
											<Card>
												<Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
													<div className="row align-items-center">
														<Field name="s" autoComplete="off" id="s" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Search.." />

														<div className="col-sm-2 pt-2">
															<button type="submit" className="btn btn-secondary btn-sm">Filter</button>
															<button type="button" className="btn btn-secondary btn-sm ml-1" onClick={() => {
																resetForm();
																setTimeout(() => {
																	if (offset) setOffset(0)
																	else getData();
																}, 200);
															}}>Reset</button>
														</div>
													</div>
												</Card.Body>
											</Card>
										</Form>
									)}
								</Formik>
							</div>
						</div>
					)}
				</div>

				<div className="table-responsive">
					<InfiniteScroll
						height={"72vh"}
						dataLength={fundRequests.length}
						next={() => setOffset(offset + limit)}
						hasMore={true}
					>
						<table className="table table-hover">
							<thead>
								<tr>
									<th>Name</th>
									<th>Category</th>
									<th>Required Fund</th>
									<th>Fund Last Date</th>
									<th>Status</th>
									<th>Approved</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{fundRequests.map((fundRequest, index) => {
									return <tr key={fundRequest._id}>
										<td>{fundRequest.applicant.fName} {fundRequest.applicant.lName}</td>
										<td>{fundRequest.category.name}</td>
										<td>{fundRequest.amount}</td>
										<td>{fundRequest.getFundingTill && dateToDateString(fundRequest.getFundingTill)}</td>
										<td>{expiryStatus(fundRequest.getFundingTill)}</td>
										<td>
											{fundRequest.isApproved && <span className="badge badge-secondary"><i className="fas fa-check"></i> Approved</span>}
											{!fundRequest.isApproved &&
												<span className="badge badge-light"><i class="fas fa-exclamation"></i> No</span>
												// <button type="button" className="btn btn-danger btn-sm" onClick={() => approveRequest(fundRequest.applicant._id, fundRequest._id, index)}>Approve</button>
											}
										</td>
										<td>
											<Dropdown>
												<Dropdown.Toggle variant="light" id="dropdown-basic">
													<i className="fas fa-cog"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
												{
														AclService.checkPermission("MonthlyRequestViewDetail") &&
													<Dropdown.Item as={Link} to={"/portal/fund-request/" + fundRequest._id}>View Detail</Dropdown.Item>
												}
													{(!fundRequest.isApproved || fundRequest.isMonthly) && AclService.checkPermission("MonthlyUpdate") &&
														<Dropdown.Item as={Link} to={"/portal/fund-request/form/" + fundRequest.applicant._id + "/" + fundRequest._id}>Edit</Dropdown.Item>
													}
													{/* {fundRequest.isApproved &&  AclService.checkPermission("MonthlyRequestCollectionDetails") &&
														<Dropdown.Item href="#/action-3">Collection Detail</Dropdown.Item>
													} */}
														{
														AclService.checkPermission("MonthlyRequestDelete") &&
													<Dropdown.Item eventKey={"1"} onClick={() => setApplicantId(fundRequest.applicant._id) + setDeleteId(fundRequest._id)}>Delete</Dropdown.Item>}
												</Dropdown.Menu>
											</Dropdown>
										</td>
									</tr>
								}
								)}
							</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</section>
		</>
	)
}
