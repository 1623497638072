import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class GeoDbService extends HttpService {
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/geo-db";
  } 
  getAllCountries(conditions = {}) {
    return this.get(this.apiUrl + "/country" + this.objToQuery(conditions));
  }

  getAllStates(conditions = {}) {
    return this.get(this.apiUrl + "/states" + this.objToQuery(conditions));
  }

  getAllCities(conditions = {}) {
    return this.get(this.apiUrl + "/city" + this.objToQuery(conditions));
  }
}