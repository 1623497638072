import React from 'react';
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import { Breadcrumb } from "../shared/Breadcrumb";
import { UserService } from '../../services/User.service';
import * as Yup from "yup"

const userService = new UserService();

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must contain at least 8 characters")
    .max(100, "Password cannot exceed 100 characters")
    .required("Password is required"),
    
  newPassword: Yup.string()
    .min(8, "New Password must contain at least 8 characters")
    .max(100, "New Password cannot exceed 100 characters")
    .required("New Password is required"),

  passwordConfirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref('newPassword'), null], 'Confirm Password must match with new Password'),
});

export const ChangePassword = () => {

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    userService.changePasswords(values)
      .then((response) => {
        toast.success("Password changed successfully");
        resetForm();
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data &&  error.response.data.message) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Breadcrumb title="Change Password" links={[{ title: "Change Password" }]} />
      <div className="d-flex justify-content-center">
        <div className="mt-3 mb-3 p-2 col-sm-7 col-lg-6">
          <h3 className="center">Change Password</h3>
          <Formik
            initialValues={{
              password: "",
              newPassword: "",
              passwordConfirmation:"",
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={formSubmitted}>
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor=" password">Current Password</label>
                  <Field type='password' id="password" name="password" placeholder="Current Password" className="sm-form-control" />
                  {errors.password && touched.password ? (<div className="error">{errors.password}</div>) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="password">New Password</label>
                  <Field type='password' id="password" name="newPassword" placeholder="New Password" className="sm-form-control" />
                  {errors.newPassword && touched.newPassword ? (<div className="error">{errors.newPassword}</div>) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Confirm Password</label>
                  <Field type='password' id="password" name="passwordConfirmation" placeholder="Confirm Password" className="sm-form-control" />
                  {errors.passwordConfirmation && touched.passwordConfirmation ? (<div className="error">{errors.passwordConfirmation}</div>) : null}
                </div>

                <button type="submit" disabled={isSubmitting} className="btn-block d-flex justify-content-center align-items-center btn btn-secondary mt-3">
                {isSubmitting && <i className="fas fa-spinner fa-pulse mr-1"></i>} Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
