import { React, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap"

export const ConfirmationModal = (props) => {
  const { show = false, title = "Are you sure you want to delete this?" } = props;
  
  const cancel = () => {
    props.onClose(false);
  }

  const confirm = () => {
    props.onClose(true);
  }

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          {title}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={cancel}>
            Cancel
          </Button>
          <Button variant="light" size="sm" onClick={confirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}