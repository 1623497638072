import { React, useState, useEffect } from 'react';
import { AccessRequestService } from "../../services/AccessRequest.service";
import { Breadcrumb } from '../shared/Breadcrumb';
import { toast } from "react-toastify";
import { dateToDateString } from "../../utils/date.utils";
import { Link } from 'react-router-dom';

export const AccessRequest = () => {
  const accessRequestService = new AccessRequestService();
  const [accessRequests, setAccessRequest] = useState([]);
  const changeStatusRequest = (RecordId, index, status) => {
    const data = {
      status: status
    };

    accessRequestService.approve(RecordId, data).then(() => {
      toast.success("Status updated successfully");
      const tempRecords = [...accessRequests];
      tempRecords[index].status = data.status;
      setAccessRequest(tempRecords);
    })
      .catch(() => {
        toast.error("Status could not be updated");
      })
  }

  const getData = () => {
    // const filters = {
    // 	l: limit,
    // 	o: offset
    // };
    // if (filterFormRef.current) {
    // 	if (filterFormRef.current.values.s) filters.s = filterFormRef.current.values.s;
    // 	if (filterFormRef.current.values.city) filters.city = filterFormRef.current.values.city;
    // 	if (filterFormRef.current.values.category) filters.category = filterFormRef.current.values.category;
    // 	if (filterFormRef.current.values.institute) filters.institute = filterFormRef.current.values.institute;
    // }

    accessRequestService.getData().then((response) => {
      // if (offset) setApplicants([...applicants, ...response.data]);
      setAccessRequest(response.data);
    })
  }
  useEffect(() => {
    getData();
  }, [])
  return (
    <>
      <Breadcrumb title="Access Request" links={[{ title: "Access Request" }]} />
      <section id="content" className='container-xl p-2 mt-3 bottommargin-lg'>
        <div className="table-responsive">
          {/* <InfiniteScroll
            height={"72vh"}
            dataLength={fundRequests.length}
            next={() => setOffset(offset + limit)}
            hasMore={true}
          > */}
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Access Valid Till</th>
               
                <th>Status</th>
                <th align="center" className="center">Action</th>
              </tr>
            </thead>
            <tbody>
              {accessRequests.map((accessRequest, index) => {
                return <tr key={accessRequest._id}>
                  <td>{accessRequest.user.fName} {accessRequest.user.lName}</td>
                  {/* <td>{fundRequest.category.name}</td>
                    <td>{fundRequest.amount}</td>*/}
                  <td>{accessRequest.validTill && dateToDateString(accessRequest.validTill)}</td>
                  <td>{accessRequest.status}</td>
                  {/* <td>
                      {fundRequest.isMonthly && <span className="badge badge-primary"><i className="fas fa-redo"></i> Yes</span>}
                      {!fundRequest.isMonthly && <span className="badge badge-secondary"><i className="far fa-money-bill-alt"></i> No</span>}
                    </td> */}
                  <td className="d-flex justify-content-center">
                    {((accessRequest.status === "Declined") || (accessRequest.status === "Pending")) &&
                      <button type="button" className="btn btn-secondary btn-sm" onClick={() => changeStatusRequest(accessRequest._id, index, "Approved")}>Approve</button>
                    }
                    {(accessRequest.status === "Approved") &&
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => changeStatusRequest(accessRequest._id, index, "Declined")}>Decline</button>
                    }
                   
                      <Link to={"/portal/donation-request/" + accessRequest.RecordId}>
                        <button type="button" className="btn btn-light btn-sm float-right">View Applicant</button>
                      </Link>
    
                  </td>
                </tr>
              }
              )}
            </tbody>
          </table>
          {/* </InfiniteScroll> */}
        </div>
      </section>
    </>
  );
}
