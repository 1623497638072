import { React, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Card, Dropdown, Button, Modal } from "react-bootstrap";
import { DonationsService } from "../../services/Donations.service";
import { Formik, Field, Form } from "formik";
import { toast } from 'react-toastify';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import AsyncSelect from 'react-select/async';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Breadcrumb } from "../shared/Breadcrumb";
import { dateToDateString, compareDates } from "../../utils/date.utils";
import { ProjectService } from "../../services/Project.service";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { AccessRequestService } from "../../services/AccessRequest.service";
import { InstituteService } from "../../services/Institute.service";
import { DonationRequestService } from '../../services/DonationRequest.service';
import AclService from "../../services/Acl.service";
import SessionService from "../../services/Session.service";

const accessRequestService = new AccessRequestService();
const donationService = new DonationsService();


export const DonationRequest = () => {
  const user = SessionService.getLoggedIn();
  const [categories, setCategories] = useState([]);
  const filterFormRef = useRef();
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const toggleFilter = () => setIsFilterOpened(wasOpened => !wasOpened);
  const donationRequestService = new DonationRequestService();
  const [donationRequests, setDonationRequests] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [offset, setOffset] = useState(0);
  const projectService = new ProjectService();
  const instituteService = new InstituteService();
  const limit = 20;
  const [showModal, setModal] = useState(false);
  const [showPaypalButton, setPaypalButton] = useState(true);
  const [donationRequestBeingDonated, setDonationRequestBeingDonated] = useState({});
  const history = useHistory();

  const hideButton = () => {
    setPaypalButton(false);
  };

  const handleClose = () => {
    setModal(false);
    setDonationRequestBeingDonated({});
  };
  const handleShow = (donationRequest) => {
    history.push("/donation/" + donationRequest._id);
    setDonationRequestBeingDonated(donationRequest);
  };

  const initialOptions = {
    "client-id": "AYtkXw-Dlnj03tJ94k304uD3D1C2jE0tOEW0n2BCLdPpDHw-SUzjjG0sLTJx8EJtmZQ7b5EhRm24ZS4g",
    currency: "GBP",
  };

  const createOrder = (data, actions) => {
    if (!donationRequestBeingDonated || !donationRequestBeingDonated.requested) {
      return;
    }
    const requiredFund = parseFloat(donationRequestBeingDonated.requested) - parseFloat(donationRequestBeingDonated.collected);
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: requiredFund.toString(),
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {

    return actions.order.capture().then((details) => {
      const data = {
        reference: details.id,
        amount: parseFloat(details.purchase_units[0].amount.value),
        DonationRequestId: donationRequestBeingDonated._id,
        source: 'Paypal'
      };
      if (user || user.id) {
        data.DonorId = user.id;
      };
      donationService.paypalPayment(data).then((response) => {

      }).catch((error) => {

      })
      hideButton();
      // const name = details.payer.name.given_name;
      // alert(`Transaction completed by ${name}`);
    });
  };


  const deleteConfirmationResult = (isConfirmed) => {
    const id = deleteId;
    setDeleteId(null);

    if (!isConfirmed) {
      return;
    }

    donationRequestService.deleteDonationRequest(id).then((response) => {
      const i = donationRequests.findIndex(x => x._id === id);
      if (i >= 0) {
        const tempDonationRequest = [...donationRequests];
        tempDonationRequest.splice(i, 1);
        setDonationRequests(tempDonationRequest);
        toast.success('Donation request delete successfully')
      }
    })
      .catch(() => {
        toast.error("Donation request could not deleted")
      })
  }

  const getInstitutes = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions['s'] = search;
    }

    const response = await instituteService.getInstitutes(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.name
      };
    });
  }

  const getProjects = (search) => {
    const conditions = { l: 100 };
    if (search) {
      conditions['s'] = search;
    }

    projectService.getAllProjects(conditions).then((response) => {
      setCategories(response.data);
    })
  }

  const requestAccess = (id, index) => {
    const user = SessionService.getLoggedIn();
    if (!user || user.role !== "donor") {
      toast.warning("You need to be logged in as a donor for profile access");
      return;
    }
    accessRequestService.create({ RecordId: id, module: "DonationRequest" })
      .then((response) => {
        const tempRecords = [...donationRequests];
        tempRecords[index].AccessStatus = "Pending";
        setDonationRequests(tempRecords);
        toast.success("Request Sended to admin");
      })
      .catch((error) => {
        toast.warning("Request access could not be generated");
      })
  }

  useEffect(() => {
    getProjects();
  }, [])

  const getData = () => {
    const filters = {
      l: limit,
      o: offset
    };
    if (filterFormRef.current) {
      if (filterFormRef.current.values.status) filters.status = filterFormRef.current.values.status;
      if (filterFormRef.current.values.CategoryId) filters.CategoryId = filterFormRef.current.values.CategoryId;
      if (filterFormRef.current.values.InstitutionId) filters.InstitutionId = filterFormRef.current.values.InstitutionId;
      if (filterFormRef.current.values.uid) filters.uid = filterFormRef.current.values.uid;
      if (filterFormRef.current.values.applicantName) filters.applicantName = filterFormRef.current.values.applicantName;
    }
    donationRequestService.getDonationRequests(filters).then((response) => {
      console.log(filters, "<<<<<<<");
      if (offset) setDonationRequests([...donationRequests, ...response.data]);
      else setDonationRequests(response.data);

    })
  }


  useEffect(() => {
    getData();
  }, [offset])
  return (
    <>
      <Breadcrumb title="Donation Requests" links={[{ title: "Donation Requests" }]} />
      <ConfirmationModal show={deleteId !== null} onClose={deleteConfirmationResult} />

      <section id="content" className='container-xl p-2 mt-3 bottommargin-lg'>
        <div className="row mb-3">
          <div className='col-12 mb-2'>
            {
              AclService.checkPermission("DonationRequestCreate") &&
              <Link to="/portal/donation-request/form">
                <button type="button" className="btn btn-light btn-sm float-right"><i className="fas fa-plus"></i></button>
              </Link>}
            <button type="button" onClick={toggleFilter} className="btn btn-light btn-sm float-right  mr-1"><i className="fas fa-filter"> Filters (0)</i></button>
          </div>
          {isFilterOpened && (
            <div className="col-12">
              <Formik
                innerRef={filterFormRef}
                initialValues={{
                  status: "",
                  CategoryId: "",
                  InstitutionId: "",
                  uid: "",
                  applicantName: "",
                }}
                onSubmit={(values) => {
                  console.log("Form Values:", values);
                  if (offset) setOffset(0)
                  else getData();
                }}
              >
                {({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
                  <Form>
                    <Card>
                      <Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
                        <div className="row align-items-center">
                          <Field name="uid" autoComplete="off" id="s" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Search.." />
                          <Field name="applicantName" autoComplete="off" id="ap" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Applicant`s Name" />
                          <Field as="select" name="CategoryId" className="form-control col-sm-3 sm ml-1 mt-1">
                            <option value="">Select Category</option>
                            {categories.map(category =>
                              <option key={category._id} value={category._id} >{category.name}</option>
                            )};
                          </Field>
                          <AsyncSelect name="InstitutionId" className="col-sm-3 sm ml-1 p-0 mt-1" cacheOptions defaultOptions loadOptions={getInstitutes} onChange={(val) => setFieldValue("InstitutionId", val.value)} />
                          <Field as="select" name="status" className="form-control col-sm-3 sm ml-1 p-0 mt-1">
                            <option value="">Select Status</option>
                            <option value="Active" >Active</option>
                            <option value="Canceled" >Canceled</option>
                            <option value="Completed" >Completed</option>
                          </Field>
                          <div className="col-sm-2 pt-2">
                            <button type="submit" className="btn btn-secondary btn-sm">Filter</button>
                            <button type="button" className="btn btn-secondary btn-sm ml-1" onClick={() => {
                              resetForm();
                              setTimeout(() => {
                                if (offset) setOffset(0)
                                else getData();
                              }, 200);
                            }}>Reset</button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Form>
                )}
              </Formik>

            </div>
          )}
          <div className='col-12'>
            <p className="mb-0" style={{ lineHeight: "1" }}>
              <small>
                <i className="fas fa-circle mr-1" style={{ color: "green" }}></i> Listed on public app for donations collection<br />
                <i className="fas fa-circle mr-1"></i> Not Listed on public app. The fund may be collected, last date may be passed, status may be canceled/completed or the required fund may be disbursed.
              </small>
            </p>
          </div>
        </div>
        <div className="table-responsive">
          <InfiniteScroll height={"72vh"} dataLength={donationRequests.length} next={() => setOffset(offset + limit)} hasMore={true}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Applicant Name</th>
                  <th>Category</th>
                  <th>Required</th>
                  <th>Collected</th>
                  <th>Disbursed</th>
                  <th>Last Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{donationRequests.map((donationRequest, index) => {
                return <tr key={donationRequest._id} style={{ backgroundColor: donationRequest.status === "Completed" ? "yellow" : "inherit" }}>
                  <td>
                    {donationRequest.status === "Active" && compareDates(donationRequest.validTill, new Date()) &&
                      donationRequest.requested > donationRequest.collected && donationRequest.requested > donationRequest.disbursed &&
                      <i className="fas fa-circle mr-1" style={{ color: "green" }}></i>}
                    {(donationRequest.status !== "Active" || !compareDates(donationRequest.validTill, new Date()) ||
                      donationRequest.requested <= donationRequest.collected || donationRequest.requested <= donationRequest.disbursed) &&
                      <i className="fas fa-circle mr-1" title="Last date is passed, fund is collected or disbursed"></i>}
                    {donationRequest.uid}
                  </td>
                  {donationRequest.applicant && donationRequest.applicant.fName ? <td>{donationRequest.applicant.fName} {donationRequest.applicant.lName}</td> : <td>Applicant Deleted</td>}
                  {donationRequest.category && donationRequest.category.name ? <td>{donationRequest.category.name}</td> : <td>Category Deleted</td>}
                  <td>{donationRequest.requested}</td>
                  <td>{donationRequest.collected}</td>
                  <td>{donationRequest.disbursed}</td>
                  <td>{dateToDateString(donationRequest.validTill)}</td>
                  <td>{donationRequest.status}</td>
                  <td className="d-flex justify-content-end">
                    {donationRequest.status === "Active" && donationRequest.requested > donationRequest.disbursed && AclService.checkPermission("DonationRequestAddDonation") && <Link
                      to={"/portal/donation-request/" + donationRequest._id + "/add-disbursement"}
                    >
                      <button type="button" className="btn btn-secondary btn-sm">Disburse Funds</button>
                    </Link>}
                    {donationRequest.status === "Active" && donationRequest.requested > donationRequest.disbursed && user.role === "donor" &&
                      <Button className="ml-1" variant="secondary" onClick={() => {
                        handleShow(donationRequest);
                      }} >
                        Donate Now
                      </Button>}
                    <Dropdown className="ml-1">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <i className="fas fa-cog"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {!donationRequest.AccessStatus &&
                          // <button type="button" className="btn btn-secondary btn-sm" onClick={() => requestAccess(donationRequest._id, index)}>Request Access</button>
                          <Dropdown.Item eventKey={"0"} onClick={() => requestAccess(donationRequest._id, index)}>Request Access</Dropdown.Item>
                        }
                        {
                          (AclService.checkPermission("DonationRequestUpdate") || (donationRequest.AccessStatus && donationRequest.AccessStatus == "Approved")) &&
                          <Dropdown.Item eventKey={"1"} as={Link} to={"/portal/donation-request/" + donationRequest._id}>Detail</Dropdown.Item>
                        }
                        {
                          AclService.checkPermission("DonationRequestUpdate") &&
                          <Dropdown.Item eventKey={"2"} as={Link} to={"/portal/donation-request/form/" + donationRequest._id}>Edit</Dropdown.Item>
                        }
                        {donationRequest.status === "Active" && donationRequest.requested > donationRequest.disbursed && donationRequest.requested > donationRequest.collected && AclService.checkPermission("DonationRequestAddDonation") &&
                          <Dropdown.Item eventKey={"3"} as={Link} to={"/portal/donation-request/" + donationRequest._id + "/add-donation"}>Add Donation</Dropdown.Item>
                        }
                        {donationRequest.disbursed === 0 && AclService.checkPermission("DonationRequestDelete") &&
                          <Dropdown.Item eventKey={"4"} onClick={() => setDeleteId(donationRequest._id)}>Delete</Dropdown.Item>}
                      </Dropdown.Menu >
                    </Dropdown >
                  </td >
                </tr >
              }
              )}
              </tbody >
            </table >
          </InfiniteScroll >
        </div>
      </section>
      <Modal show={showModal} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Method</Modal.Title>
        </Modal.Header>
        {showPaypalButton ?
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              createOrder={(data, actions) => createOrder(data, actions)}
              onApprove={(data, actions) => onApprove(data, actions)}
            />
          </PayPalScriptProvider> : <> <i style={{ color: "green" }} className='fa fa-check fa-3x mt-2 text-center'></i> <h5 className='mt-2 text-center'>Transaction Successfully Completed</h5></>}
      </Modal>
    </>
  );
};
