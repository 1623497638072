import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { AuthService } from "../../../services/Auth.service";

const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) => {
  return (
    <label htmlFor={id} className={`label btn btn-outline-secondary px-3 t600 ls0 nott ${id === value ? 'active' : ''  }`}>
      <input id={id} value={id} checked={id === value} name={name} type="radio" onChange={onChange} onBlur={onBlur} {...props} />{" "}
      {label}
    </label>
  );
};

const SignUpSchema = Yup.object().shape({
  fName: Yup.string()
    .min(2, "First name must contain at least 2 letters")
    .max(50, "First name cannot exceed 50 letters")
    .required("First name is required"),

  lName: Yup.string()
    .min(2, "Last name must contain at least 2 letters")
    .max(50, "Last name cannot exceed 50 letters")
    .required("Last name is required"),

  password: Yup.string()
    .min(8, "Password must contain at least 8 characters")
    .max(100, "Password cannot exceed 100 characters")
    .required("Password is required"),

  email: Yup.string()
    .email("Please enter a valid email")
    .min(5, "Email must contain at least 5 characters")
    .max(255, "Email cannot exceed 255 characters")
    .required("Email is required"),

  role: Yup.string().required("Please Select donor or institute"),
});

export const SignUpForm = (props) => {
  const authService = new AuthService();

  const roleChanged = (e) => {
    if (e.target.name === "role") {
      props.onRoleChange(e.target.value)
    }
  };

  const initialValues = {
    fName: "",
    lName: "",
    email: "",
    password: "",
    role: props.role
  };

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    authService.signUp(values)
      .then((response) => {
        toast("Account registered successfully!");
        props.onRegistration(true);
        resetForm();
      })
      .catch((error) => {
        toast.error("Email already exist!");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <>
    <ToastContainer />

    <h3 className="d-md-none">Sign up</h3>
    <Formik initialValues={initialValues} validationSchema={SignUpSchema} onSubmit={formSubmitted}>

      {({ errors, touched, isSubmitting }) => (
        <Form className="nobottommargin" autoComplete="off" onChange={roleChanged}>
          <div className="row">
            <div className="col-sm-6">
              <label className="label" htmlFor="fName">First Name</label>
              <Field autoComplete="off" id="fName" name="fName" className="sm-form-control" />
              {errors.fName && touched.fName ? (<div className="error">{errors.fName}</div>) : null}
            </div>
          
            <div className="col-sm-6">
              <label className="label" htmlFor="lName">Last Name</label>
              <Field autoComplete="off" id="lName" name="lName" className="sm-form-control" />
              {errors.lName && touched.lName ? (<div className="error">{errors.lName}</div>) : null}
            </div>

            <div className="form-group col-12">
              <label className="label" htmlFor="email">Email</label>
              <Field name="email" id="email" className="sm-form-control" />
              {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}
            </div>

            {!props.hideRole && props.role && (
              <div className="col-12 form-group mb-4">
                <label htmlFor="role" className="mb-3 label">
                  I am:
                </label>
                <br />
                <div className="btn-group btn-group-toggle flex-wrap" data-toggle="buttons">
                  <Field component={RadioButton} name="role" id="donor" label="Donor" />
                  <Field component={RadioButton} name="role" id="institute" label="Institute" />
                </div>
                {errors.role && touched.role ? (<div className="error">{errors.role}</div>) : null}
              </div>
            )}

            <div className="form-group col-12">
              <label className="label" htmlFor="password">Password</label>
              <Field type="password" id="password" name="password" className="sm-form-control" />
              {errors.password && touched.password ? (<div className="error">{errors.password}</div>) : null}
            </div>

            <div className="col-12 mt-2 mb-2 create">
              <Link to="/login">I already have an account</Link>
            </div>

            <div className="col-12">
              <button type="submit" name="website-cost-submit" className="btn btn-secondary" disabled={isSubmitting}>
              {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Sign up
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  </>;
};
