import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class DonationsService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/payment";
  }

  create(data) {
    return this.post(this.apiUrl, data);
  }

  paypalPayment(data) {
    return this.post(this.apiUrl + "/" + "paypal", data);
  }

  getDonations(conditions = {}) {
    console.log("Conditions:", conditions);
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }
  getDonation(id) {
    return this.get(this.apiUrl + "/" + id);
  }
  deleteDonation(id) {
    return this.delete(this.apiUrl + "/" + id);
  }
  update(id, data) {
    return this.patch(this.apiUrl + "/" + id, data);
  }
}
