import { Breadcrumb } from "../shared/Breadcrumb";
import { ApplicantPrimaryInfoView } from "./Components/ApplicantPrimaryInfoView";
import { useParams } from 'react-router-dom';

export const ApplicantDetail = () => {
  const { ApplicantId } = useParams();
  
  return <>
    <Breadcrumb title="Applicant Profile" links={[{ title: "Applicants", to: "/portal/applicant" }, { title: "Applicant Detail" }]} />

    <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
      <ApplicantPrimaryInfoView ApplicantId={ApplicantId} infoLevel="full" />
    </section>
  </>
}