import SessionService from "./Session.service";

export class AclService {
  constructor(sessionService) {
    this.sessionService = sessionService;
  }

  getPermissions() {
    const user = this.sessionService.getLoggedIn();
    if (!user || !user.permissions || !user.permissions.length) {
      return [];
    }

    return user.permissions;
  }

  checkPermission(permission) {
    const permissions = this.getPermissions();
    if (!permissions.some(x => x === permission)) return false;

    return true;
  }

  checkPermissions(permissionsToCheck) {
    const permissions = this.getPermissions();
    if (!permissionsToCheck.some(x => permissions.includes(x))) return false;

    return true;
  }
}

export default new AclService(SessionService);