import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Breadcrumb } from "../shared/Breadcrumb";
import { removeQueryString } from "../../utils/helping.utils";
import { ImageCropperNew } from "../shared/ImageCropperNew";

import { ProjectService } from "../../services/Project.service";
import { InstituteService } from "../../services/Institute.service";
import { GeoDbService } from "../../services/GeoDb.service";
import { UserService } from "../../services/User.service";
import { UploadService } from "../../services/Upload.service";
import Session from "../../services/Session.service";

const InstitutionSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name must contain at least 3 letter!")
    .max(50, "Name cannot exceed 50 letter!")
    .required("Name is required"),

  address: Yup.string()
    .max(50, "Name cannot exceed 50 letter!!")
    .required("Address is required"),

  about: Yup.string()
    .max(1500, "About cannot exceed 1500 letters!")
    .required("About is required"),

  phone: Yup.string(),

  thumbnail: Yup.string()
    .required("Thumbnail is required"),

  CityId: Yup.string()
    .required('City is required!'),

  CategoryId: Yup.string()
    .required("Category is required"),

  AdminId: Yup.string()
    .required("AdminId is required"),
});


const instituteService = new InstituteService();
const geoDbService = new GeoDbService();
const projectService = new ProjectService();
const userService = new UserService();
const uploadService = new UploadService();

export const InstitutionForm = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const formRef = useRef();
  const user = Session.getLoggedIn();

  const [categories, setCategories] = useState([]);
  const [defaultCities, setDefaultCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [uploadingThumbnail, setUploadingThumbnail] = useState(false);

  const thumbnailChanged = (data) => {
    const formRefCurrent = formRef.current;
    if (!data || !data.blob || !data.file || !formRefCurrent) {
      return;
    };

    setUploadingThumbnail(true);
    uploadService.uploadBlob(data.blob, data.file, "public-read").then((response) => {
      formRefCurrent.setFieldValue("thumbnail", removeQueryString(response.location));
      setUploadingThumbnail(false);
    })
      .catch((error) => {
        toast.error("Image could not be uploaded")
        setUploadingThumbnail(false);
      })
  }

  const removeThumbnail = () => {
    const formRefCurrent = formRef.current;
    if (formRefCurrent) formRefCurrent.setFieldValue("thumbnail", "");
  }

  const getCities = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions['s'] = search;
    }
    const response = await geoDbService.getAllCities(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.name
      };
    });
  }

  const getUsers = async (search = "") => {
    const conditions = { l: 20, role: "institute" };
    if (search !== "") {
      conditions['s'] = search;
    }
    const response = await userService.getAllUser(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.fName + " " + x.lName
      };
    });
  }

  const getProjects = (search) => {
    const conditions = { l: 100 };
    if (search) {
      conditions['s'] = search;
    }

    projectService.getAllProjects(conditions).then((response) => {
      setCategories(response.data);
    })
  }


  useEffect(() => {
    getProjects();

    if (id) {
      instituteService.getInstitute(id).then((response) => {
        console.log(response);
        const formRefCurrent = formRef.current;
        if (response.data && formRefCurrent) {
          formRefCurrent.setFieldValue("name", response.data.name);
          formRefCurrent.setFieldValue("address", response.data.address);
          formRefCurrent.setFieldValue("about", response.data.about);
          formRefCurrent.setFieldValue("phone", response.data.phone || "");
          formRefCurrent.setFieldValue("CityId", response.data.city._id);
          formRefCurrent.setFieldValue("CategoryId", response.data.category._id);
          formRefCurrent.setFieldValue("AdminId", response.data.admin._id);
          formRefCurrent.setFieldValue("thumbnail", removeQueryString(response.data.thumbnail));
          setSelectedCity(response.data.city.name);
          setDefaultCities([{ value: response.data.city._id, label: response.data.city.name }]);

          setSelectedUser({ value: response.data.admin._id, label: response.data.admin.fName + " " + response.data.admin.lName });
          setDefaultUsers([{ value: response.data.admin._id, label: response.data.admin.fName + " " + response.data.admin.lName }]);
        }
      })
    } else {
      getCities("a").then((response) => {
        setDefaultCities(response);
      })
      getUsers().then((response) => {
        setDefaultUsers(response);
      })
    }
  }, [])


  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    if (!values.thumbnail) {
      toast.error("Please upload an image");
      setSubmitting(false);
      return;
    }

    const data = {
      name: values.name,
      address: values.address,
      CityId: values.CityId,
      thumbnail: values.thumbnail,
      CategoryId: values.CategoryId,
      AdminId: values.AdminId,
      about: values.about,
    }

    if (values.phone) {
      data[`phone`] = values.phone;
    }

    if (id) {
      instituteService.update(id, data)
        .then((response) => {
          toast.success("Institution information updated successfully");
          resetForm();
          history.push("/portal/institute")
        })
        .catch((error) => {
          toast.error("Institution information could not update")
        })
        .finally(() => {
          setSubmitting(false);
        })
      return;
    }

    instituteService.create(values)
      .then((response) => {
        toast.success("Institution Created successfully");
        resetForm();
        history.push("/portal/institute")
      })
      .catch((error) => {
        // toast.error("Institution Could not be created");
        console.log(error);
        toast.error(error.message)
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Breadcrumb title="Institution Form" links={[{ title: "Institutions" }, { title: "Institution Form" }]} />

      <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
        <Formik
          innerRef={formRef}
          initialValues={{
            name: "",
            address: "",
            phone: "",
            CityId: "",
            thumbnail: "",
            CategoryId: "",
            AdminId: user && user.role === "institute" ? user.id : ""
          }}
          validationSchema={InstitutionSchema}
          onSubmit={formSubmitted}
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => {
            return (
              <Form className="nobottommargin ">
                <div className="row m-0">
                  <div className="col-md-6 form-group">
                    <label className="label" htmlFor="Name">
                      Name
                    </label>
                    <Field name="name" id="Name" className="form-control" placeholder="Enter your name" />
                    {errors.name && touched.name ? (
                      <div className="error">{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 form-group">
                    <label className="label" htmlFor="address">
                      Address
                    </label>
                    <Field name="address" id="address" className="form-control" placeholder="Enter your address" />
                    {errors.address && touched.address ? (
                      <div className="error">{errors.address}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 form-group">
                    <label className="label" htmlFor="phone">
                      Phone number
                    </label>
                    <Field name="phone" id="phone" className="form-control" placeholder="+91**********"
                    />
                    {errors.phone && touched.phone ? (
                      <div className="error">{errors.phone}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 form-group">
                    <label className="label">City</label>
                    <AsyncSelect name="CityId" cacheOptions defaultOptions={defaultCities} defaultValue={"CityName"} loadOptions={getCities} onChange={(val) => setFieldValue("CityId", val.value)} />
                    {errors.CityId && touched.CityId ? (
                      <div className="error">{errors.CityId}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 form-group">
                    <label className="label">Category</label>
                    <Field as="select" name="CategoryId" className="form-control">
                      <option value="">Select Category</option>
                      {categories.map(category =>
                        <option key={category._id} value={category._id} >{category.name}</option>
                      )};
                    </Field>
                    {errors.CategoryId && touched.CategoryId ? (
                      <div className="error">{errors.CategoryId}</div>
                    ) : null}
                  </div>

                  {user.role !== "institute" &&
                    <div className="col-md-6 form-group">
                      <label className="label">Admin</label>
                      <AsyncSelect name="AdminId" cacheOptions defaultOptions={defaultUsers} defaultValue={selectedUser} loadOptions={getUsers} onChange={(val) => setFieldValue("AdminId", val.value)} />
                      {errors.AdminId && touched.AdminId ? (
                        <div className="error">{errors.AdminId}</div>
                      ) : null}
                    </div>
                  }

                  <div className="col-12 form-group">
                    <label className="label" htmlFor="about">
                      Summary
                    </label>
                    <Field as="textarea" name="about" id="about" className="form-control" placeholder="Write about institute.." />
                    {errors.about && touched.about ? (
                      <div className="error">{errors.about}</div>
                    ) : null}
                  </div>

                  <div className="col-sm-6 form-group">
                    <label className="label">
                      Institute Image/Logo
                    </label>
                    {!uploadingThumbnail && errors.thumbnail && touched.thumbnail ? (
                      <div className="error mt-0">{errors.thumbnail}</div>
                    ) : null}
                    {uploadingThumbnail && <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>}
                    {!uploadingThumbnail && <>
                      {values.thumbnail !== "" && values.thumbnail !== undefined && <div className="imageUpload">
                        <img src={values.thumbnail} alt="Thumbnail" />
                        <button className="btn btn-light pl-4 pr-4" onClick={removeThumbnail}><i className="fa fa-trash"></i></button>
                      </div>}
                      {!values.thumbnail &&
                        <ImageCropperNew btnLabel="Upload Image" inputName="thumbnailFile" onCropComplete={thumbnailChanged} aspect={17 / 10} />
                      }
                    </>}
                  </div>

                </div>
                <div className="col-12 mt-2">
                  <button type="submit" disabled={isSubmitting} className="btn btn-secondary">
                    {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
                  </button>
                  <Link to="/portal/institute">
                    <button type="button" name="" className="btn btn-warn  ">
                      Cancel
                    </button>
                  </Link>
                </div>
              </Form>)
          }
          }
        </Formik>
      </section>
    </>
  );
};
