export const booleanToString = (bool) => {
  if (bool) {
    return "Yes";
  }
  return "No";
}
export const approved = (bool) => {
  if (bool) {
    return "Approved";
  }
  return "Unapproved";
}


export const RandomId = (deepness = 10) => parseInt(Date.now() + Math.random() * deepness)

export const removeQueryString = (url) => {
  return url.split("?")[0];
}