function WhyEducation() {
	return (
		<section>
			<div className="Emp container-fluid  pt-4 mb-2">
				<div className="container">
					<div className=" d-flex justify-content-right  nobottomborder bottommargin-md">
						<h3>Why Education?</h3>
					</div>
					<div className="row mb-4">
						<div className=" col-sm-12 col-md-6 col-lg-4 nobottommargin mt-3">
							<h4>Problem</h4>
							<p className="ppri mt-3 mb-3">Sikligar community of Sikhs is among the most underprivileged communities in India. Their children continuously face numerous challenges to getting an education. Some of which include the lack of schools and their lower standards</p>
						</div>
						<div className=" col-sm-12 col-md-6 col-lg-4 nobottommargin mt-3">
							<h4>Nation Building</h4>
							<p className="ppri mt-3 mb-3">Education won't just let people improve their life. It'll also have a huge impact on the economic growth of India. These educated children can generate revenues by working in different fields. Education will also help develop a sense of nationalism among communities and make people realize they are working for one identity – India.</p>
						</div>
						<div className=" col-sm-12 col-md-6 col-lg-4 nobottommargin mt-3">
							<div className=" nobottomborder">
								<h4>Our Mission</h4>
							</div>
							<p className="ppri mt-3 mb-3">We want to offer high-quality education to these communities. So, their children can independently make their life when they grow up. Therefore, we will pay the fee for the unprivileged children and provide standard equipment and other necessary things to the deprived schools in those areas.
							</p>
						</div>
					</div>
				</div>
				<div className="clear"></div>
			</div>
		</section>
	);
}
export default WhyEducation;