import { React, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import AsyncSelect from 'react-select/async';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { Breadcrumb } from "../shared/Breadcrumb";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { ProjectService } from "../../services/Project.service";
import { ApplicantService } from "../../services/Applicant.service";
import { GeoDbService } from "../../services/GeoDb.service";
import { InstituteService } from "../../services/Institute.service";
import AclService from "../../services/Acl.service";

export const Applicant = () => {
	const filterFormRef = useRef();
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [applicants, setApplicants] = useState([]);
	const [categories, setCategories] = useState([]);
	const [offset, setOffset] = useState(0);
	const [deleteId, setDeleteId] = useState(null);
	const applicantService = new ApplicantService();
	const projectService = new ProjectService();
	const geoDbService = new GeoDbService();
	const instituteService = new InstituteService();
	const toggleFilter = () => setIsFilterOpened(wasOpened => !wasOpened);
	const limit = 20;
	
	const deleteConfirmationResult = (isConfirmed) => {
		const id = deleteId;
		setDeleteId(null);
		if (!isConfirmed) {
			return;
		}

		applicantService.deleteApplicant(id).then((response) => {
			const i = applicants.findIndex(x => x._id === id);
			if (i >= 0) {
				const tempApplicants = [...applicants];
				tempApplicants.splice(i, 1);
				setApplicants(tempApplicants);
				toast.success("Applicant Deleted successfully");
			}
		})
			.catch(() => {

			})
	}

	const filterReset = () => {
		localStorage.removeItem('filters');
	}
	const getData = () => {
		const savedFiltersJSON = localStorage.getItem('filters');
		let filters = {
			l: limit,
			o: offset,
			isEx: false
		};
		if (savedFiltersJSON) {
			const savedFilters = JSON.parse(savedFiltersJSON);
			if (savedFilters.institute) filters.institute = savedFilters.institute
		}
		if (filterFormRef.current) {
			if (filterFormRef.current.values.s) filters.s = filterFormRef.current.values.s;
			if (filterFormRef.current.values.isEx) filters.isEx = filterFormRef.current.values.isEx;
			// if (filterFormRef.current.values.city) filters.city = filterFormRef.current.values.city;
			if (filterFormRef.current.values.category) filters.category = filterFormRef.current.values.category;
			if (filterFormRef.current.values.institute) filters.institute = filterFormRef.current.values.institute;
		}

		const filtersJSON = JSON.stringify(filters);
		localStorage.setItem('filters', filtersJSON);

		applicantService.getApplicants(filters).then((response) => {
			if (offset) setApplicants([...applicants, ...response.data]);
			else setApplicants(response.data);
		})
	}

	const getCities = async (search) => {
		const conditions = { l: 20 };
		if (search) {
			conditions['s'] = search;
		}

		const response = await geoDbService.getAllCities(conditions);
		return response.data.map(x => {
			return {
				value: x._id,
				label: x.name
			};
		});
	}
	

	const getInstitutes = async (search) => {
		const conditions = { l: 20 };
		if (search) {
			conditions['s'] = search;
		}

		const response = await instituteService.getInstitutes(conditions);
		return response.data.map(x => {
			return {
				value: x._id,
				label: x.name
			};
		});
	}

	useEffect(() => {
		projectService.getAllProjects({ l: 1000 }).then((response) => {
			setCategories(response.data);
		})
	}, [])
	useEffect(() => {
		getData();
	}, [offset])
 
	return (
		<>
			<Breadcrumb title="Applicants" links={[{ title: "Applicants" }]} />
			<ConfirmationModal show={deleteId !== null} onClose={deleteConfirmationResult} />

			<section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
				<div className="row mb-3">
					<div className="col-12 mb-2">
						{
							AclService.checkPermission("ApplicantCreate") &&
							<Link to="/portal/applicant/form">
								<button type="button" className="btn btn-light btn-sm float-right"><i className="fas fa-plus"></i></button>
							</Link>
						}
						<button type="button" onClick={toggleFilter} className="btn btn-light btn-sm float-right  mr-1"><i className="fas fa-filter"> Filters (0)</i></button>
					</div>
					{isFilterOpened && (
						<div className="col-12">
							<Formik
								innerRef={filterFormRef}
								initialValues={{
									s: "",
									IsEx: "",
									category: "",
									institute: ""
								}}
								onSubmit={() => {
									if (offset) setOffset(0)
									else getData();
								}}
							>
								{({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
									<Form>
										<Card>
											<Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
												<div className="row align-items-center">
													<Field name="s" autoComplete="off" id="s" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Search.." />
													<Field as="select" name="category" className="form-control col-sm-3 sm ml-1 mt-1">
														<option value="">Select Category</option>
														{categories.map(category =>
															<option key={category._id} value={category._id} >{category.name}</option>
														)};
													</Field>
													<Field as="select" name="isEx" className="form-control col-sm-3 sm ml-1 mt-1">
														<option value="">Select Status</option>
														<option value="true">isEX</option>
														<option value="false">isCurrent</option>
														
													</Field>
													{/* <AsyncSelect name="city" className="col-sm-3 sm ml-1 p-0 mt-1" cacheOptions defaultOptions loadOptions={getCities} onChange={(val) => setFieldValue("city", val.value)} /> */}
													<AsyncSelect name="institute" className="col-sm-3 sm ml-1 p-0 mt-1" cacheOptions defaultOptions loadOptions={getInstitutes} onChange={(val) => setFieldValue("institute", val.value)} />
													<div className="col-sm-2 pt-2">
														<button type="submit" className="btn btn-secondary btn-sm">Filter</button>
														<button type="button" className="btn btn-secondary btn-sm ml-1" onClick={() => {
															resetForm();
															filterReset()
															setTimeout(() => {
																if (offset) setOffset(0)
																else getData();
															}, 200);
														}}>Reset</button>
													</div>
												</div>
											</Card.Body>
										</Card>
									</Form>
								)}
							</Formik>
						</div>
					)}
				</div>

				<div className="table-responsive">
					<InfiniteScroll height={"72vh"} dataLength={applicants.length} next={() => setOffset(offset + limit)} hasMore={true}>
						<table className="table table-hover">
							<thead>
								<tr>
									<th>Applicant No</th>
									<th>Name</th>
									<th>Father Name</th>
									<th>Mother Name</th>
									<th>Village Name</th>
									<th>Tehsil</th>
									<th>Post Office</th>
									<th>Result Card</th>
									<th>Phone no</th>
									<th>Funding Applied</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{applicants.map((applicant, index) => {
									return <tr key={applicant._id}>
										<td>{index + 1}</td>
										<td>{applicant.fName} {applicant.lName}</td>
										<td>{applicant.fatherName}</td>
										<td>{applicant.motherName}</td>
										<td>{applicant.villageName}</td>
										<td>{applicant.tehsil}</td>
										<td>{applicant.postOffice}</td>
										<td>{applicant.state}</td>
										<td>{applicant.phone}</td>
										<td>{applicant.fundRequests.length}</td>
										<td><Dropdown>
											<Dropdown.Toggle variant="light" id="dropdown-basic">
												<i className="fas fa-cog"></i>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{
													AclService.checkPermission("ApplicantView") &&
													<Dropdown.Item eventKey={"1"} as={Link} to={"/portal/applicant/" + applicant._id}>View Profile</Dropdown.Item>
												}
												{
													AclService.checkPermission("ApplicantDelete") &&
													<Dropdown.Item eventKey={"3"} onClick={() => setDeleteId(applicant._id)}>Delete</Dropdown.Item>
												}
												{
													AclService.checkPermission("ApplicantUpdate") &&
													<Dropdown.Item eventKey={"2"} as={Link} to={"/portal/applicant/form/" + applicant._id}>Edit</Dropdown.Item>
												}
												{
													AclService.checkPermission("ApplicantCreateFundRequest") &&
													<Dropdown.Item eventKey={"4"} as={Link} to={"/portal/fund-request/form/" + applicant._id}>
														Create Fund Request
													</Dropdown.Item>
												}
											</Dropdown.Menu>
										</Dropdown>
										</td>
									</tr>
								}
								)}
							</tbody>
						</table>
					</InfiniteScroll>
				</div>

			</section>
		</>
	)
}
