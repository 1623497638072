import { React, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import AsyncSelect from 'react-select/async';
import { Breadcrumb } from "../shared/Breadcrumb";
import { DonationRequestInfo } from "../DonationRequest/component/DonationRequestInfo";
import { EventService } from "../../services/Event.service";
import { DisbursementService } from "../../services/Disbursement.service";
import { DonationRequestService } from "../../services/DonationRequest.service";
import { ProjectService } from "../../services/Project.service";
import { InstituteService } from "../../services/Institute.service";

const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) => {
  return (
    <label htmlFor={id.toString()} className={`label btn btn-outline-secondary px-3 t600 ls0 nott ${id === value ? 'active' : ''}`}>
      <input id={id.toString()} value={id} checked={id === value} name={name} type="radio" onChange={onChange} onBlur={onBlur} {...props} />{" "}
      {label}
    </label>
  );
};

const DisbursementSchema = Yup.object().shape({
  isApplicantDisbursement: Yup.boolean(),

  DonationRequestId: Yup.string().when('isApplicantDisbursement', {
    is: (val) => val,
    then: Yup.string().required('Fund request is required'),
    otherwise: Yup.string(),
  }),

  EventId: Yup.string().when('isApplicantDisbursement', {
    is: (val) => !val,
    then: Yup.string().required('Event is required'),
    otherwise: Yup.string(),
  }),

  CategoryId: Yup.string().when('isApplicantDisbursement', {
    is: (val) => !val,
    then: Yup.string().required('Category is required'),
    otherwise: Yup.string(),
  }),

  InstitutionId: Yup.string(),

  amount: Yup.number()
    .required("Amount is required")
});

const eventService = new EventService();
const disbursementService = new DisbursementService();
const donationRequestService = new DonationRequestService();
const projectService = new ProjectService();
const instituteService = new InstituteService();

export const DisbursementForm = () => {
  const history = useHistory();
  const { id, DonationRequestId } = useParams();
  const [categories, setCategories] = useState([]);
  const [maxDisburseAble, setMaxDisburseAble] = useState(null);

  const getInstitute = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions['s'] = search;
    }
    const response = await instituteService.getInstitutes(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.name
      };
    });
  }

  const getProjects = (search) => {
    const conditions = { l: 100 };
    if (search) {
      conditions['s'] = search;
    }

    projectService.getAllProjects(conditions).then((response) => {
      setCategories(response.data);
    })
  }

  const getEvents = async (search) => {
    const conditions = { l: 20, role: "donor" };
    if (search) {
      conditions['s'] = search;
    }
    const response = await eventService.getEvents(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.name
      };
    });
  }

  const getDonationRequests = async (search) => {
    const conditions = { l: 20, status: "Active" };
    if (search) {
      conditions['s'] = search;
    }
    const response = await donationRequestService.getDonationRequests(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.uid + " " + x.applicant.fName + " " + x.applicant.lName
      };
    });
  }

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    const data = {
      amount: values.amount,
      DonationRequestId: null,
      ApplicantId: null,
      EventId: null,
      CategoryId: null,
      InstitutionId: null
    }

    if (values.DonationRequestId) data['DonationRequestId'] = values.DonationRequestId;
    if (values.EventId) data['EventId'] = values.EventId;
    if (values.CategoryId) data['CategoryId'] = values.CategoryId;
    if (values.InstitutionId) data['InstitutionId'] = values.InstitutionId;

    if (values.isApplicantDisbursement == "true" && values.amount > maxDisburseAble) {
      toast.error("You are trying to disburse higher than the requested fund");
      setSubmitting(false);
      return;
    }

    // if (id) {
    //   eventService.update(id, data)
    //     .then((response) => {
    //       resetForm();
    //     })
    //     .catch((error) => {

    //     })
    //     .finally(() => {
    //       setSubmitting(false);

    //     })
    //   return;
    // }

    disbursementService.create(data).then((response) => {
      toast.success("Fund disbursed successfully");
      if (DonationRequestId) history.push("/portal/donation-request");
      else history.push("/portal/donations");
    })
      .catch((error) => {
        toast.error("Fund Could not disbursed")
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    getProjects();
  }, [])

  return (
    <>
      <Breadcrumb title="Disbursement Form" links={[{ title: "Disbursement Form" }]} />

      <section id="content" className="container-xl p-2 mt-3 ">
        <Formik
          initialValues={{
            isApplicantDisbursement: "true",
            DonationRequestId: DonationRequestId || "",
            EventId: "",
            CategoryId: "",
            InstitutionId: "",
            amount: ""
          }}
          validationSchema={DisbursementSchema}
          onSubmit={formSubmitted}
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => {
            return (
              <Form>
                <div className='row'>
                  {!DonationRequestId &&
                    <div className="col-12 form-group mb-4">
                      <div className="btn-group btn-group-toggle flex-wrap w-100" data-toggle="buttons">
                        <Field component={RadioButton} name="isApplicantDisbursement" id="true" label="Disbursing to Fund Request" />
                        <Field component={RadioButton} name="isApplicantDisbursement" id="false" label="Disbursing to Event" />
                      </div>
                    </div>
                  }

                  <div className="col-12 form-group mb-2">
                    {values.isApplicantDisbursement == "true" && values.DonationRequestId &&
                      <DonationRequestInfo DonationRequestId={values.DonationRequestId} onDataLoad={(values) => {
                        setMaxDisburseAble(values.requested - values.disbursed)
                      }} />
                    }
                  </div>

                  {!DonationRequestId && values.isApplicantDisbursement == "true" &&
                    <div className="col-md-6 form-group">
                      <label className="label" htmlFor="DonationRequestId">
                        Fund Request</label>
                      <AsyncSelect name="DonationRequestId" cacheOptions defaultOptions loadOptions={getDonationRequests} onChange={(val) => setFieldValue("DonationRequestId", val.value)} />
                      {errors.DonationRequestId && touched.DonationRequestId ? (
                        <div className="error">{errors.DonationRequestId}</div>
                      ) : null}
                    </div>
                  }

                  <div className="col-md-6 form-group">
                    <label className="label">Event</label>
                    <AsyncSelect name="EventId" cacheOptions defaultOptions loadOptions={getEvents} onChange={(val) => setFieldValue("EventId", val.value)} />
                    {errors.EventId && touched.EventId ? (
                      <div className="error">{errors.EventId}</div>
                    ) : null}
                  </div>

                  {values.isApplicantDisbursement == "false" && <>
                    <div className="col-md-6 form-group">
                      <label className="label">Category</label>
                      <Field as="select" name="CategoryId" className="form-control">
                        <option value="">Select Category</option>
                        {categories.map(category =>
                          <option key={category._id} value={category._id} >{category.name}</option>
                        )};
                      </Field>
                      {errors.CategoryId && touched.CategoryId ? (
                        <div className="error">{errors.CategoryId}</div>
                      ) : null}
                    </div>

                    <div className="col-md-6 form-group">
                      <label className="label">Institute</label>
                      <AsyncSelect name="InstitutionId" cacheOptions defaultOptions loadOptions={getInstitute} onChange={(val) => setFieldValue("InstitutionId", val.value)} />
                      {errors.InstitutionId && touched.InstitutionId ? (
                        <div className="error">{errors.InstitutionId}</div>
                      ) : null}
                    </div>

                    <div className="col-md-6"></div>
                  </>}

                  <div className="col-md-6 form-group">
                    <label className="label" htmlFor="amount">
                      Enter amount
                    </label>
                    <Field type="number" name="amount" id="amount" className="form-control" placeholder="Enter amount" />
                    {errors.amount && touched.amount ? (
                      <div className="error">{errors.amount}</div>
                    ) : null}
                  </div>

                  <div className="col-12 mt-2">
                    <button type="submit" disabled={isSubmitting} className="btn btn-secondary">
                      {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
                    </button>
                    {DonationRequestId &&
                      <Link to="/portal/donation-request">
                        <button type="button" name="" className=" btn btn-warn">
                          Cancel
                        </button>
                      </Link>
                    }
                    {!DonationRequestId &&
                      <Link to="/portal/disbursement">
                        <button type="button" name="" className=" btn btn-warn">
                          Cancel
                        </button>
                      </Link>
                    }
                  </div>
                </div>

              </Form>
            )
          }
          }
        </Formik>
      </section>

    </>
  );
};
