import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal } from "react-bootstrap"
import { toast } from "react-toastify";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export const ImageCropperNew = (props) => {
  const inputRef = useRef(null);
  const { btnLabel = "Select Image", inputName = "", showPreview = false, aspect = undefined, unit = "%", width = 90, height = 90, disabled = false, locked = false } = props;
  const [showCropModal, setShowCropModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [readerResult, setReaderResult] = useState(props.src || "");
  const [crop, setCrop] = useState({ unit: unit, width: width, height: height, aspect });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [file, setFile] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const onCropComplete = (blob = null) => {
    if (!props.onCropComplete) return;
    if (!blob) {
      setFile(null);
      setPreview(false);
      inputRef.current.value = "";
    } else {
      setPreview(true);
    };

    setTimeout(() => {
      props.onCropComplete({
        file: blob && file ? file : null,
        blob: blob && file ? blob : null
      })
    }, 200);
  }

  const onInputSelectChange = (e) => {
    if (!e.target.files || e.target.files.length <= 0) {
      onCropComplete()
      return;
    }

    const file = e.target.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const allowedTypes = ['jpg', 'jpeg', 'png'];
    const isInvalid = allowedTypes.indexOf(fileExtension) < 0;

    if (isInvalid) {
      toast.error(`Only ${allowedTypes.join(", ")} are allowed`);
      onCropComplete();
      return;
    }
    setFile(file);

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setReaderResult(reader.result);
      setShowCropModal(true);
    });
    reader.readAsDataURL(file);
  }

  const onImageLoadForCrop = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);


  return (
    <>
      <div className="pt-2 pb-2" style={{ width: "100%" }}>
        <input type="file" accept="image/*" name={inputName} ref={inputRef} style={{ display: 'none' }} onChange={onInputSelectChange} />
        <button type="button" className="btn btn-light" onClick={() => {
          inputRef.current.click();
        }}>
          {btnLabel}
        </button>

        {completedCrop?.width && completedCrop?.height &&
          <div className="mt-2" style={{ cursor: "nesw-resize", display: preview && showPreview ? "block" : "none" }}>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0)
              }}
              onClick={() => setShowCropModal(true)}
            />
          </div>
        }
      </div>

      <Modal show={showCropModal && readerResult !== ""} backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title> <h5 className="modal-title">Set Image View</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactCrop
            src={readerResult}
            onImageLoaded={onImageLoadForCrop}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            keepSelection={true}
            disabled={disabled}
            locked={locked}
          />
          <Modal.Footer className="pb-0">
            <button type="button" className="btn btn-danger" onClick={() => {
              setShowCropModal(false);
              onCropComplete();
            }}>Remove</button>
            <button type="button" className="btn btn-secondary" onClick={() => {
              setShowCropModal(false);
              const canvas = previewCanvasRef.current;
              if (!canvas) return;
              canvas.toBlob(
                (blob) => onCropComplete(blob),
                'image/png',
                1
              );
            }}>Save</button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}