import React from 'react';
import { Card } from './Card';
import { Graph } from './Graph';
import { PieGraph } from './PieGraph';
import { Breadcrumb } from '../../PublicSite/Shared/BreadCrumb';
export const Home = () => {
  return (
    <>
      <div className="stretched side-header">
        <div className='clearfix' style={{ opacity: 1, boxShadow: "none" }}>
          <Breadcrumb title="Portal" links={[{ title: "portal" }]} />
          <Card />
          <Graph />
          <PieGraph />
        </div>
      </div>
    </>
  )
}
