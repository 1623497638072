import { React, useState, useRef, useEffect } from 'react';
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { Link, useParams, useHistory } from 'react-router-dom';
import { TagsInput } from "react-tag-input-component";
import { Breadcrumb } from "../shared/Breadcrumb";
import { ImageCropperNew } from "../shared/ImageCropperNew";
import { EventService } from "../../services/Event.service";
import { UploadService } from "../../services/Upload.service";
import { removeQueryString } from "../../utils/helping.utils";

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EventSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, "Name cannot exceed 50 letters!")
    .required("Name is required"),

  summary: Yup.string()
    .max(200, "Summary must be shorter than or equal to 200 characters")
    .required("Summary is required"),

  description: Yup.string()
    .required("Description is required"),

  thumbnail: Yup.string()
    .required("Thumbnail is required"),

  cover: Yup.string()
  .required("Cover is required"),

  seo: Yup.object()
});

const eventService = new EventService();
const uploadService = new UploadService();

export const EventForm = () => {
  const history = useHistory();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [keywords, setKeywords] = useState([]);
  const { id } = useParams();
  const formRef = useRef();
  const [uploadingCover, setUploadingCover] = useState(false);
  const [uploadingThumbnail, setUploadingThumbnail] = useState(false);

  const thumbnailChanged = (data) => {
    const formRefCurrent = formRef.current;
    if (!data || !data.blob || !data.file || !formRefCurrent) {
      return;
    };

    setUploadingThumbnail(true);
    uploadService.uploadBlob(data.blob, data.file, "public-read").then((response) => {
      formRefCurrent.setFieldValue("thumbnail", removeQueryString(response.location));
      setUploadingThumbnail(false);
    })
      .catch((error) => {
        toast.error("Image could not be uploaded")
        setUploadingThumbnail(false);
      })
  }

  const coverChanged = (data) => {
    const formRefCurrent = formRef.current;
    if (!data || !data.blob || !data.file || !formRefCurrent) {
      return;
    };

    setUploadingCover(true);
    uploadService.uploadBlob(data.blob, data.file, "public-read").then((response) => {
      formRefCurrent.setFieldValue("cover", removeQueryString(response.location));
      setUploadingCover(false);
    })
      .catch((error) => {
        toast.error("Image could not be uploaded")
        setUploadingCover(false);
      })
  }

  const removeCover = () => {
    const formRefCurrent = formRef.current;
    if (formRefCurrent) formRefCurrent.setFieldValue("cover", "");
  }

  const removeThumbnail = () => {
    const formRefCurrent = formRef.current;
    if (formRefCurrent) formRefCurrent.setFieldValue("thumbnail", "");
  }

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    if (!values.thumbnail) {
      toast.error("Please upload a thumbnail image");
      setSubmitting(false);
      return;
    }

    if (!values.cover) {
      toast.error("Please upload a cover image");
      setSubmitting(false);
      return;
    }

    const data = {
      name: values.name,
      summary: values.summary,
      description: values.description,
      thumbnail: values.thumbnail,
      cover: values.cover,
      keywords: values.keywords,
      seo: {
        title: values.name,
        description: values.summary,
        keywords: keywords.join(", ")
      }
    }

    if (id) {
      eventService.update(id, data)
        .then((response) => {
          toast.success("Event updated successfully")
          resetForm();
          history.push("/portal/event")
        })
        .catch((error) => {
          toast.error("Event could not updated")
        })
        .finally(() => {
          setSubmitting(false);

        })
      return;
    }

    eventService.create(data).then((response) => {
      toast.success("Event created successfully")
      resetForm();
      history.push("/portal/event")
    })
      .catch((error) => {
        toast.error("Event could not created!")
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      eventService.getEvent(id).then((response) => {
        const formRefCurrent = formRef.current;
        if (response.data && formRefCurrent) {
          formRefCurrent.setFieldValue("name", response.data.name);
          formRefCurrent.setFieldValue("summary", response.data.summary);
          formRefCurrent.setFieldValue("description", response.data.description);
          formRefCurrent.setFieldValue("thumbnail", removeQueryString(response.data.thumbnail));
          formRefCurrent.setFieldValue("cover", removeQueryString(response.data.cover));
          formRefCurrent.setFieldValue("slug", response.data.slug);
          formRefCurrent.setFieldValue("keywords", response.data.seo.keywords);

          setKeywords(keywords);

          const contentDataState = ContentState.createFromBlockArray(convertFromHTML(response.data.description));
          const editorDataState = EditorState.createWithContent(contentDataState);
          setEditorState(editorDataState);
        }
      })
    }
  }, [])

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const formRefCurrent = formRef.current;
    if (formRefCurrent) formRefCurrent.setFieldValue("description", draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }

  return (
    <>
      <Breadcrumb title="Event Form" links={[{ title: "Event Form" }]} />
      <section id="content" className="container-xl p-2 mt-3 ">
        <Formik
          innerRef={formRef}
          initialValues={{
            name: "",
            summary: "",
            description: "",
            thumbnail: "",
            cover: "",
            slug: "",
            seo: {},
          }}
          validationSchema={EventSchema}
          onSubmit={formSubmitted}
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => (
            <Form>
              <div className='row'>
                <div className="col-12 form-group">
                  <label className="label" htmlFor="name">
                    Name
                  </label>
                  <Field name="name" id="name" className="form-control" placeholder="Enter name" />
                  {errors.name && touched.name ? (
                    <div className="error">{errors.name}</div>
                  ) : null}
                </div>

                <div className="col-12 form-group">
                  <label className="label" htmlFor="summary">
                    Summary
                  </label>
                  <Field as="textarea" name="summary" id="summary" className="form-control" placeholder="Enter Summary" />
                  {errors.summary && touched.summary ? (
                    <div className="error">{errors.summary}</div>
                  ) : null}
                </div>

                <div className="col-12 form-group">
                  <label className="label" htmlFor="keywords">
                    Keywords
                  </label>
                  <TagsInput value={keywords} onChange={setKeywords} name="skill" id="keywords" placeHolder="Enter keywords" />
                  <em>Press enter to add new keyword</em>
                </div>

                <div className="col-sm-6 form-group">
                  <label className="label">
                    Thumbnail Image
                  </label>
                  {!uploadingThumbnail && errors.thumbnail && touched.thumbnail ? (
                    <div className="error mt-0">{errors.thumbnail}</div>
                  ) : null}
                  {uploadingThumbnail && <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>}
                  {!uploadingThumbnail && <>
                    {values.thumbnail !== "" && values.thumbnail !== undefined && <div className="imageUpload">
                      <img src={values.thumbnail} alt="Thumbnail" />
                      <button className="btn btn-light pl-4 pr-4" onClick={removeThumbnail}><i className="fa fa-trash"></i></button>
                    </div>}
                    {!values.thumbnail &&
                      <ImageCropperNew btnLabel="Upload Thumbnail Image" inputName="thumbnailFile" onCropComplete={thumbnailChanged} />
                    }
                  </>}
                </div>

                <div className="col-sm-6 form-group">
                  <label className="label">
                    Cover Image
                  </label>
                  {!uploadingCover && errors.cover && touched.cover ? (
                    <div className="error mt-0">{errors.cover}</div>
                  ) : null}
                  {uploadingCover && <i className="fas fa-spinner fa-pulse fa-6x" style={{ color: "#685f5f73" }}></i>}
                  {!uploadingCover && <>
                    {values.cover !== "" && values.cover !== undefined && <div className="imageUpload">
                      <img src={values.cover} alt="Cover" />
                      <button className="btn btn-light pl-4 pr-4" onClick={removeCover}><i className="fa fa-trash"></i></button>
                    </div>}
                    {!values.cover &&
                      <ImageCropperNew btnLabel="Upload Cover Image" inputName="coverFile" onCropComplete={coverChanged} />
                    }
                    {errors.cover && touched.cover ? (
                      <div className="error mt-1">{errors.cover}</div>
                    ) : null}
                  </>}
                </div>

                <div className="col-12 form-group">
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                  />
                  {errors.description && touched.description ? (
                    <div className="error">{errors.description}</div>
                  ) : null}
                </div>

                <div className="col-12 mt-4">
                  <button type="submit" name="website-cost-submit" className="btn btn-secondary" disabled={isSubmitting}>
                    {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Save
                  </button>
                  <Link to="/portal/event">
                    <button type="button" className="btn btn-warn  ">
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </>

  );
};
