import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class RoleService extends HttpService {
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/role";
  }

  create(data) {
    return this.post(this.apiUrl, data);
  }

  allPermission() {
    return this.get(this.apiUrl + "/all-permissions");
  }

  getRoles(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }

  getRole(id, data) {
    return this.get(this.apiUrl + "/" + id, data);
  }

  deleteRole(id) {
    return this.delete(this.apiUrl + "/" + id);
  }

  update(id, data) {
    return this.patch(this.apiUrl + "/" + id, data)
  }
}