import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class EventService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/event";
  } 
  create(data) {
    return this.post(this.apiUrl, data)
  }

  deleteEvent(id) {
    return this.delete(this.apiUrl + "/" + id);
  }
  getEvents(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }

  getEvent(id) {
    return this.get(this.apiUrl + "/" + id);
  }
  update(id, data) {
    return this.patch(this.apiUrl + "/" + id, data)
  }
}