import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class ExperienceService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/experience/applicant/";
  } 

  getApplicants(id) {
    return this.get(this.apiUrl + "/" + id);
  }

  update(ApplicantId, id, data) {
    return this.patch(this.apiUrl + ApplicantId + "/" + id, data);
  }
}