import React from 'react'
import { Breadcrumb } from '../Shared/BreadCrumb';

export const OurTeam = () => {
  return (
    <>
      <Breadcrumb title="Our Team" links={[{ title: "Our Team" }]} />
      <div className="container clearfix">
        <div className="row mt-5">
          <div className="col-lg-6 bottommargin">
            <div className="team team-list clearfix">
              <div className="team-image">
                <img src="/Sulakhan-Singh.jpg" alt="Sulakhan Singh Samra" />
              </div>
              <div className="team-desc">
                <div className="team-title"><h4>Sulakhan Singh Samra</h4><span>President</span></div>
                <div className="team-content">
                  <p>I am honoured to be the President and one of the founders of The Sikh Aid Global. Our charity has been working hard for the past 18 years to help improve the standards of living of the Sikligar and Vanjara communities in India. </p>
                </div>
                <a href="#" className="social-icon si-rounded si-small si-facebook">
                  <i className="icon-facebook"></i>
                  <i className="icon-facebook"></i>
                </a>
                <a href="#" className="social-icon si-rounded si-small si-twitter">
                  <i className="icon-twitter"></i>
                  <i className="icon-twitter"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 bottommargin">
            <div className="team team-list clearfix">
              <div className="team-image">
                <img src="/Dr-Inderjit-Singh.jpg" alt="Dr Inderjit Singh" />
              </div>
              <div className="team-desc">
                <div className="team-title"><h4>Dr Inderjit Singh</h4><span>Support</span></div>
                <div className="team-content">
                  <p>I have had the pleasure of being part of Sikh Council of Scotland (Sikh Aid Global) for the last ten years. The council is working hard to uplift the plight of Sikhlighar and Vanjara communities in MP and Maharashtra states in India. Short term help is given with providing clean water, assistance with heath issues and financial help with the daily necessities of life. However, the long term aim is to provide education to young children of the community to permanently uplift them from the cycle of poverty.</p>
                </div>
                <a href="#" className="social-icon si-rounded si-small si-facebook">
                  <i className="icon-facebook"></i>
                  <i className="icon-facebook"></i>
                </a>
                <a href="#" className="social-icon si-rounded si-small si-twitter">
                  <i className="icon-twitter"></i>
                  <i className="icon-twitter"></i>
                </a>
              </div>
            </div>
          </div>



          <div className="col-lg-6 bottommargin">
            <div className="team team-list clearfix">
              <div className="team-image">
                <img src="/Vijay-Pal-Singh-Baryah.jpg" alt="Vijaypal Singh Baryah" />
              </div>
              <div className="team-desc">
                <div className="team-title"><h4>Vijaypal Singh Baryah</h4><span>CO-Founder</span></div>
                <div className="team-content">
                  <p>My name is Vijay Pal Singh Baryah and I am honoured to be part of Sikh Aid Scotland since 2011. I have been involved in education for the kids which I feel is the most important in librating the mind and if they achieve professional qualifications then they will be able to get good jobs and take the families forward.</p>
                </div>
                <a href="#" className="social-icon si-rounded si-small si-facebook">
                  <i className="icon-facebook"></i>
                  <i className="icon-facebook"></i>
                </a>
                <a href="#" className="social-icon si-rounded si-small si-twitter">
                  <i className="icon-twitter"></i>
                  <i className="icon-twitter"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 bottommargin">
            <div className="team team-list clearfix">
              <div className="team-image">
                <img src="/Sardara-Singh-Jandoo.jpg" alt="Sardara Singh Jandu" />
              </div>
              <div className="team-desc">
                <div className="team-title"><h4>Sardara Singh Jandu</h4><span>Support</span></div>
                <div className="team-content">
                  <p>
                    My name is Sardara Singh Jandoo born in 1937 in Panjab India.
                    I passed my matriculation Examination from Panjab University and have attained a Building
                    Construction Diploma from the Bennet College Sheffield England by correspondence
                    Course. Also Attained ONC (Building) from the Glasgow College of Building attending night
                    classes.
                    Lave have vast experience in various fields and I worked for Company as a Draughtsman for 28 years in Glasgow. Now I am retired and helping my son in the Pharmacy.
                    Also I am awarded with the Certificate of Recognition of achievement in Service to the Community.
                    Thanking you</p>
                </div>
                <a href="#" className="social-icon si-rounded si-small si-facebook">
                  <i className="icon-facebook"></i>
                  <i className="icon-facebook"></i>
                </a>
                <a href="#" className="social-icon si-rounded si-small si-twitter">
                  <i className="icon-twitter"></i>
                  <i className="icon-twitter"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 bottommargin">
            <div className="team team-list clearfix">
              <div className="team-image">
                <img src="/Gurdeep-Singh.jpg" alt="Gurdip Singh Samra" />
              </div>
              <div className="team-desc">
                <div className="team-title"><h4>Gurdip Singh Samra</h4><span>General Secretary</span></div>
                <div className="team-content">
                  <p>I have been privileged to have been working with the Sikh Aid Global since 2007.  Our recent work has been focusing on the development of skills and provision of free education to children</p>
                </div>
                <a href="#" className="social-icon si-rounded si-small si-facebook">
                  <i className="icon-facebook"></i>
                  <i className="icon-facebook"></i>
                </a>
                <a href="#" className="social-icon si-rounded si-small si-twitter">
                  <i className="icon-twitter"></i>
                  <i className="icon-twitter"></i>
                </a>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}
