import { React, useState, useEffect, useRef } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import AsyncSelect from 'react-select/async';
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import { Breadcrumb } from "../shared/Breadcrumb";
import { Link } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { InstituteService } from "../../services/Institute.service";
import { GeoDbService } from "../../services/GeoDb.service";
import { ProjectService } from "../../services/Project.service";
import AclService from "../../services/Acl.service";
import {approved} from "../../utils/helping.utils";


export const Institution = () => {
  const filterFormRef = useRef();
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const instituteService = new InstituteService();
  const [institutes, setInstitutes] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [offset, setOffset] = useState(0);
  const geoDbService = new GeoDbService();
  const projectService = new ProjectService();
  const [categories, setCategories] = useState([]);
  const toggleFilter = () => setIsFilterOpened(wasOpened => !wasOpened);
  const limit = 20;
 
  const deleteConfirmationResult = (isConfirmed) => {
    const id = deleteId;
    setDeleteId(null);
    if (!isConfirmed) {
      return;
    }
    instituteService.deleteInstitute(id).then((response) => {
      const i = institutes.findIndex(x => x._id === id);
      if (i >= 0) {
        const tempInstitutes = [...institutes];
        tempInstitutes.splice(i, 1);
        setInstitutes(tempInstitutes);
        toast.success("Institution deleted successfully")
      }
    })
      .catch(() => {
        toast.error("Institution could not delete")
      })
  }
  const approveInstitute = (id, index) => {
    const data = {
      approved: true,
    }
    instituteService.approve(id, data).then(() => {
      toast.success("Institute approved successfully");
      const tempRecords = [...institutes];
      tempRecords[index].approved = true;
      // tempRecords.splice(index, 1);
      setInstitutes(tempRecords);

    })
      .catch(() => {
        toast.error("Institute could not be approved");
      })
  }

  const UnApproveInstitute = (id, index) => {
    const data = {
      approved: false,
    }
    instituteService.unApprove(id, data).then(() => {
      toast.success("Institute unapproved successfully");
      const tempRecords = [...institutes];
      tempRecords[index].approved = false;
      // tempRecords.splice(index, 1);
      setInstitutes(tempRecords);

    })
      .catch(() => {
        toast.error("Institute could not be approved");
      })
  }

  const getData = () => {
    const filters = {
      l: limit,
      o: offset
    };
    if (filterFormRef.current) {
      if (filterFormRef.current.values.s) filters.s = filterFormRef.current.values.s;
      if (filterFormRef.current.values.CityId) filters.CityId = filterFormRef.current.values.CityId;
      if (filterFormRef.current.values.CategoryId) filters.CategoryId = filterFormRef.current.values.CategoryId;
    }
    instituteService.getInstitutes(filters).then((response) => {
      if (offset) setInstitutes([...institutes, ...response.data]);
      else setInstitutes(response.data);
    })
  }
  const getCities = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions['s'] = search;
    }

    const response = await geoDbService.getAllCities(conditions);
    return response.data.map(x => {
      return {
        value: x._id,
        label: x.name
      };
    });
  }
  useEffect(() => {
    projectService.getAllProjects({ l: 1000 }).then((response) => {
      setCategories(response.data);
    })
  }, [])

  useEffect(() => {
    getData();
  }, [offset])

  return (
    <>
      <Breadcrumb title="Institutions" links={[{ title: "Institutions" }]} />
      <ConfirmationModal show={deleteId !== null} onClose={deleteConfirmationResult} />
      <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
        <div className="row mb-3">
          <div className="col-12 mb-2">
            {
              AclService.checkPermission("InstituteCreate") &&
              <Link to="/portal/institute/form">
                <button type="button" className="btn btn-light btn-sm float-right" >
                  <i className="fas fa-plus"></i>
                </button>
              </Link>}
            <button type="button" onClick={toggleFilter} className="btn btn-light btn-sm float-right  mr-1">
              <i className="fas fa-filter"> Filters (0)</i>
            </button>
          </div>
          {isFilterOpened && (
            <div className="col-12">
              <div className="col-12">
                <Formik
                  innerRef={filterFormRef}
                  initialValues={{
                    s: "",
                    CityId: "",
                    CategoryId: "",
                  }}
                  onSubmit={() => {
                    if (offset) setOffset(0)
                    else getData();
                  }}
                >
                  {({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
                    <Form>
                      <Card>
                        <Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
                          <div className="row align-items-center">
                            <Field name="s" autoComplete="off" id="s" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Search.." />
                            <AsyncSelect name="CityId" className="col-sm-3 sm ml-1 p-0 mt-1" cacheOptions defaultOptions loadOptions={getCities} onChange={(val) => setFieldValue("CityId", val.value)} />
                            <Field as="select" name="CategoryId" className="form-control col-sm-3 sm ml-1 mt-1">
                              <option value="">Select Category</option>
                              {categories.map(category =>
                                <option key={category._id} value={category._id} >{category.name}</option>
                              )};
                            </Field>
                            <div className="col-sm-2 pt-2">
                              <button type="submit" className="btn btn-secondary btn-sm">Filter</button>
                              <button type="button" className="btn btn-secondary btn-sm ml-1" onClick={() => {
                                resetForm();
                                setTimeout(() => {
                                  if (offset) setOffset(0)
                                  else getData();
                                }, 200);
                              }}>Reset</button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>

        <div className="table-responsive">
          <InfiniteScroll
            height={"72vh"}
            dataLength={institutes.length}
            next={() => setOffset(offset + limit)}
            hasMore={true}
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>City</th>
                  <th>Phone no</th>
                  <th>Status</th>
                  <th className="d-flex justify-content-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {institutes.map((institute, index) => {
                  return <tr key={institute._id}>
                    <td>{institute.name}</td>
                    <td>{institute.category && institute.category.name ? institute.category.name: ""}</td>
                    <td>{institute.city && institute.city.name ? institute.city.name: ""}</td>

                    <td>{institute.phone}</td>
                      <td>
                      { AclService.checkPermission("InstituteApprove") && !institute.approved && <button type="button" className="btn btn-danger btn-sm" onClick={() => approveInstitute(institute._id, index)}>Approve</button> }
                      { AclService.checkPermission("InstituteApprove") && institute.approved && <button type="button" className="btn btn-danger btn-sm" onClick={() => UnApproveInstitute(institute._id, index)}>Unapprove</button> }
                      { !AclService.checkPermission("InstituteApprove") && <p>{approved(institute.approved)}</p> }
                      </td>
                    <td className="d-flex justify-content-center">
                      <Dropdown className="ml-1">
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                          <i className="fas fa-cog"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            AclService.checkPermission("InstituteDelete") &&
                            <Dropdown.Item eventKey={"1"} onClick={() => setDeleteId(institute._id)}>Delete</Dropdown.Item>
                          }
                          {
                            AclService.checkPermission("InstituteUpdate") &&
                            <Dropdown.Item eventKey={"2"} as={Link} to={"/portal/institute/form/" + institute._id}>Edit</Dropdown.Item>
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                }
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </section>
    </>
  );
};
