import { React, useState, useEffect, useRef } from 'react';
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { RoleService } from '../../services/Role.service';
import { Breadcrumb } from "../shared/Breadcrumb";
import AclService from "../../services/Acl.service";
const roleService = new RoleService();


export const Role = () => {
  const filterFormRef = useRef();
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const toggleFilter = () => setIsFilterOpened(wasOpened => !wasOpened);
  const [deleteId, setDeleteId] = useState(null);
  const [offset, setOffset] = useState(0);
  const [roles, setRole] = useState([]);
  const limit = 20;
  const getData = () => {
    const filters = {
      l: limit,
      o: offset
    };

    if (filterFormRef.current) {
      if (filterFormRef.current.values.name) filters.name = filterFormRef.current.values.name;
    }
    roleService.getRoles().then((response) => {
      if (offset) setRole([...roles, ...response.data]);
      setRole(response.data);
    })
  }

  useEffect(() => {
    getData();
  }, [])

  const deleteConfirmationResult = (isConfirmed) => {
    const id = deleteId;
    setDeleteId(null);
    if (!isConfirmed) {
      return;
    }

    roleService.deleteRole(id).then((response) => {
      const i = roles.findIndex(x => x._id === id);
      if (i >= 0) {
        const tempRoles = [...roles];
        tempRoles.splice(i, 1);
        setRole(tempRoles);
      }
    })
      .catch(() => {

      })
  }
  return (
    <>
      <Breadcrumb title="Roles" links={[{ title: "Roles" }]} />

      <ConfirmationModal show={deleteId !== null} onClose={deleteConfirmationResult} />
      <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
        <div className="row mb-3">
          <div className="col-12 mb-2">
            {
              AclService.checkPermission("RoleCreate") &&
              <Link to="/portal/role/form">
                <button type="button" className="btn btn-light btn-sm float-right" >
                  <i className="fas fa-plus"></i>
                </button>
              </Link>}
            <button type="button" onClick={toggleFilter} className="btn btn-light btn-sm float-right  mr-1">
              <i className="fas fa-filter"> Filters (0)</i>
            </button>
          </div>
          {isFilterOpened && (
            <div className="col-12">
              <Formik
                innerRef={filterFormRef}
                initialValues={{
                  name: "",
                }}
                onSubmit={() => {
                  if (offset) setOffset(0)
                  else getData();
                }}
              >
                {({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
                  <Form>
                    <Card>
                      <Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
                        <div className="row align-items-center">
                          <Field name="name" autoComplete="off" id="name" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Search.." />
                          <div className="col-sm-2 pt-2">
                            <button type="submit" className="btn btn-secondary btn-sm">Filter</button>
                            <button type="button" className="btn btn-secondary btn-sm ml-1" onClick={() => {
                              resetForm();
                              setTimeout(() => {
                                if (offset) setOffset(0)
                                else getData();
                              }, 200);
                            }}>Reset</button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Is Public</th>
                <th>Permissions</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {roles.map((role, index) => {
                return <tr key={role._id}>
                  <td>{role.name}</td>
                  <td>{role.isPublic}</td>
                  <td>{role.permissions.join(", ")}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <i className="fas fa-cog"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {role.isDeleteAble && AclService.checkPermission("RoleDelete") &&
                          <Dropdown.Item eventKey={"1"} onClick={() => setDeleteId(role._id)}>Delete</Dropdown.Item>
                        }
                        {AclService.checkPermission("RoleUpdate") &&
                          <Dropdown.Item eventKey={"2"} as={Link} to={"/portal/role/form/" + role._id}>Edit</Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              }
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};
