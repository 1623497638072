import { HttpService } from "./Http.service";
import { SERVER_BASE_URL } from "../constants";

export class DisbursementService extends HttpService {
  apiUrl;
  constructor() {
    super();
    this.apiUrl = SERVER_BASE_URL + "/disbursement";
  }

  create(data) {
    return this.post(this.apiUrl,  data);
  }

  findAll(conditions = {}) {
    return this.get(this.apiUrl + this.objToQuery(conditions));
  }

  deleteDisbursement(id) {
    return this.delete(this.apiUrl + "/" + id);
  }
}