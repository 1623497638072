import React from 'react';
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import { Breadcrumb } from "../Shared/BreadCrumb";
import * as Yup from "yup";
import { AuthService } from '../../services/Auth.service';
import { toast } from "react-toastify";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .min(5, "Email must contain at least 5 characters")
    .max(255, "Email cannot exceed 255 characters")
    .required("Email is required"),
});

export const ForgotPassword = () => {
  const authService = new AuthService();
  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    authService.forgotPassword(values).then((response) => {
      toast.success("Reset link is sent to your email!");
    })
      .catch((error) => {
        toast.success("Reset link is sent to your email!");
      })
      .finally(() => {
        resetForm();
        setSubmitting(false);
      });
  };
  return (
    <>
      <Breadcrumb title="ForgotPassword" links={[{ title: "Forgot Password" }]} />
      <div className="d-flex justify-content-center">
        <div className="mt-3 mb-3 p-2 col-sm-7 col-lg-6">
          <h3 className="center">Change Password</h3>
          <Formik initialValues={{
            email: "",
          }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={formSubmitted}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className=" nobottommargin ">
                <div className="form-group ">
                  <label htmlFor="email">Email</label>
                  <Field name="email" id="email" placeholder="Email address" className="sm-form-control" />
                  {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}
                </div>
                <button type="submit" disabled={isSubmitting} className="btn-block d-flex justify-content-center align-items-center btn btn-secondary mt-3">
                  {isSubmitting && <i className="fas fa-spinner fa-pulse mr-1"></i>} Send me a reset link
                </button>
                <div className="mt-2 create">
                  <Link to="/login">Log In</Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>);
};
