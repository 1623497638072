
import { React, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from "react-router-dom";
import { EventService } from '../../services/Event.service';
import { dateToDateString } from "../../utils/date.utils";

function Events(props) {
	const eventService = new EventService();
	const [events, setEvents] = useState([])
	const [offset, setOffset] = useState(0);
	const { limit = 20, scrollEnable = true } = props;

	function getData() {
		const filters = {
			l: limit,
			o: offset
		};
		eventService.getEvents(filters).then((response) => {
			if (offset) setEvents([...events, ...response.data]);
			else setEvents(response.data);
		})
	}

	const infiniteScrollNext = () => {
		if (!scrollEnable) return;
		setOffset(Number(offset) + Number(limit))
	}

	useEffect(() => {
		getData();
	}, [offset])

	return (
		<>
			<section>
				<div className="container clearfix event">
					<h2 className="center mt-3 mb-3 heading2">News and Events</h2>
					<InfiniteScroll
						dataLength={events.length}
						next={infiniteScrollNext}
						hasMore={true}
						className="no-bottom-scroll"
						style={{ overflowX: "hidden" }}
					>
						<div className='row'>
							{events.map((event, index) => {
								return <div key={event._id} className="col-md-12 col-lg-6 mb-5">
									<div className="mpost clearfix">
										<div className="entry-image">
											<Link to={{ pathname: `/events/${event.slug}` }}><img src={event.thumbnail} alt="Blog Single" /></Link>
										</div>
										<div className="entry-c">
											<div className="entry-title">
												<h4><Link to={{ pathname: `/events/${event.slug}` }}>{event.name}</Link></h4>
											</div>
											<ul className="entry-meta clearfix">
												<li><i className="icon-calendar3"></i>{dateToDateString(event.createdAt)}</li>
											</ul>
											<div className="entry-content">{event.summary}</div>
										</div>
									</div>
								</div>
							}
							)}
						</div>
					</InfiniteScroll>
				</div>
			</section>
		</>
	)
}
export default Events;


