import { React, useRef, useEffect, useState } from 'react';
import { Field, Form, Formik, input } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from "../shared/Breadcrumb";
import { toast } from "react-toastify";
import { UserService } from "../../services/User.service"
import { RoleService } from '../../services/Role.service';
import * as Yup from "yup";

// const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) => {
//   return (
//     <label htmlFor={id} className={`label btn btn-outline-secondary px-3 t600 ls0 nott ${id === value ? 'active' : ''}`}>
//       <input id={id} value={id} checked={id === value} name={name} type="radio" onChange={onChange} onBlur={onBlur} {...props} />{" "}
//       {label}
//     </label>
//   );
// };
const roleService = new RoleService();
const UserSchema = Yup.object().shape({
  id: Yup.string(),

  fName: Yup.string()
    .min(2, "First name must contain at least 2 letters")
    .max(50, "First name cannot exceed 50 letters")
    .required("First name is required"),

  lName: Yup.string()
    .min(2, "Last name must contain at least 2 letters")
    .max(50, "Last name cannot exceed 50 letters")
    .required("Last name is required"),

  password: Yup.string().when('id', {
    is: (val) => !val,
    then: Yup.string().required("Password is required").min(8, "Password must contain at least 8 characters")
      .max(100, "Password cannot exceed 100 characters"),
    otherwise: Yup.string().min(8, "Password must contain at least 8 characters")
      .max(100, "Password cannot exceed 100 characters"),
  }),

  email: Yup.string()
    .email("Please enter a valid email")
    .min(5, "Email must contain at least 5 characters")
    .max(255, "Email cannot exceed 255 characters")
    .required("Email is required"),

  role: Yup.string().required("Please select role"),
});

export const UserForm = (Data) => {
  const history = useHistory();
  const [roles, setRole] = useState([])
  const { id } = useParams();
  const userService = new UserService();
  const formRef = useRef();

  const getData = () => {
    roleService.getRoles().then((response) => {
      setRole(response.data);
    })
  }
  useEffect(() => {
    getData();
  }, [])

  const formSubmitted = (values, { setSubmitting, resetForm }) => {
    const data = {
      fName: values.fName, lName: values.lName, email: values.email, role: values.role
    };

    if (values.password || !id) {
      data.password = values.password;
    }

    if (id) {
      userService.update(id, data)
        .then((response) => {
          toast.success("User information updated successfully");
          history.push("/portal/user")
        })
        .catch((error) => {
          toast.error("User record could not updated!")
        })
        .finally(() => {
          setSubmitting(false);
        })
      return;
    }
    userService.create(values)
      .then((response) => {
        toast.success("User information created successfully");
        resetForm();
        history.push("/portal/user")
      })
      .catch((error) => {

      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      userService.getUser(id).then((response) => {
        const formRefCurrent = formRef.current;
        if (response.data && formRefCurrent) {
          formRefCurrent.setFieldValue("id", id);
          formRefCurrent.setFieldValue("fName", response.data.fName);
          formRefCurrent.setFieldValue("lName", response.data.lName);
          formRefCurrent.setFieldValue("email", response.data.email);
          formRefCurrent.setFieldValue("role", response.data.RoleId.identifier);
        }
      })
    }
  }, [])
  return (
    <>
      <Breadcrumb title="Users" links={[{ title: "Users", to: "/portal/user" }, { title: "Form" }]} />
      <section className="container-xl p-2 mt-3 bottommargin-lg">
        <Formik
          innerRef={formRef}
          initialValues={{
            fName: "",
            lName: "",
            password: "",
            email: "",
            role: "",
          }}
          validationSchema={UserSchema}
          onSubmit={formSubmitted}>

          {({ errors, touched, isSubmitting }) => (
            <Form className="nobottommargin" autoComplete="off">
              <div className="row m-0">
                <div className="col-6">
                  <label className="label" htmlFor="fName">First Name</label>
                  <Field autoComplete="off" id="fName" name="fName" className="sm-form-control" />
                  {errors.fName && touched.fName ? (<div className="error">{errors.fName}</div>) : null}
                </div>
                <div className="col-6">
                  <label className="label" htmlFor="lName">Last Name</label>
                  <Field autoComplete="off" id="lName" name="lName" className="sm-form-control" />
                  {errors.lName && touched.lName ? (<div className="error">{errors.lName}</div>) : null}
                </div>
                <div className="form-group col-6">
                  <label className="label" htmlFor="email">Email</label>
                  <Field name="email" id="email" className="sm-form-control" />
                  {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}
                </div>
                {/* <div className="col-12 form-group mb-4">
                  <label htmlFor="role" className="mb-3 label">
                   Role
                  </label>
                  <br />
                  <div className="btn-group btn-group-toggle flex-wrap" data-toggle="buttons">
                    <Field component={RadioButton} name="role" id="donor" label="donor" />
                    <Field component={RadioButton} name="role" id="institute" label="institute" />
                  </div>
                  {errors.role && touched.role ? (<div className="error">{errors.role}</div>) : null}
                </div> */}
                <div className="col-md-6 form-group">
                  <label className="label">Role</label>
                  <Field as="select" name="role" className="form-control">
                    <option value="">Select Role</option>
                    {roles.map(role =>
                      <option key={role._id} value={role.identifier}>{role.name}</option>
                    )};
                  </Field>
                  {errors.role && touched.role ? (
                    <div className="error">{errors.role}</div>
                  ) : null}
                </div>
                <div className="form-group col-12">
                  <label className="label" htmlFor="password">Password</label>
                  <Field type="password" id="password" name="password" className="sm-form-control" />
                  {errors.password && touched.password ? (<div className="error">{errors.password}</div>) : null}
                </div>
                {/* <div className="col-12 mt-2 mb-2 create">
                  <Link to="/login">I already have an account</Link>
                </div> */}
                <div className="col-12">
                  <button type="submit" name="website-cost-submit" className="btn btn-secondary">
                    {isSubmitting && <i className="fas fa-spinner fa-pulse"></i>} Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </>
  )
};
