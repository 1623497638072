import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Card, Button, Modal } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import { useHistory } from 'react-router-dom';


import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { DonationRequestService } from "../../../services/DonationRequest.service";
import { dateToDateString } from "../../../utils/date.utils";
import { InstituteService } from "../../../services/Institute.service";
import { ProjectService } from "../../../services/Project.service";
import { DonationsService } from "../../../services/Donations.service";
import { AccessRequestService } from "../../../services/AccessRequest.service";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import SessionService from "../../../services/Session.service";
import PayPalDonateButton from "../../StaticPages/Paypal";

const donationRequestService = new DonationRequestService();
const instituteService = new InstituteService();
const projectService = new ProjectService();
const donationService = new DonationsService();
const accessRequestService = new AccessRequestService();

const limit = 10;
const user = SessionService.getLoggedIn();

const CategoryApplicantTable = (props) => {
  console.log("======>props", props);
  const history = useHistory();
  let { slug } = useParams();
  const [donationRequests, setDonationRequests] = useState([]);
  const [offset, setOffset] = useState(0);
  const [categories, setCategories] = useState([]);
  const [donationRequestBeingDonated, setDonationRequestBeingDonated] =
    useState({});
  const filterFormRef = useRef();
  const [showModal, setModal] = useState(false);
  const [showPaypalButton, setPaypalButton] = useState(true);

  const hideButton = () => {
    setPaypalButton(false);
  };

  const handleClose = () => {
    setModal(false);
    setDonationRequestBeingDonated({});
  };
  const handleShow = (donationRequest) => {
    setModal(true);
    setPaypalButton(true);
    setDonationRequestBeingDonated(donationRequest);
  };

  const initialOptions = {
    "client-id":
      "AYtkXw-Dlnj03tJ94k304uD3D1C2jE0tOEW0n2BCLdPpDHw-SUzjjG0sLTJx8EJtmZQ7b5EhRm24ZS4g",
    currency: "GBP",
  };

  const createOrder = (data, actions) => {
    if (
      !donationRequestBeingDonated ||
      !donationRequestBeingDonated.requested
    ) {
      return;
    }
    const requiredFund =
      parseFloat(donationRequestBeingDonated.requested) -
      parseFloat(donationRequestBeingDonated.collected);
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: requiredFund.toString(),
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      const data = {
        reference: details.id,
        amount: parseFloat(details.purchase_units[0].amount.value),
        DonationRequestId: donationRequestBeingDonated._id,
        source: "Paypal",
      };
      if (user || user.id) {
        data.DonorId = user.id;
      }
      donationService
        .paypalPayment(data)
        .then((response) => {})
        .catch((error) => {});
      hideButton();
      // const name = details.payer.name.given_name;
      // alert(`Transaction completed by ${name}`);
    });
  };

  const getInstitutes = async (search) => {
    const conditions = { l: 20 };
    if (search) {
      conditions["s"] = search;
    }
    const response = await instituteService.getInstitutes(conditions);
    return response.data.map((x) => {
      return {
        value: x._id,
        label: x.name,
      };
    });
  };

  const requestAccess = (id, index) => {
    const user = SessionService.getLoggedIn();
    if (!user || user.role !== "donor") {
      history.push('/signup');
      toast.warning("You need to be logged in as a donor for profile access");
      return;
    }
    accessRequestService
      .create({ RecordId: id, module: "DonationRequest" })
      .then((response) => {
        const tempRecords = [...donationRequests];
        tempRecords[index].AccessStatus = "Pending";
        setDonationRequests(tempRecords);
      })
      .catch((error) => {
        toast.warning("Request access could not be generated");
      });
     
  };

  const getData = () => {
    let filters = {
      l: limit,
      o: offset,
      // CategoryId: props.filters.CategoryId,
      status: "Active",
    };

    if (props.InstitutionId) {
      filters.InstitutionId = props.InstitutionId;
    }
    if (filterFormRef.current) {
      if (filterFormRef.current.values.CategoryId)
        filters.CategoryId = filterFormRef.current.values.CategoryId;
      if (filterFormRef.current.values.InstitutionId)
        filters.InstitutionId = filterFormRef.current.values.InstitutionId;
      if (filterFormRef.current.values.uid)
        filters.uid = filterFormRef.current.values.uid;
    }
    donationRequestService.getDonationRequests(filters).then((response) => {
      if (offset) setDonationRequests([...donationRequests, ...response.data]);
      else setDonationRequests(response.data);
    });
    return;
  };

  const getProjects = async (search) => {
    const conditions = { l: 100, slug: slug };
    if (search) {
      conditions["s"] = search;
    }
    const response = await projectService.getAllProjects(conditions);
    setCategories(response.data);
    return;
  };

  useEffect(() => {
    getData();
  }, [offset]);

  useEffect(() => {
    getProjects();
  }, []);
  return (
    <>
      <div className="row pb-3">
        <div className="col-12">
          <Formik
            innerRef={filterFormRef}
            initialValues={{
              CategoryId: "",
              InstitutionId: "",
              uid: "",
            }}
            onSubmit={() => {
              if (offset) setOffset(0);
              else getData();
            }}>
            {({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
              <Form>
                <Card>
                  <Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
                    <div className="row align-items-center">
                      <Field
                        name="uid"
                        autoComplete="off"
                        id="s"
                        className="form-control col-sm-3 sm ml-1 mt-1"
                        placeholder="Id"
                      />
                      {/* <Field as="select" name="CategoryId" className="form-control col-sm-3 sm ml-1 mt-1">
												<option value="">Select Category</option>
												{categories.map(category =>
													<option key={category._id} value={category._id} >{category.name}</option>
												)};
											</Field> */}
                      <AsyncSelect
                        name="InstitutionId"
                        className="col-sm-3 sm ml-1 p-0 mt-1"
                        cacheOptions
                        defaultOptions
                        loadOptions={getInstitutes}
                        onChange={(val) =>
                          setFieldValue("InstitutionId", val.value)
                        }
                      />
                      <div className="col-sm-2 pt-2">
                        <button
                          type="submit"
                          className="btn btn-secondary btn-sm">
                          Filter
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm ml-1"
                          onClick={() => {
                            resetForm();
                            setTimeout(() => {
                              if (offset) setOffset(0);
                              else getData();
                            }, 200);
                          }}>
                          Reset
                        </button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="table-responsive mt-1">
        <InfiniteScroll
          style={{ maxHeight: "72vh" }}
          dataLength={donationRequests.length}
          next={() => setOffset(offset + limit)}
          hasMore={true}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Id</th>
                <th>Applicant Name</th>
                <th>Category</th>
                <th>Required Fund</th>
                <th>Collected Fund</th>
                <th>Required Date</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody id="mytable1">
              {donationRequests.map((donationRequest, index) => {
                return (
                  <tr key={donationRequest._id}>
                    <td>{donationRequest.uid}</td>
                    {donationRequest.applicant &&
                    donationRequest.applicant.fName ? (
                      <td>
                        {donationRequest.applicant.fName}{" "}
                        {donationRequest.applicant.lName}
                      </td>
                    ) : (
                      <td>Applicant Deleted</td>
                    )}
                    {donationRequest.category &&
                    donationRequest.category.name ? (
                      <td>{donationRequest.category.name}</td>
                    ) : (
                      <td>Category Deleted</td>
                    )}
                    <td>{donationRequest.requested}</td>
                    <td>{donationRequest.collected}</td>
                    <td>{dateToDateString(donationRequest.validTill)}</td>
                    <td className="d-flex justify-content-center">
                      {!donationRequest.AccessStatus && (
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm mr-1"
                          onClick={() =>
                            requestAccess(donationRequest._id, index)
                          }>
                         Click here to Sponsor
                        </button>
                      )}
                      {donationRequest.AccessStatus &&
                        donationRequest.AccessStatus == "Pending" && (
                          <span className="badge badge-light p-1">
                            Pending Approval
                          </span>
                        )}
                      {donationRequest.AccessStatus &&
                        donationRequest.AccessStatus == "Declined" && (
                          <span className="badge badge-danger p-1">
                            Access Declined
                          </span>
                        )}
                      {donationRequest.AccessStatus &&
                        donationRequest.AccessStatus == "Approved" && (
                          <>
                            <Link
                              to={
                                "/portal/donation-request/" +
                                donationRequest._id
                              }>
                              <button
                                type="button"
                                className="btn btn-info btn-sm">
                                View Profile
                              </button>
                            </Link>
                          </>
                        )}
                      {/* <Button className="ml-1" variant="primary" onClick={() => {
										handleShow(donationRequest);
									}} >
										Pay Now
									</Button> */}

                      {/* <PayPalDonateButton
                        key={donationRequest._id}
                        className="ml-2"
                        data={donationRequest._id}
                      /> */}
                       <Link
                              to={
                                "/donation/" +
                                donationRequest._id
                              }>
                              <button
                                type="button"
                                className="btn btn-info btn-sm">
                               Donate Now
                              </button>
                            </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default CategoryApplicantTable;
