import { React, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Dropdown } from "react-bootstrap";
import { Breadcrumb } from "../shared/Breadcrumb";
import { UserService } from "../../services/User.service";
import { booleanToString } from "../../utils/helping.utils";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Formik, Field, Form } from "formik";
import AclService from "../../services/Acl.service";



export const User = () => {
  const filterFormRef = useRef();
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [users, setUsers] = useState([]);
  const userService = new UserService();
  const [offset, setOffset] = useState(0);
  const [deleteId, setDeleteId] = useState(null);


  const toggleFilter = () => setIsFilterOpened(wasOpened => !wasOpened);
  const limit = 20;

  const getData = () => {
    const filters = {
      l: limit,
      o: offset
    };

    if (filterFormRef.current) {
      if (filterFormRef.current.values.s) filters.s = filterFormRef.current.values.s;
      if (filterFormRef.current.values.email) filters.email = filterFormRef.current.values.email;
    }
    userService.getAllUser(filters).then((response) => {
      if (offset) setUsers([...users, ...response.data]);
      else setUsers(response.data);
    })
  }

  useEffect(() => {
    getData();
  }, [offset])

  const deleteConfirmationResult = (isConfirmed) => {
    const id = deleteId;
    setDeleteId(null);
    if (!isConfirmed) {
      return;
    }

    userService.deleteUser(id).then((response) => {
      const i = users.findIndex(x => x._id === id);
      if (i >= 0) {
        const tempUsers = [...users];
        tempUsers.splice(i, 1);
        setUsers(tempUsers);
        toast.success("User delete successfully")
      }
    })
      .catch(() => {
        toast.error("User could not delete")
      })
  }
  return (
    <>
      <Breadcrumb title="Users" links={[{ title: "Users" }]} />
      <ConfirmationModal show={deleteId !== null} onClose={deleteConfirmationResult} />
      <section id="content" className="container-xl p-2 mt-3 bottommargin-lg">
        <div className="row mb-3">
          <div className="col-12 mb-2">
            {
              AclService.checkPermission("UserCreated") &&
              <Link to="/portal/user/form">
                <button type="button" className="btn btn-light btn-sm float-right" >
                  <i className="fas fa-plus"></i>
                </button>
              </Link>
            }
            <button type="button" onClick={toggleFilter} className="btn btn-light btn-sm float-right  mr-1">
              <i className="fas fa-filter"> Filters (0)</i>
            </button>
          </div>
          {isFilterOpened && (
            <div className="col-12">
              <Formik
                innerRef={filterFormRef}
                initialValues={{
                  s: "",
                  email: "",
                }}
                onSubmit={() => {
                  if (offset) setOffset(0)
                  else getData();
                }}
              >
                {({ errors, touched, isSubmitting, setFieldValue, resetForm }) => (
                  <Form>
                    <Card>
                      <Card.Body className="pt-1 pb-2 pl-4 pr-4 card-body">
                        <div className="row align-items-center">
                          <Field name="s" autoComplete="off" id="s" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Search.." />
                          <Field name="email" autoComplete="off" id="email" className="form-control col-sm-3 sm ml-1 mt-1" placeholder="Email...." />
                          <div className="col-sm-2 pt-2">
                            <button type="submit" className="btn btn-secondary btn-sm">Filter</button>
                            <button type="button" className="btn btn-secondary btn-sm ml-1" onClick={() => {
                              resetForm();
                              setTimeout(() => {
                                if (offset) setOffset(0)
                                else getData();
                              }, 200);
                            }}>Reset</button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
        <div className="table-responsive">
          <InfiniteScroll
            height={"72vh"}
            dataLength={users.length}
            next={() => setOffset(offset + limit)}
            hasMore={true}
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Verified</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  return <tr key={user._id}>
                    <td>{user.fName} {user.lName}</td>
                    <td>{user.email}</td>
                    <td>{user.role.name}</td>
                    <td>{booleanToString(user.verified)}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                          <i className="fas fa-cog"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            AclService.checkPermission("UserDelete") &&
                          <Dropdown.Item eventKey={"1"} onClick={() => setDeleteId(user._id)}>Delete</Dropdown.Item>}
                           {
                            AclService.checkPermission("UserUpdate") &&
                          <Dropdown.Item eventKey={"2"} as={Link} to={"/portal/user/form/" + user._id}>Edit</Dropdown.Item>}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                }
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </section>
    </>
  );
};
